import { FILTER_TYPES } from '../../../utils/filter';

const filterConfig = [{
  placeholder: 'Enter the Id of the Banner you are looking for',
  type: FILTER_TYPES.TEXT,
  filterTitle: 'Id',
  dataField: 'ID',
},{
  placeholder: 'Enter the Name of the Banner File you are looking for',
  type: FILTER_TYPES.TEXT,
  filterTitle: 'File Name',
  dataField: 'FILE_NM',
}];

export default filterConfig;
