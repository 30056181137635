// @flow

import React, { Component } from 'react';

import styled from 'styled-components';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Creators as ProductDetailCreators } from '../../../../store/ducks/productDetail';

import { Typography } from '@material-ui/core';


const Container = styled.div`
  margin: 0 4px;
  min-height: 115px !important;
`;

type Props = {
  getDataStatus: Function,
}

class DataStatus extends Component<Props, State> {

  componentDidMount() {
    const { getDataStatus } = this.props;

    getDataStatus();
  }
  
  shouldComponentUpdate(nextProps, nextStats) {
    const vitalPropsChange = 
      nextProps.dataStatus != null &&
      typeof nextProps.dataStatus !== 'undefined' &&
      nextProps.dataStatus.last_dt != null &&
      typeof nextProps.dataStatus.last_dt !== 'undefined';
    
    return vitalPropsChange;
  }  

  render() {
    const { dataStatus } = this.props;

    let updatedText = '';
    if (dataStatus && dataStatus.last_dt) {
      updatedText = 'Updated as of ' + dataStatus.last_dt;
    }

    return (
      <Container>
        <Typography variant="h6">
          {updatedText}
        </Typography>
      </Container>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators(ProductDetailCreators, dispatch);

const mapStateToProps = state => ({
  dataStatus: state.productDetail.dataStatus,
});

export default connect(mapStateToProps, mapDispatchToProps)(DataStatus);
