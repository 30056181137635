// @flow

import React, { Component } from 'react';

import styled from 'styled-components';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Creators as ProductDetailCreators } from '../../../../store/ducks/productDetail';

import { Typography } from '@material-ui/core';

import moment from 'moment';


const Container = styled.div`
  margin: 0 4px;
  min-height: 115px !important;
`;

type Props = {
  mpCode: String,
  fundsInfo: Object,
}

class DataStatus extends Component<Props, State> {

  componentDidMount() {
  }
  
  shouldComponentUpdate(nextProps, nextStats) {
    const vitalPropsChange = 
      nextProps.fundsInfo != null &&
      typeof nextProps.fundsInfo !== 'undefined';
    
    return vitalPropsChange;
  }  

  getFormattedDate = (targetDt) => {
    const target = moment(targetDt);
    return target.format('MMM. Do, YYYY');
  }

  render() {
    const { fundsInfo, mpCode } = this.props;

    let updatedText = '';
    if (fundsInfo) {
      const dataStatus = fundsInfo.filter(item => item.MP_CODE == mpCode)[0];
      updatedText = 'Updated as of ' + this.getFormattedDate(dataStatus.INDEX_DT) + ", Inception Date: " + this.getFormattedDate(dataStatus.INIT_DT);
      // updatedText = 'Updated as of ' + dataStatus.INDEX_DT + ", Inception Date: " + dataStatus.INIT_DT;

      if (dataStatus.INIT_INDEX) {
        updatedText += ", Initial index: " + dataStatus.INIT_INDEX;
      }

      if (dataStatus.TRADING_COST) {
        updatedText += ", Trading Cost: " + dataStatus.TRADING_COST;
      }
    }

    return (
      <Container>
        <Typography variant="h7">
          {updatedText}
        </Typography>
      </Container>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators(ProductDetailCreators, dispatch);

// const mapStateToProps = state => ({
//   dataStatus: state.productDetail.dataStatus,
//   fundsInfo: state.productList.fundsInfo,
// });

const mapStateToProps = (state) => { 
  return {
    fundsInfo: state.productList.fundsInfo,
}};

export default connect(mapStateToProps, mapDispatchToProps)(DataStatus);
