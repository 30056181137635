import { call, put } from 'redux-saga/effects';

import { Creators as BannerCreators } from '../ducks/banner';

import {
  CREATE_BANNER,
  READ_BANNERS,
  UPDATE_BANNER,
  DELETE_BANNER,
} from './event-handlers-types/banner';

import execRequest from './execRequest';
import { BANNER } from './entitiesTypes';

const EVENT_TAGS = {
  GET_ALL_BANNERS: 'BANNERS_GET_ALL',
  GET_BANNER: 'BANNER_GET',
  CREATE_BANNER: 'BANNER_CREATE',
  REMOVE_BANNER: 'BANNER_REMOVE',
  EDIT_BANNER: 'BANNER_EDIT',
};

export function* createBanner(action) {
  try {
    const { args } = action;

    const result = yield call(execRequest, BANNER, CREATE_BANNER, EVENT_TAGS.CREATE_BANNER, args);

    const newBanner = {
      ...args,
      newBanner: result.data.result,
    };

    yield put(BannerCreators.createBannerSuccess(newBanner));
  } catch (err) {
    yield put(BannerCreators.createBannerFailure(err.message));
  }
}

export function* getAllBanners() {
  try {
    const result = yield call(execRequest, BANNER, READ_BANNERS, EVENT_TAGS.GET_ALL_BANNERS);
    yield put(BannerCreators.getAllBannersSuccess(result));
  } catch (err) {
    yield put(BannerCreators.getAllBannersFailure(err));
  }
}

export function* editBanner(action) {
  try {
    const { banner } = action.payload;

    yield call(execRequest, BANNER, UPDATE_BANNER, EVENT_TAGS.EDIT_BANNER, banner);
    yield put(BannerCreators.editBannerSuccess(banner));
  } catch (err) {
    yield put(BannerCreators.editBannerFailure(err));
  }
}

export function* removeBanner(action) {
  try {
    const { id } = action.payload;

    yield call(execRequest, BANNER, DELETE_BANNER, EVENT_TAGS.REMOVE_BANNER, id);
    yield put(BannerCreators.removeBannerSuccess(id));
  } catch (err) {
    yield put(BannerCreators.removeBannerFailure());
  }
}
