import { call, put } from 'redux-saga/effects';

import { Creators as RoleCreators } from '../ducks/role';

import {
  CREATE_ROLE,
  READ_ROLES,
  UPDATE_ROLE,
  DELETE_ROLE,
} from './event-handlers-types/role';

import execRequest from './execRequest';
import { ROLE } from './entitiesTypes';

const EVENT_TAGS = {
  GET_ALL_ROLES: 'ROLES_GET_ALL',
  GET_ROLE: 'ROLE_GET',
  CREATE_ROLE: 'ROLE_CREATE',
  REMOVE_ROLE: 'ROLE_REMOVE',
  EDIT_ROLE: 'ROLE_EDIT',
};

export function* createRole(action) {
  try {
    const { args } = action;

    const result = yield call(execRequest, ROLE, CREATE_ROLE, EVENT_TAGS.CREATE_ROLE, args);

    const newRole = {
      ...args,
      id: result,
    };

    yield put(RoleCreators.createRoleSuccess(newRole));
  } catch (err) {
    yield put(RoleCreators.createRoleFailure(err.message));
  }
}

export function* getAllRoles() {
  try {
    const result = yield call(execRequest, ROLE, READ_ROLES, EVENT_TAGS.GET_ALL_ROLES);
    yield put(RoleCreators.getAllRolesSuccess(result));
  } catch (err) {
    yield put(RoleCreators.getAllRolesFailure(err));
  }
}

export function* editRole(action) {
  try {
    const { role } = action.payload;

    yield call(execRequest, ROLE, UPDATE_ROLE, EVENT_TAGS.EDIT_ROLE, role);
    yield put(RoleCreators.editRoleSuccess(role));
  } catch (err) {
    yield put(RoleCreators.editRoleFailure(err));
  }
}

export function* removeRole(action) {
  try {
    const { id } = action.payload;

    yield call(execRequest, ROLE, DELETE_ROLE, EVENT_TAGS.REMOVE_ROLE, id);
    yield put(RoleCreators.removeRoleSuccess(id));
  } catch (err) {
    yield put(RoleCreators.removeRoleFailure());
  }
}
