export default {
  white: '#FFF',
  tableOddColor: '#f2f2f2',
  containerColor: '#f4f4f4',
  darkText: '#rgba(0, 0, 0, 0.8)',
  lightText: '#FFF',
  headerText: '#6F6F6F',
  affirmative: '#5977e2',
  customInactiveButton: '#abbbf4',
  lightGray: '#f2f2f2',
  inputBorder: '#C9D2D7',
  mediumGray: '#494949',
  mediumGrayDisabled: '#a3a3a3',
  success: '#1DB954',
  successDisabled: '#62fc98',
  danger: '#FF5A60',
  dangerDisabled: '#f97c81',
  warning: '#ffc107',
  warningDisabled: '#ffdb72',
  indigo: '#3f51b5',
  blue: '#2196f3',
  blue900: '#0d47a1',
  blue800: '#1565c0',
  blue100: '#bbdefb',
};
