// @flow

import React, { Component } from 'react';

import styled from 'styled-components';

import { connect } from 'react-redux';

import { Typography } from '@material-ui/core';



const Container = styled.div`
  margin: 0 4px;
  min-height: 115px !important;
`;

type Props = {
}

class NoProductFound extends Component<Props, State> {

  render() {
    return (
      <Container>
        <center>
          <Typography variant="h6">
          </Typography>
        </center>
      </Container>
    );
  }
}

export default connect()(NoProductFound);
