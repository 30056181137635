import React, { Component } from 'react';

import styled from 'styled-components';

import { connect } from 'react-redux';

import LoadingImg from '../../images/gears100.svg';


const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 500px;
`;

class Loading extends Component<Props, State> {

  render() {
    return (
      <div id="loading">
        <IconWrapper>
          <img src={LoadingImg} alt='loading'/>
        </IconWrapper>
      </div>
    );
  }
}

export default connect()(Loading);
