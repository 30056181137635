// @flow

import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';

import Button from '@material-ui/core/Button';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Creators as AuthCreators } from '../../../store/ducks/auth';

import { Wrapper, InputWrapper, ButtonWrapper } from './styles';
import Input from '../../common/CustomInput';

type Props = {
  setSnackbarError: Function,
  history: Object,
  login: Function,
  auth: Object,
};

type State = {
  username: string,
  password: string,
}

const inputStyle = {
  color: '#ffffff !important',
  backgroundColor: '#1e1e2e !important',
}

class LoginForm extends Component<Props, State> {
  state = {
    username: '',
    password: '',
  };

  componentDidMount() {
    this._usernameInputRef.focus();
  }

  componentWillReceiveProps(nextProps) {
    const { status } = nextProps.auth;
    const { setSnackbarError } = this.props;

    if (status) {
      setSnackbarError(status);
    }
  }

  onTypeInputValue = (stateRef: string, value: string) => {
    this.setState({
      [stateRef]: value,
    });
  };

  onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      this.onClickEnterButton();
    }
  };

  onClickEnterButton = (): void => {
    const { username, password } = this.state;

    const user = {
      username, password
    };

    return this.handleLogin(user);
  };

  handleLogin = (user: Object): void => {
    const { login } = this.props;

    login(user);
  };

  render() {
    const { isAuthenticated } = this.props.auth;

    if (isAuthenticated) {
      return (
        <Redirect to='/product-list' />
      );
    }
    const { username, password } = this.state;

    return (
      <Wrapper>
        <InputWrapper>
          <Input
            inputRef={(input) => { this._usernameInputRef = input; }}
            onChange={(event: Object): void => this.onTypeInputValue('username', event.target.value)}
            onBlur={() => {}}
            value={username}
            label="Username"
            placeholder=""
            id="username"
            type="text"
            error=""
            style={inputStyle}
          />
        </InputWrapper>
        <Input
          onChange={(event: Object): void => this.onTypeInputValue('password', event.target.value)}
          onBlur={() => {}}
          value={password}
          type="password"
          placeholder=""
          label="Password"
          id="password"
          error=""
          style={inputStyle}
          onKeyDown={this.onKeyDown}
        />
        <ButtonWrapper>
          <Button
            disabled={(password.length < 6)}
            variant="contained"
            onClick={this.onClickEnterButton}
            color="secondary"
          >
            SIGN IN
          </Button>
        </ButtonWrapper>
      </Wrapper>
    );
  }
}

const Creators = Object.assign({}, AuthCreators);

const mapDispatchToProps = dispatch => bindActionCreators(Creators, dispatch);

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LoginForm));
