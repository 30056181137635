// @flow

import React, { Component } from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import styled from 'styled-components';


const StyledTable = styled(Table)`
`;

const StyledTableCell = styled(TableCell)`
  padding: 0px 10px 5px 0px !important;
  background-color: #27293d !important;
  border-bottom: none !important;
  font-weight: bold !important;
`;

const StyledRedCell = styled(StyledTableCell)`
  color: #b3bffe !important;
  width: 33% !important;
`;

type Props = {
  summary: Object,
};

class SummaryProfit extends Component<Props, State> {

  shouldComponentUpdate(nextProps, nextStats) {
    const vitalPropsChange = 
      nextProps.summary !== this.props.summary &&
      nextProps.summary != null &&
      typeof nextProps.summary !== 'undefined' &&
      typeof nextProps.summary.itd !== 'undefined' &&
      nextProps.summary.itd !== '-';
    
    return vitalPropsChange;
  }  

  render() {
    const { summary } = this.props;
    const oneMonth = summary.oneMonth;
    const threeMonth = summary.threeMonth;
    const sixMonth = summary.sixMonth;
    const oneYear = summary.oneYear;
    const threeYear = summary.threeYear;
    const fiveYear = summary.fiveYear;
    const ytd = summary.ytd;
    const itd = summary.itd;

    return (
      <StyledTable aria-label="target portfolio">
        <TableBody>
          <TableRow key="firstRow">
            <StyledTableCell align="left">
              1M
            </StyledTableCell>
            <StyledRedCell align="right">
              {`${oneMonth}`}
            </StyledRedCell>
            <StyledTableCell align="left">
              3M
            </StyledTableCell>
            <StyledRedCell align="right">
              {`${threeMonth}`}
            </StyledRedCell>
          </TableRow>
          <TableRow key="secondRow">
            <StyledTableCell align="left">
              6M
            </StyledTableCell>
            <StyledRedCell align="right">
              {`${sixMonth}`}
            </StyledRedCell>
            <StyledTableCell align="left">
              1Y
            </StyledTableCell>
            <StyledRedCell align="right">
              {`${oneYear}`}
            </StyledRedCell>
          </TableRow>
          <TableRow key="thirdRow">
            <StyledTableCell align="left">
              3Y
            </StyledTableCell>
            <StyledRedCell align="right">
              {`${threeYear}`}
            </StyledRedCell>
            <StyledTableCell align="left">
              5Y
            </StyledTableCell>
            <StyledRedCell align="right">
              {`${fiveYear}`}
            </StyledRedCell>
          </TableRow>
          <TableRow key="fourthRow">
            <StyledTableCell align="left">
              YTD
            </StyledTableCell>
            <StyledRedCell align="right">
              {`${ytd}`}
            </StyledRedCell>
            <StyledTableCell align="left">
              ITD
            </StyledTableCell>
            <StyledRedCell align="right">
              {`${itd}`}
            </StyledRedCell>
          </TableRow>
        </TableBody>
      </StyledTable>
    );
  }
}

export default SummaryProfit;
