/* eslint-disable react/no-string-refs */
// @flow

import React, { Component } from 'react';

import styled from 'styled-components';

import { connect } from 'react-redux';

import * as d3 from 'd3';


const Wrapper = styled.div`
  margin-top: 10px;
  margin-bottom: 0px;
`;

type Props = {
  mpCode: String,
  data: Array<Object>,
}

class SummaryChart extends Component<Props, State> {

  constructor(...args) {
    super(...args);
    this.childKey = 0;
  }

  shouldComponentUpdate(nextProps, nextStats) {
    const vitalPropsChange = 
      nextProps.data !== this.props.data &&
      nextProps.data != null &&
      typeof nextProps.data !== 'undefined'; 
    
    return vitalPropsChange;
  }  

  componentDidUpdate(prevProps, prevState) {

    const { data } = this.props;

    if (typeof data !== 'undefined' && data.length !== 0) {
      const converted = [];
      data.forEach((item, index) => {
        if ((data.length > 250 && index > data.length - 250) || data.length < 250) {
          converted.push(this.createData(item.DT, item.TR_INDEX));
        }
      });
      const allocated = [];
      let init_tr = 100

      if (converted.length > 0) {
        init_tr = converted[0].value;
      }

      converted.forEach((val, idx) => {
        allocated.push({date: val["date"], value: (val["value"] / init_tr - 1) * 100})
      });

      this.drawChart(allocated);
    }
  }

  createData = (date, val) => {
    return {
      date: new Date(date), value: val,
    };
  };

  drawChart = (data) => {
    const { mpCode } = this.props;

    // set the dimensions and margins of the graph
    const margin = {
      top: 0, right: 0, bottom: 0, left: 0,
    };
    const width = 120;
    const height = 45;

    const x = d3.scaleTime()
      .domain(d3.extent(data, (d) => d.date))
      .range([margin.left, width - margin.right]);

    // eslint-disable-next-line prefer-spread
    const yMin = Math.min.apply(Math, data.map((d) => d.value));
    // eslint-disable-next-line prefer-spread
    const yMax = Math.max.apply(Math, data.map((d) => d.value));

    const y = d3.scaleLinear()
      // .domain([0, d3.max(data, (d) => d.value)])
      .domain([yMin - 3, yMax + 3])
      .nice()
      .range([height - margin.bottom, margin.top]);

    const line = d3.line()
      // eslint-disable-next-line no-restricted-globals
      .defined((d) => !isNaN(d.value))
      .x((d) => x(d.date))
      .y((d) => y(d.value));
    
    const svg = d3.select(this.refs[`chartdiv_${mpCode}`])
      .append('svg')
      .style('width', '100%')
      .style('height', '100%')
      .style('display', 'block')
      .style('margin', 'auto')
      .attr('viewBox',  [0,  0, width, height]);

    svg.append('path')
      .datum(data)
      .attr('fill', 'none')
      .attr('stroke', 'steelblue')
      .attr('stroke-width', 1)
      .attr('stroke-linejoin', 'round')
      .attr('stroke-linecap', 'round')
      .attr('d', line);

    svg.node();
  }

  render() {
    const { mpCode } = this.props;

    // Props 갱신 될 때 마다 새로 그리기 위해서 key 속성 추가
    this.childKey++;
    return (
      <Wrapper key={this.childKey} ref={`chartdiv_${mpCode}`} />
    );
  }
}

export default connect()(SummaryChart);
