// @flow

import React, { Component } from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import { Hidden, Dialog, DialogTitle, DialogContent, TextField, DialogActions } from '@material-ui/core';

import Grid from '@material-ui/core/Grid';

import styled from 'styled-components';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Creators as ProductDetailCreators } from '../../../../store/ducks/productDetail';

import moment from 'moment';
import { parse as parseJson } from 'json2csv';
import FileSaver from 'file-saver';

import TitleWithDate from './components/TitleWithDate';

const Container = styled.div`
  margin: 0 4px;
  min-height: 375px;
`;

const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 16px;
`;

const LineWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LeftSideContainer = styled.div`
  margin-bottom: 24px;
  align-items: center;
  background-color: #27293d !important;
`;

const RightSideContainer = styled.div`
  padding-bottom: 15px;
  align-items: center;
  background-color: #27293d !important;
  @media ( max-width: 415px ) {
    margin-bottom: 0;
  }
`;

const StyledHeaderCell = styled(TableCell)`
  padding: 10px 24px 10px 16px !important;
  background-color: #A6A6A6 !important;
`;
const StyledTableCell = styled(TableCell)`
  padding: 10px 24px 10px 16px !important;
  background-color: #27293d !important;
  font-size: 0.7rem !important;
`;

const ChartWrapper = styled.div`
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const ButtonDiv = styled.div`
  float: right;
  margin-bottom: 10px !important;
`;

const ButtonWrapper = styled(Button)`
  margin-left: 10px !important;
`;

const StyledTextField = styled(TextField)`
  input {
    color: white !important;
  }
`;

type Props = {
  getPortfolioScore: Function,
  data: Array<Object>,
  mpCode: String,
  authLevel: String,
}

type State = {
  open: Boolean,
};

class PortfolioScore extends Component<Props, State> {

  state = {
    open: false,
  };

  constructor(...args) {
    super(...args);

    this.savedPort = [];
    this.condensed = [];
    this.lastScore = null;
    this.scoreMomentDate = null;

    const today = moment();
	  this.endDate = today.format("YYYY-MM-DD");
  }
  
  shouldComponentUpdate(nextProps, nextStats) {
    const vitalPropsChange = 
      nextProps.data != null &&
      typeof nextProps.data !== 'undefined' &&
      nextProps.data.length !== 0;
    const vitalStateChange = 
      nextStats.open != null &&
      typeof nextStats.open !== 'undefined';
  
    return vitalPropsChange || vitalStateChange;
  }  

  componentDidMount() {
    this.getPortfolioScoreList(this.endDate);
  }
  
  getPortfolioScoreList = (endDt) => {
    const { getPortfolioScore, mpCode } = this.props;

    const params = {
      mp_code: mpCode,
      datestr: endDt,
    }

    getPortfolioScore(params);
  }

  getLastPortDt = (data) => {
    let lastPortDt = null;

    for (const [key, value] of Object.entries(data)) {
      if (lastPortDt == null || lastPortDt < value['date']) {
        lastPortDt = value['date'];
      }
    }

    return lastPortDt;
  }

  initialize = (data) => {
    const lastPortDt = this.getLastPortDt(data);
    console.log(lastPortDt)

    const targetPortfolio = [];
    for (const [key, value] of Object.entries(data)) {
      targetPortfolio.push({
        "CATEGORY": value['CATEGORY'],
        "STK_CD": value['STK_CODE'],
        "SCORE": value['SCORE'],
      });
    }
    targetPortfolio.sort((a, b) => (b.SCORE - a.SCORE));
    this.lastScore = targetPortfolio;
  };

  getPrecision = (precision) => {
    const condensed = [];
    Array.from(this.lastScore).forEach((item) => {
      condensed.push(this.createData(item.CATEGORY, item.STK_CD, item.SCORE.toFixed(precision)));
    });
    condensed.sort((a, b) => (b.scores - a.scores));

    return condensed;
  };

  createData = (category, ticker, scores) => {
    return {
      category, ticker, scores,
    };
  };

  getSliced = (data) => {
    let sliced = []
    if (data.length > 0) {
      sliced = sliced.concat(data.filter(item => item.category == 'Core').slice(0, 3))
      sliced = sliced.concat(data.filter(item => item.category == 'Satelite').slice(0, 3))
    }
    return sliced
  }

  handleClickOpen = () => {
    this.setState({open: true});
  }

  handleClose = () => {
    this.setState({open: false});
  }

  handleCloseGetData = () => {
    this.getPortfolioScoreList();
    this.setState({open: false});
  }

  handleTextFieldChange = (key, e) => {
    // this[key] = e.target.value;
    this.endDate = e.target.value;
    this.startDate = moment(this.endDate).subtract(2, 'month').format("YYYY-MM-DD");
  }

  handleCsvDownload = (key, e) => {
    const clonedData = JSON.parse(JSON.stringify( this.forCsv ));

    const csv = parseJson(clonedData.sort(
      (a, b) => (a.ticker.localeCompare(b.ticker))
    ));

    const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8' });
    FileSaver.saveAs(csvData, `portfolio_score(${this.getFormattedScoreDate('YYYY-MM-DD')}).csv`);
  }

  getFormattedScoreDate = (format) => {
    if (this.scoreMomentDate == null) {
      return '';
    } else {
      return this.scoreMomentDate.format(format);
    }
  }

  renderTitleWithDate = (): Object => {
    const { data } = this.props;

    if (typeof data !== 'undefined' && 
      data != null) {
      this.scoreMomentDate = moment(this.getLastPortDt(data));
    }

    const title = 'Portfolio Score';

    return (
      <TitleWithDate
        text={title}
        date={`(scored on ${this.getFormattedScoreDate('MMM. Do, YYYY')})`}
      />
    );
  };

  prepareData = () => {
    const { data } = this.props;

    if (data != null && typeof data !== 'undefined') {
      this.lastScore = null;
  
      this.initialize(data);
      this.condensed = this.getPrecision(2);
      this.forCsv = this.getPrecision(3);
    }
  };

  renderTable = (authenticated, portfolio): Object => {
    if (authenticated) {
      return this.renderDetailTable(portfolio);
    } else {
      return (
        <div>
          <br />
          <br />
        </div>
      )
    }
  };

  renderDetailTable = (portfolio): Object => {

    return (
      <div>
        <Table aria-label="portfolio score">
          <TableHead>
            <TableRow>
              <StyledHeaderCell align="left">
                Category
              </StyledHeaderCell>
              <StyledHeaderCell align="left">
                Ticker
              </StyledHeaderCell>
              <StyledHeaderCell align="right">
                Score
              </StyledHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              portfolio.map((row) => (
                <TableRow key={row.ticker}>
                  <StyledTableCell component="th" scope="row">
                    {row.category}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {row.ticker}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row.scores}
                  </StyledTableCell>
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
      </div>
    );
  };

  renderButtons = (authenticated): Object => {

    if (authenticated) {
      return (
        <ButtonDiv>
          <ButtonWrapper variant="contained" size="small" color="primary" onClick={this.handleClickOpen}>
            Portfolio Date
          </ButtonWrapper>
          {this.renderDatesDialog()}
          <ButtonWrapper variant="contained" size="small" color="primary" onClick={this.handleCsvDownload}>
            CSV Download
          </ButtonWrapper>
        </ButtonDiv>
      );
    } else {
      return (
        <div>
          <br />
          <br />
        </div>
      )
    }
  };

  renderDatesDialog = () => {
    const { open } = this.state;

    return (
      <Dialog 
        open={open} 
        onClose={this.handleClose} 
        aria-labelledby="form-dialog-title"
        PaperProps={{
          style: {
            backgroundColor: '#27293d',
          }
        }}>
        <DialogTitle id="form-dialog-title">Portfolio Date</DialogTitle>
        <DialogContent>
          <StyledTextField
            id="endDate"
            label="To"
            type="date"
            defaultValue={this.endDate}
            onChange={(e) => this.handleTextFieldChange('endDate', e)}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={this.handleCloseGetData} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    )
  };

  render() {
    const { authLevel, fundsInfo, mpCode } = this.props;
    let authenticated = authLevel === "1";
    if (fundsInfo) {
      const dataStatus = fundsInfo.filter(item => item.MP_CODE == mpCode)[0];
      // this.scoreMomentDate = moment(dataStatus.REBAL_DT);
    }

    this.prepareData();
    const port = this.getSliced(this.condensed);

    if (typeof port !== 'undefined' &&
      port !== null &&
      port.length !== 0 &&
      (
        this.savedPort.length === 0 || this.savedPort !== port
      )) {
      this.savedPort = port;
    }

    if (this.savedPort.length === 0) {
      return (
        <div style={{
          margin: "0"
        }}>
        </div>
      );
    } else {
      return (
        <Container>
          <HeaderWrapper>
            {this.renderTitleWithDate()}
          </HeaderWrapper>
          <LineWrapper>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <LeftSideContainer>
                  {this.renderTable(authenticated, this.savedPort)}
                </LeftSideContainer>
              </Grid>
            </Grid>
          </LineWrapper>
          <Hidden mdDown>
            {this.renderButtons(authenticated)}
          </Hidden>
        </Container>
      );
    }
  }
}

const mapDispatchToProps = dispatch => bindActionCreators(ProductDetailCreators, dispatch);

const mapStateToProps = state => ({
  fundsInfo: state.productList.fundsInfo,
  data: state.productDetail.portfolioScore,
  auth: state.auth,
});

export default connect(mapStateToProps, mapDispatchToProps)(PortfolioScore);
