import React, { Component } from 'react';

import { withFormik, Form } from 'formik';
import * as Yup from 'yup';

import styled from 'styled-components';

import ActionFormButton from '../../../components/common/ActionFormButton';
import Input from '../../../components/common/CustomInput';
import PreviewDropzone from '../../../components/dropzone';
import Snackbar from '../../../components/common/Snackbar';

import {
  renderSectionTitle,
  Section,
  Wrapper,
  Row,
  RowItem
} from '../../../components/common/FormUtils';

const StyledImg = styled.img`
  width: 1084px !important;
  height: 120px !important;
  margin: auto;
`;

const ItemLine = styled(Wrapper)`
  margin-bottom: 24px;
`;

const Label = styled.p`
  margin-bottom: 4px;
  color: ${({ theme, error }) => error && theme.colors.danger}
`;

type Props = {
  onChageFormToEditMode: Function,
  handleSubmit: Function,
  onRemoveItem: Function,
  notify: Function,
  isSubmitting: boolean,
  item: Object,
  mode: string,
};

type State = {
  files: Array<Object>,
  isSnackbarOpen: boolean,
  message: string,
  error: string,
};

const getFileUrl = (filename): string => {
  return 'https://roboinvest-engine-webapp.s3.ap-northeast-2.amazonaws.com/banner/' + filename;
}

class BannerForm extends Component<Props, State> {
  state = {
    filename: this.props.values.filename,
    file: getFileUrl(this.props.values.filename),
    link: this.props.values.link,
    message: '',
    error: '',
    isSnackbarOpen: false,
  }
  
  handleUpload = (files) => {
    const { values } = this.props;
    values.file = files[0];
    values.filename = files[0].name;
  }

  notify = ({message, error}) => {
    if (message) {
      this.setState({
        message: message,
      });
    }
    if (error) {
      this.setState({
        error: error,
      });
    }
    if (message || error) {
      this.setState({
        isSnackbarOpen: true,
      });
    }
  }

  renderImageSection = (): Object => {

    const {filename} = this.props.values;
    const src = getFileUrl(filename);

    return (
      <Section>
        {renderSectionTitle('Image')}
        <StyledImg src={src} alt='slider' />
      </Section>
    );
  };

  renderDropzoneSection = (): Object => {

    const {mode} = this.props;
    const {filename} = this.props.values;
    const src = 'https://roboinvest-engine-webapp.s3.ap-northeast-2.amazonaws.com/banner/' + filename;
    let files = [{
        name: filename,
        preview: src
    }];
    if (mode === 'create') {
      files = [];
    }

    return (
      <ItemLine>
        <Label>
          Prefered image size: 1067 x 120 pixel
        </Label>
        <PreviewDropzone 
          initialFiles={files}
          notify={this.notify}
          setFiles={this.handleUpload}
        />
      </ItemLine>
    );
  };

  renderBannerSection = (): Object => {
    const placeholder = 'Filename';
    const label = 'Filename';
    const type = 'text';
    const id = 'filename';

    const {
      handleChange,
      handleBlur,
      touched,
      errors,
      values,
    } = this.props;
  
    return (
      <Section>
        {renderSectionTitle('Banner')}

        <Row>
          <RowItem>
            <Input
              error={touched[id] && errors[id]}
              placeholder={placeholder}
              disabled
              value={values[id]}
              onChange={handleChange}
              label={label}
              type={type}
              onBlur={handleBlur}
              id={id}
            />
          </RowItem>
        </Row>
      </Section>
    );
  };

  renderLinkSection = (): Object => {
    const placeholder = 'Url';
    const label = 'Link';
    const type = 'text';
    const id = 'link';

    const {
      handleChange,
      handleBlur,
      touched,
      errors,
      values,
    } = this.props;
  
    return (
      <Section>
        <Row>
          <RowItem>
            <Input
              error={touched[id] && errors[id]}
              placeholder={placeholder}
              value={values[id]}
              onChange={handleChange}
              label={label}
              type={type}
              onBlur={handleBlur}
              id={id}
            />
          </RowItem>
        </Row>
      </Section>
    );
  };

  renderSnackbar = (): Object => {
    const { isSnackbarOpen, message, error } = this.state;

    return (
      <Snackbar
        onCloseSnackbar={() => this.setState({ isSnackbarOpen: false })}
        isOpen={isSnackbarOpen}
        message={message}
        error={error}
      />
    );
  };

  render() {
    const {
      onChageFormToEditMode,
      onRemoveItem,
      handleSubmit,
      isSubmitting,
      mode,
    } = this.props;

    return (
      <Wrapper>
        <Form>
          {this.renderBannerSection()}
          {this.renderLinkSection()}
          {mode === 'detail' && this.renderImageSection()}
          {mode !== 'detail' && this.renderDropzoneSection()}
          <Row>
            <ActionFormButton
              onChageFormToEditMode={onChageFormToEditMode}
              onRemoveItem={onRemoveItem}
              disabled={isSubmitting}
              onClick={handleSubmit}
              entity="User"
              mode={mode}
            />
          </Row>
        </Form>
        {this.renderSnackbar()}
      </Wrapper>
    );
  }
}

const CustomForm = withFormik({
  mapPropsToValues: ({ item }) => ({
    filename: item.FILE_NM || '',
    file: item.file,
    link: item.link
  }),

  validationSchema: ({ item, mode }) => Yup.lazy(() => Yup.object().shape({
    filename: Yup.string()
      .required('The File Name is required.'),
  })),

  handleSubmit(values, { setSubmitting, props }) {
    const { onCreateItem, onEditItem, mode } = props;

    const properCallback = (mode === 'edit' ? onEditItem : onCreateItem);

    properCallback({
      FILE_NM: values.filename,
      FILE: values.file,
      LINK: values.link,
    });

    setSubmitting(false);
  },
})(BannerForm);

export default CustomForm;
