import React from 'react';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import styled from 'styled-components';

import { withFormik, Form } from 'formik';
import * as Yup from 'yup';

import ActionFormButton from '../../../components/common/ActionFormButton';

import {
  renderRowWithTwoItems,
  renderSectionTitle,
  getRowItemObject,
  Section,
  Wrapper,
  Row,
} from '../../../components/common/FormUtils';

const ItemLine = styled(Wrapper)`
  margin-bottom: 24px;
`;

type Props = {
  onChageFormToEditMode: Function,
  handleSubmit: Function,
  onRemoveItem: Function,
  isSubmitting: boolean,
  item: Object,
  mode: string,
};

type State = {
  isPasswordDialogOpen: boolean,
  isAdmin: Boolean,
};

const UserForm = (props: Props): Object => {
  const [isAdmin, setIsAdmin] = React.useState(props.item.IS_ADMIN === 'Y' ? true : false);

  const handleIsAdminChange = event => {
    const { values } = props;

    const value = event.target.checked;
    setIsAdmin(value);

    const isAdmin = value === true ? 'Y' : 'N'
    values.isAdmin = isAdmin;
  }

  const renderPasswordSection = (): Object => {
    const passwordConfirmInputFieldData = getRowItemObject('Repeat the Password', 'Enter the Password again.', 'password', 'passwordConfirm');
    const passwordInputFieldData = getRowItemObject('Password', 'Enter the Password', 'password', 'password');

    return (
      <Section>
        {renderSectionTitle('Password')}
        {renderRowWithTwoItems(passwordInputFieldData, passwordConfirmInputFieldData, props)}
      </Section>
    );
  };

  const renderIsAdminCheckbox = (): Object => {
    return (
      <ItemLine>
        <FormControlLabel
          onChange={handleIsAdminChange}
          control={<Checkbox checked={isAdmin} color="primary" />}
          label="Is user admin level?"
        />
      </ItemLine>
    );
  }

  const renderUserInfoSection = (): Object => {
    const nameInputFieldData = getRowItemObject('Name', 'Enter the Name', 'text', 'name');
    const usernameInputFieldData = getRowItemObject('Username', 'Enter the Username', 'text', 'username');
    const phoneInputFieldData = getRowItemObject('Phone', 'Enter the Phone Number', 'text', 'phone');
    const belongInputFieldData = getRowItemObject('Belongs to', 'Enter where he belongs to', 'text', 'belong');

    return (
      <Section>
        {renderSectionTitle('User Info')}
        {renderRowWithTwoItems(nameInputFieldData, usernameInputFieldData, props)}
        {renderRowWithTwoItems(phoneInputFieldData, belongInputFieldData, props)}
        {renderIsAdminCheckbox()}
      </Section>
    );
  };

  const {
    onChageFormToEditMode,
    onRemoveItem,
    handleSubmit,
    isSubmitting,
    mode,
  } = props;

  return (
    <Wrapper>
      <Form>
        {renderUserInfoSection()}
        {mode === 'create' && renderPasswordSection()}
        <Row>
          <ActionFormButton
            onChageFormToEditMode={onChageFormToEditMode}
            onRemoveItem={onRemoveItem}
            disabled={isSubmitting}
            onClick={handleSubmit}
            entity="User"
            mode={mode}
          />
        </Row>
      </Form>
    </Wrapper>
  );
}

const getPasswordValidation = (mode: string): Object => {
  if (mode === 'create') {
    return {
      password: Yup.string()
        .min(6, 'The Password might have at least 6 characters.')
        .required('The Password is required.'),

      passwordConfirm: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords is not equal.')
        .required('The Passwords might be equal.'),
    };
  }

  return {};
};

const CustomForm = withFormik({
  mapPropsToValues: ({ item }) => ({
    name: item.NAME || '',
    username: item.USER_ID || '',
    phone: item.PHONE || '',
    belong: item.BELONG || '',
    isAdmin: item.IS_ADMIN || '',
    password: '',
    passwordConfirm: '',
  }),

  validationSchema: ({ item, usernames, mode }) => Yup.lazy(() => Yup.object().shape({
    name: Yup.string()
      .required('The Name is required.'),

    username: Yup.string()
      // .test('username-repeated', 'This User has already been registered.', (value) => {
      //   const { username } = item;
      //   return handleRepeatedFormValues(usernames, username, value, mode);
      // })
      .required('The Username is requried.'),

    ...getPasswordValidation(mode),
  })),

  handleSubmit(values, { setSubmitting, props }) {
    const { onCreateItem, onEditItem, mode } = props;

    const properCallback = (mode === 'edit' ? onEditItem : onCreateItem);

    properCallback({
      NAME: values.name,
      USER_ID: values.username,
      PASSWORD: values.password,
      PHONE: values.phone,
      BELONG: values.belong,
      IS_ADMIN: values.isAdmin,
    });

    setSubmitting(false);
  },
})(UserForm);

export default CustomForm;
