import Immutable from 'seamless-immutable';

export const Types = {
  CREATE_REQUEST: 'banner/CREATE_REQUEST',
  CREATE_SUCCESS: 'banner/CREATE_SUCCESS',
  CREATE_FAILURE: 'banner/CREATE_FAILURE',

  GET_ALL_REQUEST: 'banner/GET_ALL_REQUEST',
  GET_ALL_SUCCESS: 'banner/GET_ALL_SUCCESS',
  GET_ALL_FAILURE: 'banner/GET_ALL_FAILURE',

  EDIT_REQUEST: 'banner/EDIT_REQUEST',
  EDIT_REQUEST_SUCCESS: 'banner/EDIT_REQUEST_SUCCESS',
  EDIT_REQUEST_FAILURE: 'banner/EDIT_REQUEST_FAILURE',

  REMOVE_REQUEST: 'banner/REMOVE_REQUEST',
  REMOVE_REQUEST_SUCCESS: 'banner/REMOVE_REQUEST_SUCCESS',
  REMOVE_REQUEST_FAILURE: 'banner/REMOVE_REQUEST_FAILURE',

  EDIT_PASSWORD_REQUEST: 'banner/EDIT_PASSWORD_REQUEST',
  EDIT_PASSWORD_REQUEST_SUCCESS: 'banner/EDIT_PASSWORD_REQUEST_SUCCESS',
  EDIT_PASSWORD_REQUEST_FAILURE: 'banner/EDIT_PASSWORD_REQUEST_FAILURE',
};

const INITIAL_STATE = Immutable({
  message: null,
  error: null,
  data: [],
});

export const Creators = {
  createBanner: args => ({
    type: Types.CREATE_REQUEST,
    args,
  }),

  createBannerSuccess: banner => ({
    type: Types.CREATE_SUCCESS,
    payload: { message: 'Banner Created Successfully', banner },
  }),

  createBannerFailure: () => ({
    type: Types.CREATE_FAILURE,
    payload: { error: 'There was a problem when trying to Create Banner' },
  }),

  getAllBanners: () => ({
    type: Types.GET_ALL_REQUEST,
  }),

  getAllBannersSuccess: banners => ({
    type: Types.GET_ALL_SUCCESS,
    payload: { banners },
  }),

  getAllBannersFailure: () => ({
    type: Types.GET_ALL_FAILURE,
    payload: { error: 'There was a problem when trying to get Banners from Database' },
  }),

  editBanner: banner => ({
    type: Types.EDIT_REQUEST,
    payload: { banner },
  }),

  editBannerSuccess: bannerEdited => ({
    type: Types.EDIT_REQUEST_SUCCESS,
    payload: { message: 'Banner Edited Successfully', bannerEdited },
  }),

  editBannerFailure: () => ({
    type: Types.EDIT_REQUEST_FAILURE,
    payload: { error: 'There was a problem when trying to Edit Banner' },
  }),

  removeBanner: id => ({
    type: Types.REMOVE_REQUEST,
    payload: { id },
  }),

  removeBannerSuccess: id => ({
    type: Types.REMOVE_REQUEST_SUCCESS,
    payload: { message: 'Banner Removed Successfully', id },
  }),

  removeBannerFailure: () => ({
    type: Types.REMOVE_REQUEST_FAILURE,
    payload: { error: 'There was a problem when trying to Remove Banner' },
  }),
};

const banner = (state = INITIAL_STATE, { payload, type }) => {
  switch (type) {
    case Types.CREATE_REQUEST:
      return {
        ...state,
        message: null,
        error: null,
      };

    case Types.CREATE_SUCCESS:
      return {
        ...state,
        message: payload.message,
        data: [...state.data, payload.banner.newBanner],
      };

    case Types.CREATE_FAILURE:
      return {
        ...state,
        error: payload.error,
      };

    case Types.GET_ALL_REQUEST:
      return {
        ...state,
        message: null,
        error: null,
      };

    case Types.GET_ALL_SUCCESS:
      return {
        data: [...payload.banners.data],
        error: null,
      };

    case Types.GET_ALL_FAILURE:
      return {
        ...state,
        error: payload.error,
      };

    case Types.EDIT_REQUEST:
      return {
        ...state,
        message: null,
        error: null,
      };

    case Types.EDIT_REQUEST_SUCCESS:
      return {
        ...state,
        message: payload.message,
        data: state.data.map(item => (item.ID === payload.bannerEdited.ID ? payload.bannerEdited : item)),
      };

    case Types.EDIT_REQUEST_FAILURE:
      return {
        ...state,
        error: payload.error,
      };

    case Types.REMOVE_REQUEST:
      return {
        ...state,
        message: null,
        error: null,
      };

    case Types.REMOVE_REQUEST_SUCCESS:
      return {
        ...state,
        message: payload.message,
        data: state.data.filter(item => item.ID !== payload.id.ID),
      };

    case Types.REMOVE_REQUEST_FAILURE:
      return {
        ...state,
        error: payload.error,
      };
  
    default:
      return state;
  }
};

export default banner;
