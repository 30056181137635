import Immutable from 'seamless-immutable';

export const Types = {
  GET_PORTFOLIO_RETURN_REQUEST: 'productDetail/GET_PORTFOLIO_RETURN_REQUEST',
  GET_PORTFOLIO_RETURN_SUCCESS: 'productDetail/GET_PORTFOLIO_RETURN_SUCCESS',
  GET_PORTFOLIO_RETURN_FAILURE: 'productDetail/GET_PORTFOLIO_RETURN_FAILURE',

  GET_ASSET_ALLOCATION_LIST_REQUEST: 'productDetail/GET_ASSET_ALLOCATION_LIST_REQUEST',
  GET_ASSET_ALLOCATION_LIST_SUCCESS: 'productDetail/GET_ASSET_ALLOCATION_LIST_SUCCESS',
  GET_ASSET_ALLOCATION_LIST_FAILURE: 'productDetail/GET_ASSET_ALLOCATION_LIST_FAILURE',

  GET_TARGET_PORTFOLIO_LIST_REQUEST: 'productDetail/GET_TARGET_PORTFOLIO_LIST_REQUEST',
  GET_TARGET_PORTFOLIO_LIST_SUCCESS: 'productDetail/GET_TARGET_PORTFOLIO_LIST_SUCCESS',
  GET_TARGET_PORTFOLIO_LIST_FAILURE: 'productDetail/GET_TARGET_PORTFOLIO_LIST_FAILURE',

  GET_PRODUCT_INDICATOR_LIST_REQUEST: 'productDetail/GET_PRODUCT_INDICATOR_LIST_REQUEST',
  GET_PRODUCT_INDICATOR_LIST_SUCCESS: 'productDetail/GET_PRODUCT_INDICATOR_LIST_SUCCESS',
  GET_PRODUCT_INDICATOR_LIST_FAILURE: 'productDetail/GET_PRODUCT_INDICATOR_LIST_FAILURE',

  GET_DATA_STATUS_REQUEST: 'productDetail/GET_DATA_STATUS_REQUEST',
  GET_DATA_STATUS_SUCCESS: 'productDetail/GET_DATA_STATUS_SUCCESS',
  GET_DATA_STATUS_FAILURE: 'productDetail/GET_DATA_STATUS_FAILURE',

  GET_PORTFOLIO_SNAPSHOT_REQUEST: 'productDetail/GET_PORTFOLIO_SNAPSHOT_REQUEST',
  GET_PORTFOLIO_SNAPSHOT_SUCCESS: 'productDetail/GET_PORTFOLIO_SNAPSHOT_SUCCESS',
  GET_PORTFOLIO_SNAPSHOT_FAILURE: 'productDetail/GET_PORTFOLIO_SNAPSHOT_FAILURE',

  GET_PORTFOLIO_SCORE_REQUEST: 'productDetail/GET_PORTFOLIO_SCORE_REQUEST',
  GET_PORTFOLIO_SCORE_SUCCESS: 'productDetail/GET_PORTFOLIO_SCORE_SUCCESS',
  GET_PORTFOLIO_SCORE_FAILURE: 'productDetail/GET_PORTFOLIO_SCORE_FAILURE',
};

const INITIAL_STATE = Immutable({
  message: null,
  error: null,
  data: [],
  args: null,
});

export const Creators = {
  getPortfolioReturn: (args) => ({
    type: Types.GET_PORTFOLIO_RETURN_REQUEST,
    args,
  }),

  getPortfolioReturnSuccess: result => ({
    type: Types.GET_PORTFOLIO_RETURN_SUCCESS,
    payload: { result },
  }),

  getPortfolioReturnFailure: () => ({
    type: Types.GET_PORTFOLIO_RETURN_FAILURE,
    payload: { error: 'There was a problem when trying to get PortfolioReturn from Database' },
  }),

  getAssetAllocationList: (args) => ({
    type: Types.GET_ASSET_ALLOCATION_LIST_REQUEST,
    args,
  }),

  getAssetAllocationListSuccess: result => ({
    type: Types.GET_ASSET_ALLOCATION_LIST_SUCCESS,
    payload: { result },
  }),

  getAssetAllocationListFailure: () => ({
    type: Types.GET_ASSET_ALLOCATION_LIST_FAILURE,
    payload: { error: 'There was a problem when trying to get AssetAllocationList from Database' },
  }),

  getTargetPortfolioList: (args) => ({
    type: Types.GET_TARGET_PORTFOLIO_LIST_REQUEST,
    args,
  }),

  getTargetPortfolioListSuccess: result => ({
    type: Types.GET_TARGET_PORTFOLIO_LIST_SUCCESS,
    payload: { result },
  }),

  getTargetPortfolioListFailure: () => ({
    type: Types.GET_TARGET_PORTFOLIO_LIST_FAILURE,
    payload: { error: 'There was a problem when trying to get TargetPortfolio from Database' },
  }),

  getProductIndicatorList: (args) => ({
    type: Types.GET_PRODUCT_INDICATOR_LIST_REQUEST,
    args,
  }),

  getProductIndicatorListSuccess: result => ({
    type: Types.GET_PRODUCT_INDICATOR_LIST_SUCCESS,
    payload: { result },
  }),

  getProductIndicatorListFailure: () => ({
    type: Types.GET_PRODUCT_INDICATOR_LIST_FAILURE,
    payload: { error: 'There was a problem when trying to get ProductIndicator from Database' },
  }),

  getDataStatus: (args) => ({
    type: Types.GET_DATA_STATUS_REQUEST,
    args,
  }),

  getDataStatusSuccess: result => ({
    type: Types.GET_DATA_STATUS_SUCCESS,
    payload: { result },
  }),

  getDataStatusFailure: () => ({
    type: Types.GET_DATA_STATUS_FAILURE,
    payload: { error: 'There was a problem when trying to get DataStatus from Database' },
  }),

  getPortfolioSnapshot: (args) => ({
    type: Types.GET_PORTFOLIO_SNAPSHOT_REQUEST,
    args,
  }),

  getPortfolioSnapshotSuccess: result => ({
    type: Types.GET_PORTFOLIO_SNAPSHOT_SUCCESS,
    payload: { result },
  }),

  getPortfolioSnapshotFailure: () => ({
    type: Types.GET_PORTFOLIO_SNAPSHOT_FAILURE,
    payload: { error: 'There was a problem when trying to get PortfolioSnapshot from Database' },
  }),

  getPortfolioScore: (args) => ({
    type: Types.GET_PORTFOLIO_SCORE_REQUEST,
    args,
  }),

  getPortfolioScoreSuccess: result => ({
    type: Types.GET_PORTFOLIO_SCORE_SUCCESS,
    payload: { result },
  }),

  getPortfolioScoreFailure: () => ({
    type: Types.GET_PORTFOLIO_SCORE_FAILURE,
    payload: { error: 'There was a problem when trying to get PortfolioScore from Database' },
  }),
};

const productDetail = (state = INITIAL_STATE, { payload, type }) => {

  let newState = {
    ...state,
  };

  switch (type) {
    case Types.GET_PORTFOLIO_RETURN_REQUEST:
      newState = {
        ...state,
        message: null,
        error: null,
      };
      return newState;

    case Types.GET_PORTFOLIO_RETURN_SUCCESS:
      newState = {
        portfolioReturn: payload.result.data,
        args: payload.result.args,
        error: null,
      };
      return newState;

    case Types.GET_PORTFOLIO_RETURN_FAILURE:
      newState = {
        ...state,
        error: payload.error,
      };
      return newState;

    case Types.GET_ASSET_ALLOCATION_LIST_REQUEST:
      newState = {
        ...state,
        message: null,
        error: null,
      };
      return newState;
    
    case Types.GET_ASSET_ALLOCATION_LIST_SUCCESS:
      newState = {
        assetAllocationList: payload.result.data,
        args: payload.result.args,
        error: null,
      };
      return newState;
    
    case Types.GET_ASSET_ALLOCATION_LIST_FAILURE:
      newState = {
        ...state,
        error: payload.error,
      };
      return newState;

    case Types.GET_TARGET_PORTFOLIO_LIST_REQUEST:
      newState = {
        ...state,
        message: null,
        error: null,
      };
      return newState;

    case Types.GET_TARGET_PORTFOLIO_LIST_SUCCESS:
      newState = {
        targetPortfolioList: payload.result.data,
        args: payload.result.args,
        error: null,
      };
      return newState;

    case Types.GET_TARGET_PORTFOLIO_LIST_FAILURE:
      newState = {
        ...state,
        error: payload.error,
      };
      return newState;

    case Types.GET_PRODUCT_INDICATOR_LIST_REQUEST:
      newState = {
        ...state,
        message: null,
        error: null,
      };
      return newState;

    case Types.GET_PRODUCT_INDICATOR_LIST_SUCCESS:
      newState = {
        productIndicatorList: payload.result.data,
        args: payload.result.args,
        error: null,
      };
      return newState;

    case Types.GET_PRODUCT_INDICATOR_LIST_FAILURE:
      newState = {
          ...state,
        error: payload.error,
      };
      return newState;

    case Types.GET_DATA_STATUS_REQUEST:
      newState = {
        ...state,
        message: null,
        error: null,
      };
      return newState;
    
    case Types.GET_DATA_STATUS_SUCCESS:
      newState = {
        dataStatus: payload.result.data,
        args: payload.result.args,
        error: null,
      };
      return newState;
    
    case Types.GET_DATA_STATUS_FAILURE:
      newState = {
        ...state,
        error: payload.error,
      };
      return newState;

    case Types.GET_PORTFOLIO_SNAPSHOT_REQUEST:
      newState = {
        ...state,
        message: null,
        error: null,
      };
      return newState;
    
    case Types.GET_PORTFOLIO_SNAPSHOT_SUCCESS:
      newState = {
        portfolioSnapshot: payload.result.data,
        args: payload.result.args,
        error: null,
      };
      return newState;
    
    case Types.GET_PORTFOLIO_SNAPSHOT_FAILURE:
      newState = {
        ...state,
        error: payload.error,
      };
      return newState;

    case Types.GET_PORTFOLIO_SCORE_REQUEST:
      newState = {
        ...state,
        message: null,
        error: null,
      };
      return newState;
    
    case Types.GET_PORTFOLIO_SCORE_SUCCESS:
      newState = {
        portfolioScore: payload.result.data,
        args: payload.result.args,
        error: null,
      };
      return newState;
    
    case Types.GET_PORTFOLIO_SCORE_FAILURE:
      newState = {
        ...state,
        error: payload.error,
      };
      return newState;

    default:
      return state;
  }
};

export default productDetail;
