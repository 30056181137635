// @flow

import React, { Component } from 'react';

import AppBar from '@material-ui/core/AppBar';

import { connect } from 'react-redux';

import Toolbar from './components/toolbar';


type Props = {
  history: Object,
};

class Header extends Component<Props, State> {

  render() {
    return (
      <AppBar
        // style={{ position: 'fixed' }}
        position="static"
        color="secondary"
      >
        <Toolbar />
      </AppBar>
    );
  }
}

export default connect()(Header);
