// @flow

import { Component } from 'react';
import { withRouter } from 'react-router-dom';


class ErrorBoundary extends Component {
  redirectToErrorPage() {
    const { history } = this.props;

    history.push('/error');
  }

  componentDidCatch(error, info) {
    this.redirectToErrorPage(error, info);
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
