// @flow

import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

import styled from 'styled-components';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import StrategyDescription from './components/strategy-description';
import PortfolioReturn from './components/portfolio-return';
import AssetAllocation from './components/asset-allocation';
import TargetPortfolio from './components/target-portfolio';
import AssetAllocationTrend from './components/asset-allocation-trend';
import ProductIndicator from './components/product-indicator';
import DataStatus from './components/data-status';
import PortfolioScore from './components/portfolio-score';

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const Wrapper = styled(Paper)`
  margin-bottom: 16px;
  padding: 16px 32px 0 32px;
  background-color: #27293d !important;
`;

const DataStatusWrapper = styled(Paper)`
  margin-bottom: 16px;
  padding: 16px 32px 0 32px;
  background-color: #27293d !important;
  max-height: 55px;
  text-align: right;
`;

const BackIconWrapper = styled(Wrapper)`
  margin-top: 15px;
  padding-bottom: 10px;
  cursor: pointer !important;
`;

const LineWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LeftSideContainer = styled(Paper)`
  margin-bottom: 16px;
  padding: 16px 32px 0 32px;
  align-items: center;
  background-color: #27293d !important;
  @media ( max-width: 415px ) {
    margin-bottom: 0;
  }
`;

const RightSideContainer = styled(Paper)`
  margin-bottom: 16px;
  padding: 16px 32px 0 32px;
  align-items: center;
  background-color: #27293d !important;
`;

type Props = {
}

class ProductDetail extends Component<Props, State> {

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  componentDidUpdate(prevProps, prevState) {
  }

  getMpCode = () => {
    //  URL에 mpCode 넣어서 들어온 경우
    let { params: { mpCode } } = this.props.match;

    //  state에 넣어서 들어온 경우
    if (!mpCode) {
      mpCode = this.props.location.state.mpCode;
    }

    return mpCode;
  };

  getAuthLevel = (mpCode) => {
    const { fundsInfo } = this.props;
    let authLevel = "0";

    if (fundsInfo) {
      const status = fundsInfo.filter(item => item.MP_CODE == mpCode);
      if (status.length == 1) {
        authLevel = status[0].GET_AUTH_LEVEL;
      }
    }

    return authLevel;
  }


  handleBackClick = () => {
    const { history } = this.props;
    history.push('/product-list');
  }

  renderDescription = (mpCode): Object => {
    return (
      <StrategyDescription 
        mpCode={mpCode}
      />
    );
  };

  renderDataStatus = (mpCode): Object => {
    return (
      <DataStatus 
        mpCode={mpCode}
      />
    );
  };

  renderPortfolioReturn = (mpCode): Object => {
    return (
      <PortfolioReturn 
        mpCode={mpCode}
      />
    );
  };

  renderAssetAllocation = (mpCode): Object => {
    return (
      <AssetAllocation 
        mpCode={mpCode}
      />
    );
  };

  renderTargetPortfolio = (mpCode, authLevel): Object => {
    return (
      <Wrapper>
        <TargetPortfolio 
          mpCode={mpCode}
          authLevel={authLevel}
        />
      </Wrapper>
    );
  };

  renderAssetAllocationTrend = (mpCode, hasViewAuth): Object => {
    return (
      <Wrapper>
        <AssetAllocationTrend 
          mpCode={mpCode}
          hasViewAuth={hasViewAuth}
        />
      </Wrapper>
    );
  };

  renderPortfolioScore = (mpCode, authLevel): Object => {
    return (
      <Wrapper>
        <PortfolioScore 
          mpCode={mpCode}
          authLevel={authLevel}
        />
      </Wrapper>
    );
  };

  renderProductIndicator = (mpCode): Object => {
    return (
      <ProductIndicator
      mpCode={mpCode}
      />      
    );
  };

  render() {
    const mpCode = this.getMpCode();
    const authLevel = this.getAuthLevel(mpCode);

    return (
      <Container>
        <BackIconWrapper onClick={this.handleBackClick}>
          <KeyboardBackspaceIcon/>
        </BackIconWrapper>
        <Wrapper>
          {this.renderDescription(mpCode)}
        </Wrapper>
        <DataStatusWrapper>
          {this.renderDataStatus(mpCode)}
        </DataStatusWrapper>
        <Wrapper>
          {this.renderPortfolioReturn(mpCode)}
        </Wrapper>
          {authLevel !== "2" && this.renderTargetPortfolio(mpCode, authLevel)}
          {authLevel !== "2" && this.renderAssetAllocationTrend(mpCode, authLevel !== "2")}
          {authLevel !== "2" && this.renderPortfolioScore(mpCode, authLevel)}
        <Wrapper>
          {this.renderProductIndicator(mpCode)}
        </Wrapper>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  fundsInfo: state.productList.fundsInfo,
  auth: state.auth,
});

export default connect(mapStateToProps)(withRouter(ProductDetail));
