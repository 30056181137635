// @flow

import React from 'react';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import styled, { ThemeProvider } from 'styled-components';
import { Provider } from 'react-redux';

import { Helmet } from 'react-helmet';

// import './config/reactotron';
import GlobalStyle from './styles/global';

import Root from './components/Root';

import AppTheme from './styles';
import store from './store';

const TITLE = 'QRAFT EMP';

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.containerColor};
`;

const theme = createMuiTheme({
  typography: {
    body2: {
      color: AppTheme.colors.white,
    },
    h6: {
      fontFamily: 'Noto Sans KR',
    }
  },  
  palette: {
    primary: { 
      main: AppTheme.colors.blue800,
    },
    secondary: { 
      main: AppTheme.colors.blue,
    },
    text: {
      primary: AppTheme.colors.white,
      secondary: AppTheme.colors.white,
      disabled: "rgba(0, 0, 0, 0.90)",
      hint: AppTheme.colors.white,
    },
    action: {
      disabled: "rgba(0, 0, 0, 0.74)",
    },
  },
  overrides: {
    MuiPaper: { // Name of the component 
      root: { // Name of the rule
        color: AppTheme.colors.white, // Some CSS
        "background-color": '#27293d'
      },
    },
    MuiSpeedDialAction: {
      fab: {
        "background-color": AppTheme.colors.blue800
      },
      staticTooltipLabel: {
        "background-color": AppTheme.colors.blue800
      },
    },
    MuiListItemIcon: { // Name of the component 
      root: { // Name of the rule
        color: AppTheme.colors.white, // Some CSS
      },
    },
    MuiInputBase: { // Name of the component 
      input: { // Name of the rule
        color: AppTheme.colors.darkText, // Some CSS
      },
    },
    MuiButton: { // Name of the component 
      root: { // Name of the rule
        minWidth: '50px', // Some CSS
      },
    },
  },
});
const App = () => (
  <MuiThemeProvider
    theme={theme}
  >
    <Helmet>
      <title> {TITLE} </title>
    </Helmet>
    <GlobalStyle />
    <ThemeProvider
      theme={AppTheme}
    >
      <Wrapper>
        <Provider
          store={store}
        >
          <Root />
        </Provider>
      </Wrapper>
    </ThemeProvider>
  </MuiThemeProvider>
);

export default App;
