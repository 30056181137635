import { call, cancelled, put } from 'redux-saga/effects';

import { Creators as AuthCreators } from '../ducks/auth';

import axios from 'axios'

import { getUrl } from './execRequest';


export const USER_NAME_SESSION_ATTRIBUTE_NAME = 'authenticatedUser';
export const TOKEN_SESSION_ATTRIBUTE_NAME = 'authenticatedToken';


export function doAuthorize(username, password) {
  const url = `${getUrl()}/api/login/authenticate`;
  
  var formData = new FormData();
  formData.append('grant_type', 'password');
  formData.append('username', username);
  formData.append('password', password);

  return axios({
    method: 'post',
    url: url,
    data: formData
  });
}

export function doRefresh() {
  const url = `${getUrl()}/api/login/refresh`;
  
  return axios({
    method: 'get',
    url: url,
  });
}

export function* authorize(request) {
  try {
    yield put(AuthCreators.processing());

    const user = request.payload.user;
    const result = yield call(doAuthorize, user.username, user.password)
    yield doSuccessfulLoggedInRoutine(result, user.username);

  } catch (err) {
    console.error(err);
    yield put(AuthCreators.loginError());
  } finally {
    if (yield cancelled()) {
      yield put(AuthCreators.loginCancelled());
    }
  }
}

export function* checkLoggedIn() {
  try {
    let username = null;
    if (isUserLoggedIn()) {
      const token = getLoggedInToken();
      username = getLoggedInUserName();
      setupAxiosInterceptors(createJWTToken(token));

      const result = yield call(doRefresh);
      yield doSuccessfulLoggedInRoutine(result, username);
    }
  } catch (err) {
    console.error(err);
    yield put(AuthCreators.loginError());
  } finally {
    if (yield cancelled()) {
      yield put(AuthCreators.loginCancelled());
    }
  }
}

export function* doSuccessfulLoggedInRoutine(result, username) {
  const authorities = [];
  const newUser = {
    username: username,
    // TODO: Admin function migration needed
    // isAdmin: result.data.isAdmin,
    isAdmin: false,
  };
  
  const token = result.data.access_token
  yield put(AuthCreators.saveToken(token));
  registerSuccessfulLogin(username, token);

  yield put(AuthCreators.loginSuccess(newUser));
}

export function* deleteToken() {
  sessionStorage.removeItem(TOKEN_SESSION_ATTRIBUTE_NAME);
  setupAxiosInterceptors(null);
  yield put(AuthCreators.deleteToken());
}

/****************************************************************************
 * SessionStorage & Authorization Token related methods
 ****************************************************************************/

export function logout() {
  sessionStorage.removeItem(USER_NAME_SESSION_ATTRIBUTE_NAME);
}

export function registerSuccessfulLogin(username, token) {
  sessionStorage.setItem(USER_NAME_SESSION_ATTRIBUTE_NAME, username);
  sessionStorage.setItem(TOKEN_SESSION_ATTRIBUTE_NAME, token);
  setupAxiosInterceptors(createJWTToken(token));
}

export function createJWTToken(token) {
  return 'Bearer ' + token
}

export function isUserLoggedIn() {
  let user = sessionStorage.getItem(USER_NAME_SESSION_ATTRIBUTE_NAME);
  if (user == null) {
    return false;
  } else {
    return true;
  }
}

export function getLoggedInUserName() {
  let user = sessionStorage.getItem(USER_NAME_SESSION_ATTRIBUTE_NAME);
  if (user == null) {
    return '';
  } else {
    return user;
  }
}

export function getLoggedInToken() {
  let token = sessionStorage.getItem(TOKEN_SESSION_ATTRIBUTE_NAME);
  if (token == null) {
    return '';
  } else {
    return token;
  }
}

export function setupAxiosInterceptors(token) {
  axios.interceptors.request.use(
    (config) => {
      if (isUserLoggedIn()) {
        config.headers.authorization = token;
      } else {
        config.headers.authorization = null;
      }
      return config;
    }
  )
}