// @flow

import React, { Component } from 'react';

import Typography from '@material-ui/core/Typography';

import styled from 'styled-components';

import { connect } from 'react-redux';


const Container = styled.div`
  margin: 30px 4px 0 4px;
  min-height: 120px !important;
`;

const TypographyTopWrapper = styled(({ ...props }) => (
  <Typography
    {...props}
  />
))`
  font-weight: bold !important;
`;

const TypographyBottomWrapper = styled(({ ...props }) => (
  <Typography
    {...props}
  />
))`
  font-style: italic;
`;

class Footer extends Component<Props, State> {

  render() {
    return (
      <Container>
        <TypographyTopWrapper variant="body2" gutterBottom>
	 Qraft makes no representation or warranty as to the actual performance of the
	 trades executed on behalf of the investor through Managed Portfolios. Qraft
	 presents strategies, not advices, developed and selected by a proprietary
         artificial intelligence selection model with data and information supplied by
	 third parties. Some of such strategies operate with leverage due to the use
	 of margin products and a high degree of discretion and exposures towards single
	 securities and asset classes, and as such EMP may entail significant risks.         
        </TypographyTopWrapper>
        <TypographyBottomWrapper variant="body2" gutterBottom>
          All investments entail risks and may result in both profits and losses. In 
          particular, investments in leveraged products, including (but not limited to) 
          foreign exchange, derivatives and commodities can be very speculative and 
          profits and losses may fluctuate both violently and rapidly. Speculative 
          trading is not suitable for all investors and all investors should carefully 
          consider their financial situation and seek independent financial advice in 
          order to understand the risks involved and ensure their suitability prior to 
          making any investment, divestment or entering into any transaction.
        </TypographyBottomWrapper>
        <TypographyBottomWrapper variant="body2" gutterBottom>
          Past performance is not indicative of future performance and should not be 
          the sole factor of consideration when selecting a portfolio or product to 
          invest in. The price of the investments may fluctuate and the investor’s 
          income is not fixed and the investor may not recover the amounts invested. 
          The value of investments involving exposure to foreign currencies may be 
          affected by exchange rate movements.
        </TypographyBottomWrapper>
        <TypographyBottomWrapper variant="body2" gutterBottom>
          Qraft shall not be liable for (i) any loss which the investor may incur as a 
          consequence of the trading and any transactions undertaken by Qraft in 
          accordance with the power of attorney or similar authorizations and the EMP 
          terms and conditions (the "Terms"); (ii) any loss suffered or incurred by the 
          investor as a result of or in connection with Qraft’s provision of EMP under 
          the Terms; (iii) any loss due to actions taken by Qraft according to its 
          rights under the Terms; (iv) any consequential or other indirect loss 
          (including loss of profit and loss of goodwill) suffered or incurred by the 
          investor whether arising from Qraft's negligence or otherwise; or (v) any 
          loss exceeding Qraft’s revenue on the investment account(s) of the investor 
          for any 12 month period prior to the investor raising a claim against Qraft.
        </TypographyBottomWrapper>
      </Container>
    );
  }
}

export default connect()(Footer);
