// @flow

import React, { Component } from 'react';

import styled from 'styled-components';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Creators as ProductDetailCreators } from '../../../../store/ducks/productDetail';

import moment from 'moment'

import TitleWithDate from './components/TitleWithDate';
import AssetAllocationChart from './components/AssetAllocationChart';

const Container = styled.div`
  margin: 0 4px;
  height: 345px;
`;

const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 16px;
`;

const ChartWrapper = styled.div`
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

type Props = {
  getAssetAllocationList: Function,
  list: Array<Object>,
  mpCode: String,
}

class AssetAllocation extends Component<Props, State> {
  
  shouldComponentUpdate(nextProps, nextStats) {
    const vitalPropsChange = 
      nextProps.list != null &&
      typeof nextProps.list !== 'undefined' &&
      nextProps.args != null &&
      typeof nextProps.args !== 'undefined' &&
      nextProps.args.key != null &&
      typeof nextProps.args.key !== 'undefined' &&
      nextProps.args.key === 'pie';
    
    return vitalPropsChange;
  }  

  componentDidMount() {
    const { getAssetAllocationList, mpCode } = this.props;

    const today = moment();
	  let endDate = today.format("YYYY-MM-DD");
	  let startDate = today.add(-4, 'M').format("YYYY-MM-DD");

    const params = {
      key: 'pie',
      mp_code: mpCode,
      start_dt: startDate,
      end_dt: endDate,
    };

    getAssetAllocationList(params);
  }

  renderTitleWithDate = (rebalDate): Object => {
    const title = 'Asset Allocation';
    let date = '';

    if (typeof rebalDate !== 'undefined' && rebalDate !== null) {
      date = moment(rebalDate).format('MMM. Do, YYYY');
    }

    return (
      <TitleWithDate
        text={title}
        date={`(as of ${date})`}
      />
    );
  };

  renderChart = (data: Array<Object>): Object => {
    return (
      <ChartWrapper>
        <AssetAllocationChart
          data={data}
        />
      </ChartWrapper>
    );
  };

  render() {
    const { list } = this.props;
    const data = typeof list !== 'undefined' ? list[list.length - 1].CONTENTS : null;
    const date = typeof list !== 'undefined' ? list[list.length - 1].STK_DT : null;

    return (
      <Container>
        <HeaderWrapper>
          {this.renderTitleWithDate(date)}
        </HeaderWrapper>
        {this.renderChart(data)}
      </Container>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators(ProductDetailCreators, dispatch);

const mapStateToProps = state => ({
  list: state.productDetail.assetAllocationList,
  args: state.productDetail.args,
});

export default connect(mapStateToProps, mapDispatchToProps)(AssetAllocation);
