import React, { Component, Fragment } from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Creators as BannerCreators } from '../../store/ducks/banner';

import EntityComponent from '../../components/common/entity-component';
import Snackbar from '../../components/common/Snackbar';

import config from './config';
import BannerForm from './form';

type Props = {
  getAllBanners: Function,
  createBanner: Function,
  banners: Array<Object>,
  removeBanner: Function,
  editBanner: Function,
};

type State = {
  isSnackbarOpen: boolean,
};

class Banner extends Component<Props, State> {
  state = {
    isSnackbarOpen: false,
  };

  componentDidMount() {
    const { getAllBanners } = this.props;

    getAllBanners();
  }

  componentWillReceiveProps(nextProps) {
    const { message, error } = nextProps.banners;

    if (message || error) {
      this.setState({
        isSnackbarOpen: true,
      });
    }
  }

  onCreateBanner = async (banner: Object): void => {
    const { createBanner } = this.props;

    createBanner(banner);
  };

  onEditBanner = async (bannerEdited: Object): void => {
    const { editBanner } = this.props;

    editBanner(bannerEdited);
  };

  onRemoveBanner = async (ID: string): void => {
    const { removeBanner } = this.props;

    removeBanner({ID});
  };

  renderSnackbar = (stock: Object): Object => {
    const { isSnackbarOpen } = this.state;
    const { message, error } = stock;

    return (
      <Snackbar
        onCloseSnackbar={() => this.setState({ isSnackbarOpen: false })}
        isOpen={isSnackbarOpen}
        message={message}
        error={error}
      />
    );
  };

  render() {
    const { banners } = this.props;
    const data = banners.data.map((item) => ({id: item.ID, ...item}))

    return (
      <Fragment>
        <EntityComponent
          onCreateItem={this.onCreateBanner}
          onEditItem={this.onEditBanner}
          onRemoveItem={this.onRemoveBanner}
          singularEntityName="Banner"
          pluralEntityName="Banners"
          filterConfig={config.filterConfig}
          tabConfig={config.tabConfig}
          withOwnRemoveAction={this.onClickRemoveTableIcon}
          dataset={data}
          canBeCreated
          canBeEdited
          canBeRemoved
          Form={props => (
            <BannerForm
              {...props}
            />
          )}
        />
        {this.renderSnackbar(banners)}
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators(BannerCreators, dispatch);

const mapStateToProps = state => ({
  banners: state.banner,
});

export default connect(mapStateToProps, mapDispatchToProps)(Banner);
