import { call, put } from 'redux-saga/effects';

import { Creators as ProductListCreators } from '../ducks/productList';

import {
  READ_FUNDS_INFO,
  READ_ALL_FUNDS_INFO,
} from './event-handlers-types/productList';

import execRequest from './execRequest';
import { PRODUCTLIST } from './entitiesTypes';

const EVENT_TAGS = {
  GET_FUNDS_INFO: 'PRODUCTLIST_GET_FUNDS_INFO',
  GET_ALL_FUNDS_INFO: 'PRODUCTLIST_GET_ALL_FUNDS_INFO',
};

export function* getFundsInfo(args) {
  try {
    const result = yield call(execRequest, PRODUCTLIST, READ_FUNDS_INFO, EVENT_TAGS.GET_FUNDS_INFO, args);
    yield put(ProductListCreators.getFundsInfoSuccess(result));
  } catch (err) {
    yield put(ProductListCreators.getFundsInfoFailure(err));
  }
}

export function* getAllFundsInfo(args) {
  try {
    const result = yield call(execRequest, PRODUCTLIST, READ_ALL_FUNDS_INFO, EVENT_TAGS.GET_ALL_FUNDS_INFO, args);
    yield put(ProductListCreators.getAllFundsInfoSuccess(result));
  } catch (err) {
    yield put(ProductListCreators.getAllFundsInfoFailure(err));
  }
}
