import Immutable from 'seamless-immutable';

export const Types = {
  GET_FUNDS_INFO_REQUEST: 'productList/GET_FUNDS_INFO_REQUEST',
  GET_FUNDS_INFO_SUCCESS: 'productList/GET_FUNDS_INFO_SUCCESS',
  GET_FUNDS_INFO_FAILURE: 'productList/GET_FUNDS_INFO_FAILURE',
  GET_ALL_FUNDS_INFO_REQUEST: 'productList/GET_ALL_FUNDS_INFO_REQUEST',
  GET_ALL_FUNDS_INFO_SUCCESS: 'productList/GET_ALL_FUNDS_INFO_SUCCESS',
  GET_ALL_FUNDS_INFO_FAILURE: 'productList/GET_ALL_FUNDS_INFO_FAILURE',
};

const INITIAL_STATE = Immutable({
  message: null,
  error: null,
  data: [],
  args: null,
});

export const Creators = {
  getFundsInfo: (args) => ({
    type: Types.GET_FUNDS_INFO_REQUEST,
    args,
  }),

  getFundsInfoSuccess: result => ({
    type: Types.GET_FUNDS_INFO_SUCCESS,
    payload: { result },
  }),

  getFundsInfoFailure: () => ({
    type: Types.GET_FUNDS_INFO_FAILURE,
    payload: { error: 'There was a problem when trying to get FundsInfo from Database' },
  }),

  getAllFundsInfo: (args) => ({
    type: Types.GET_ALL_FUNDS_INFO_REQUEST,
    args,
  }),

  getAllFundsInfoSuccess: result => ({
    type: Types.GET_ALL_FUNDS_INFO_SUCCESS,
    payload: { result },
  }),

  getAllFundsInfoFailure: () => ({
    type: Types.GET_ALL_FUNDS_INFO_FAILURE,
    payload: { error: 'There was a problem when trying to get AllFundsInfo from Database' },
  }),
};

const productList = (state = INITIAL_STATE, { payload, type }) => {

  let newState = {
    ...state,
  };

  switch (type) {
    case Types.GET_FUNDS_INFO_REQUEST:
      newState = {
        ...state,
        message: null,
        error: null,
      };
      return newState;

    case Types.GET_FUNDS_INFO_SUCCESS:
      newState = {
        fundsInfo: payload.result.data,
        args: payload.result.args,
        error: null,
      };
      return newState;

    case Types.GET_FUNDS_INFO_FAILURE:
      newState = {
        ...state,
        error: payload.error,
      };
      return newState;
        
    case Types.GET_ALL_FUNDS_INFO_REQUEST:
      newState = {
        ...state,
        message: null,
        error: null,
      };
      return newState;

    case Types.GET_ALL_FUNDS_INFO_SUCCESS:
      newState = {
        allFundsInfo: payload.result.data,
        args: payload.result.args,
        error: null,
      };
      return newState;

    case Types.GET_ALL_FUNDS_INFO_FAILURE:
      newState = {
        ...state,
        error: payload.error,
      };
      return newState;
        
    default:
      return state;
  }
};

export default productList;
