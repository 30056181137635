// @flow

import React, { Component } from 'react';

import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Creators as ProductDetailCreators } from '../../../../store/ducks/productDetail';

import moment from 'moment'

import Title from './components/Title';
import AssetAllocationTrendChart from './components/AssetAllocationTrendChart';
import { parse as parseJson } from 'json2csv';
import FileSaver from 'file-saver';

const Container = styled.div`
  margin: 0 4px;
`;

const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 16px;
`;

const ButtonWrapper = styled(Button)`
  margin-left: 10px !important;
`;

const ChartWrapper = styled.div`
  max-width: 100%;
  min-height: 240px;
`;

const DownloadIcon = styled(CloudDownloadIcon)`
  color: ${({ theme }) => theme.colors.white};
`;

const notSelectedStyle = {
};

const selectedStyle = {
  backgroundColor: '#1e1e2e',
};

type Props = {
  getAssetAllocationList: Function,
  list: Array<Object>,
  args: Object,
  mpCode: String,
  fundsInfo: Object,
  hasViewAuth: Boolean,
}

class AssetAllocationTrend extends Component<Props, State> {

  constructor(...args) {
    super(...args);
    this.selectedLabel = null;
    this.savedList = null;
    this.startDate = null;
    this.endDate = null;
  }

  shouldComponentUpdate(nextProps, nextStats) {
    const vitalPropsChange = 
      nextProps.list != null &&
      typeof nextProps.list !== 'undefined' &&
      nextProps.args != null &&
      typeof nextProps.args !== 'undefined' &&
      nextProps.args.key != null &&
      typeof nextProps.args.key !== 'undefined' &&
      nextProps.args.key === 'trend';

    if (vitalPropsChange) {
      this.savedList = this.getPrecision(JSON.parse(JSON.stringify( nextProps.list )), 3);
    }

    return vitalPropsChange;
  }  
  
  componentDidMount() {
    this.getPeriod('1Y');
  }

  getPrecision = (list, precision) => {
    const condensed = [];
    Array.from(list).forEach((item) => {
      for (const key in item) {
        let value = item[key];
        if (key == 'date') {
          continue;
        } else {
          value = value.toFixed(precision);
          item[key] = value;
        }
      }

      condensed.push(item);
    });
    condensed.sort((a, b) => (b.weights - a.weights));

    return condensed;
  };

  getPeriod = (label) => {
    const { getAssetAllocationList, mpCode } = this.props;
    this.selectedLabel = label;

    const today = moment();
	  this.startDate = "";
	  this.endDate = moment();

    switch(label) {
      case "1M":
        this.startDate = today.add(-1, 'M');
        
        break;
    
      case "3M":
        this.startDate = today.add(-3, 'M');
        break;
  
      case "6M":
        this.startDate = today.add(-6, 'M');
        break;
  
      case "1Y":
        this.startDate = today.add(-1, 'y');
        break;
  
      case "3Y":
        this.startDate = today.add(-3, 'y');
        break;
  
      case "5Y":
        this.startDate = today.add(-5, 'y');
        break;
  
      case "10Y":
        this.startDate = today.add(-10, 'y');
        break;
                          
      case "YTD":
        this.startDate = today.startOf('year');
        break;
  
      case "ITD":
        this.startDate = "ITD";
        break;

      default:
        this.startDate = moment('2019-01-01', 'YYYY-MM-DD');
    }

    if (this.startDate !== "ITD") {
      this.startDate = this.startDate.format("YYYY-MM-DD");
    }
    this.endDate = this.endDate.format("YYYY-MM-DD");

    const params = {
      key: 'trend',
      mp_code: mpCode,
      start_dt: this.startDate,
      end_dt: this.endDate,
    };

    getAssetAllocationList(params);
  };

  getFilledData = (data) => {
    const filled = [];
    let currentContents = null;
    let currentDt = null;

    if (typeof data === 'undefined' || data.length === 0) {
      return data;
    }

    data.forEach((item) => {
      if (currentContents === null) {
        currentContents = item;
        currentDt = moment(item.date);
        currentDt = currentDt.add(1, 'd');
        item.date = currentDt.toDate();
        filled.push(item);
      } else {
        const nextDt = moment(item.date);
        while(currentDt < nextDt) {
          const cloned = JSON.parse(JSON.stringify(currentContents));
          // cloned.date = currentDt.format('YYYY-MM-DD');
          cloned.date = currentDt.toDate()
          currentDt = currentDt.add(1, 'd');
          filled.push(cloned);
        }
        currentDt = nextDt;
        currentContents = item;
      }
    });

    let today = moment();

    while(currentDt < today) {
      const cloned = JSON.parse(JSON.stringify(currentContents));
      // cloned.date = currentDt.format('YYYY-MM-DD');
      cloned.date = currentDt.toDate()
      currentDt = currentDt.add(1, 'd');
      filled.push(cloned);
    }

    const startDt = moment(this.startDate == "ITD" ? "1990-01-01" : this.startDate).toDate();
    const endDt = moment(this.endDate).toDate();
    const filtered = filled.filter(item => {
      return item.date >= startDt && item.date <= endDt;
    })

    return filtered;
  };

  handleCsvDownload = (key, e) => {
    const csv = parseJson(this.savedList);
    const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8' });
    FileSaver.saveAs(csvData, `asset_allocation_trend.csv`);
  };

  renderTitle = (): Object => {
    const title = 'Asset Allocation Trend';

    return (
      <Title>
        {title}
      </Title>
    );
  };

  renderCsvDownloadButton = (): Object => {
    return (
      <Hidden mdDown key='csvDownload'>
        <ButtonWrapper
          key='csvDownload'
          variant="contained"
          size="small"
          color="primary"
          onClick={this.handleCsvDownload}
        >
          <DownloadIcon />
        </ButtonWrapper>
      </Hidden>
    );
  };

  renderButtons = (): Object => {
    const categories = [
      { label: '1M' },
      { label: '3M' },
      { label: '6M' },
      { label: '1Y' },
      { label: '3Y' },
      { label: '5Y' },
      { label: '10Y' },
      { label: 'YTD' },
      { label: 'ITD' },
    ];
    const buttonList = categories.map((category) => {
      let style = notSelectedStyle;
      if (category.label === this.selectedLabel) {
        style = selectedStyle;
      }

      return (
        <Hidden mdDown key={category.label}>
          <ButtonWrapper
            key={category.label}
            variant="contained"
            size="small"
            color="primary"
            term={category.label}
            onClick={() => this.getPeriod(category.label)}
            style = {style}
          >
            {category.label}
          </ButtonWrapper>
        </Hidden>
      );
    });

    return (
      <div>
        {buttonList}
        {this.renderCsvDownloadButton()}
      </div>
    );
  };

  renderChart = (data: Array<Object>): Object => {
    const converted = this.getFilledData(data);

    return (
      <ChartWrapper>
        <AssetAllocationTrendChart
          data={converted}
        />
      </ChartWrapper>
    );
  };

  render() {
    const { list } = this.props;

    return (
      <Container>
        <HeaderWrapper>
          {this.renderTitle()}
          {this.renderButtons()}
        </HeaderWrapper>
        {this.renderChart(list)}
      </Container>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators(ProductDetailCreators, dispatch);

const mapStateToProps = state => ({
  list: state.productDetail.assetAllocationList,
  args: state.productDetail.args,
  fundsInfo: state.productList.fundsInfo,
});

export default connect(mapStateToProps, mapDispatchToProps)(AssetAllocationTrend);
