// @flow

import React, { Component } from 'react';

import Paper from '@material-ui/core/Paper';

import styled from 'styled-components';

import { connect } from 'react-redux';
// import { bindActionCreators } from 'redux';
// import { Creators as AuthCreators } from '../../store/ducks/auth';

import RegisterUserForm from './components/RegisterUserForm';
import LoginForm from './components/LoginForm';
import Snackbar from '../common/Snackbar';
import Logo from '../../images/logo.png';

const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: 750px !important;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const IconWrapper = styled.div`
  max-width: 100%;
`;

const LogoIcon = styled.img.attrs({
  src: Logo,
  alt: 'Logo',
})`
  width: 150px;
  height: 100%;
`;

const LoginTitle = styled.p`
  margin: 16px 0;
  font-size: 28px;
  font-weight: 500;
  color: #fff;
`;

const ContentContainer = styled(({ ...props }) => (
  <Paper
    {...props}
  />
))`
  padding: 24px;
  background-color: #27293d !important;
`;

type Props = {
  getAllUsers: Function,
  users: Array<Object>,
};

type State = {
  isSnackbarOpen: boolean,
  snackbarMessage: string,
  snackbarError: string,
}

class Login extends Component<Props, State> {
  state = {
    isSnackbarOpen: false,
    snackbarMessage: '',
    snackbarError: '',
  };

  onToggleSnackbar = (): void => {
    const { isSnackbarOpen } = this.state;

    this.setState({
      isSnackbarOpen: !isSnackbarOpen,
    });
  };

  setSnackbarMessage = (message: string): void => {
    this.setState({
      isSnackbarOpen: true,
      snackbarMessage: message,
      snackbarError: '',
    });
  };

  setSnackbarError = (error: string): void => {
    this.setState({
      isSnackbarOpen: true,
      snackbarError: error,
      snackbarMessage: '',
    });
  };

  renderLoginForm = (): Object => (
    <LoginForm
      setSnackbarError={this.setSnackbarError}
    />
  );

  render() {
    const { snackbarMessage, isSnackbarOpen, snackbarError } = this.state;

    return (
      <Container>
        <Wrapper>
          <Wrapper>
            <IconWrapper>
              <LogoIcon />
            </IconWrapper>
            <LoginTitle>
              Login
            </LoginTitle>
          </Wrapper>
          <ContentContainer>
            {this.renderLoginForm()}
          </ContentContainer>
        </Wrapper>
        <Snackbar
          onCloseSnackbar={this.onToggleSnackbar}
          message={snackbarMessage}
          isOpen={isSnackbarOpen}
          error={snackbarError}
        />
      </Container>
    );
  }
}

// const mapDispatchToProps = dispatch => bindActionCreators(AuthCreators, dispatch);

// const mapStateToProps = state => ({
//   users: state.user.data,
// });

// export default connect(mapStateToProps, mapDispatchToProps)(Login);
export default connect(null, null)(Login);
