import axios from 'axios';

export function getUrl() {

  let url = '';
  if (process.env.NODE_ENV === 'development') {
    // url = 'http://devop.qrft.io:60180';
    // url = 'http://10.0.0.202:8060';
    // url = 'http://10.0.0.202:8000';
    // url = 'http://localhost:8000';
    url = 'https://dev.qraftemp.com';
  } else if (process.env.NODE_ENV === 'local') {
    url = 'http://localhost:8000';
  } else if (process.env.NODE_ENV === 'production') {
    url = 'https://api.qraftemp.com';
  }

  return url;
}

export default function* execRequest(entity, action, tag, args) {

  let params = {};
  if (typeof args != 'undefined' && args != null && typeof args.args != 'undefined') {
    params = args.args;
  } else if (typeof args != 'undefined' && args != null) {
    params = args;
  }

  const { data } = yield doRequest(entity, action, tag, params);
  return { 
    data,
    args: params,
  };
}

export async function doRequest(entity, action, tag, args) {
  let url;
  let method = 'get';
  let headers = {
    'Content-Type': 'application/json;charset=UTF-8'
  };
  let request = args;
  switch(tag) {
    // Product-List
    case 'PRODUCTLIST_GET_FUNDS_INFO':
      url = '/api/product/list';
      break;
    case 'PRODUCTLIST_GET_ALL_FUNDS_INFO':
      url = '/api/emp/json/emp_all_info';
      break;
    
    // User
    case 'USER_CREATE':
    case 'USER_EDIT':
      method = 'post';
      url = '/api/emp/json/user_modify';
      break;
    case 'USERS_GET_ALL':
      url = '/api/emp/json/user_info';
      break;
    case 'USER_REMOVE':
      method = 'post';
      url = '/api/emp/json/user_remove';
      break;
    case 'USER_PASSWORD_EDIT':
      method = 'post';
      url = '/api/operation/change_password';
      break;
    
    // Role
    case 'ROLE_CREATE':
    case 'ROLE_EDIT':
      method = 'post';
      url = '/api/emp/json/role_modify';
      break;
    case 'ROLES_GET_ALL':
      url = '/api/emp/json/role_info';
      break;
    case 'ROLE_REMOVE':
      method = 'post';
      url = '/api/emp/json/role_remove';
      break;
    
    // Banner
    case 'BANNER_CREATE':
    case 'BANNER_EDIT':
      method = 'post';
      url = '/api/emp/json/banner_modify';
      headers = {
        'Content-Type': 'multipart/form-data'
      };
      request = new FormData();
      for (let [key, value] of Object.entries(args)) {
        request.append(key, value);
      }
      break;
    case 'BANNERS_GET_ALL':
      url = '/api/banner/list';
      break;
    case 'BANNER_REMOVE':
      method = 'post';
      url = '/api/emp/json/banner_remove';
      break;
  
    // Product-Detail
    case 'PRODUCTDETAIL_GET_ASSET_ALLOCATION_LIST':
      url = '/api/product/portfolio_category';
      break;
    case 'PRODUCTDETAIL_GET_PRODUCT_INDICATOR_LIST':
      url = '/api/product/index_statistics';
      break;
    case 'PRODUCTDETAIL_GET_PORTFOLIO_SNAPSHOT':
      url = '/api/product/portfolio_snapshot';
      break;
    case 'PRODUCTDETAIL_GET_PORTFOLIO_SCORE':
      url = '/api/product/score';
      break;
    case 'PRODUCTDETAIL_GET_PORTFOLIO_RETURN':
    default:
      url = '/api/product/index';
      break;
  }

  url = `${getUrl()}${url}`

  if (method === 'get') {
    return await axios({
      method: method,
      url: url,
      params: request,
      headers: headers
    });
  } else {
    return await axios({
      method: method,
      url: url,
      data: request,
      headers: headers
    });
  
  }
}