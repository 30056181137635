/* eslint-disable react/no-string-refs */
// @flow

import React, { Component } from 'react';

import styled from 'styled-components';

import { connect } from 'react-redux';

import * as d3 from 'd3';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

type Props = {
  data: Object,
}

class AssetAllocationChart extends Component<Props, State> {

  constructor(...args) {
    super(...args);
    this.childKey = 0;
  }

  shouldComponentUpdate(nextProps, nextStats) {
    const vitalPropsChange = 
      nextProps.data != null &&
      typeof nextProps.data !== 'undefined';
    
    return vitalPropsChange;
  }  

  componentDidUpdate(prevProps, prevState) {
    
    const { data } = this.props;

    if (data != null && typeof data != 'undefined') {
      /******************************************************************
       * 서버에서 내려온 TYPE, RATIO의 이름을 바꾼다.
       ******************************************************************/
      const converted = [];
      // data.forEach((item, index) => {
      //   converted.push(this.createData(item.TYPE, item.RATIO * 100));
      // });
      for (const [key, value] of Object.entries(data)) {
        if (key != "date")
          converted.push(this.createData(key, value * 100));
      }
  
      this.drawChart(converted);
    }
  }

  createData = (name, value) => {
    return {
      name, value: value.toFixed(2),
    };
  };

  drawChart = (data) => {
    // set the dimensions and margins of the graph
    const width = 1000;
    const height = 300;
    const radius = Math.min(width, height) / 2;

    let colorRange = d3.schemeBlues[data.length];
    if (data.length <= 2) {
      colorRange = d3.schemePaired.slice(0, data.length);
    }

    const color = d3.scaleOrdinal()
      .domain(data.map((d) => d.name))
      .range(colorRange);

    const arc = d3.arc()
      .outerRadius(radius * 0.9)
      .innerRadius(radius * 0.5)
    ;

    const pie = d3.pie()
      .sort(null)
      .value((d) => d.value);

    const arcs = pie(data);

    const svg = d3.select(this.refs.chartDiv)
      .append('svg')
      .attr('width', '500px')
      .attr('height', '100%')
      .attr('viewBox', [-500, -150, 1000, 500])
      .append('g')
      // .attr('transform', `translate(${width / 2}, ${height / 2})`)
      ;

    /* ------- PIE SLICES -------*/
    svg.append('g')
      .attr('stroke', 'white')
      .selectAll('path')
      .data(arcs)
      .join('path')
      .attr('fill', (d) => color(d.data.name))
      .attr('d', arc);
    
    //++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    // LEGEND
    //++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    const size = 22;
    const legendCnt = data.length;
    const itemWidth = 160;
    svg.append('g')
      .selectAll('rect')
      .data(data)
      .join('rect')
      .attr('x', (d, i) => {
        const level = i - legendCnt / 2;
        let xVal = (width / 2) + (level * itemWidth) - 490
        return xVal;
      })
      .attr('y', 230)
      .attr('width', size)
      .attr('height', size)
      .style('fill', (d) => color(d.name))
    ;

    svg.append('g')
      .selectAll('text')
      .data(data)
      .join('text')
      .attr('x', (d, i) => {
        const level = i - legendCnt / 2;
        let xVal = (width / 2) + (level * itemWidth) - 490
        return xVal + 30;
      })
      .attr('y', 244)
      .style('fill', 'White')
      .text((d) => {
        let text = d.name;
        if (text.length > 13) {
          text = text.substring(0, 9) + " ...";
        }
        return text
      })
      .attr('text-anchor', 'left')
      .style('alignment-baseline', 'middle')
      .attr('font-size', 18)
      .attr('font-weight', 'bold')
    ;

  }

  render() {
    this.childKey++;
    return (
      <Wrapper key={this.childKey}>
        <div ref="chartDiv" />
      </Wrapper>
    );
  }
}

export default connect(null, null)(AssetAllocationChart);
