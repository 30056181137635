const productDetailEventTypes = {
  READ_PORTFOLIO_RETURN: 'READ_PORTFOLIO_RETURN',
  READ_ASSET_ALLOCATION_LIST: 'READ_ASSET_ALLOCATION_LIST',
  READ_TARGET_PORTFOLIO_LIST: 'READ_TARGET_PORTFOLIO_LIST',
  READ_PRODUCT_INDICATOR_LIST: 'READ_PRODUCT_INDICATOR_LIST',
  READ_DATA_STATUS: 'READ_DATA_STATUS',
  READ_PORTFOLIO_SNAPSHOT: 'READ_PORTFOLIO_SNAPSHOT',
  READ_PORTFOLIO_SCORE: 'READ_PORTFOLIO_SCORE',
};

module.exports = productDetailEventTypes;
