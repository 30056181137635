// @flow

import React, { Component } from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import styled from 'styled-components';


const StyledTable = styled(Table)`
`;

const StyledTableCell = styled(TableCell)`
  padding: 0px 10px 5px 0px !important;
  background-color: #27293d !important;
  border-bottom: none !important;
  font-weight: bold !important;
`;

type Props = {
  summary: Object,
};

class Summary extends Component<Props, State> {

  shouldComponentUpdate(nextProps, nextStats) {
    const vitalPropsChange = 
      nextProps.summary !== this.props.summary &&
      nextProps.summary != null &&
      typeof nextProps.summary !== 'undefined' &&
      typeof nextProps.summary.index !== 'undefined' &&
      nextProps.summary.index !== '-';
    
    return vitalPropsChange;
  }  

  render() {
    const { summary } = this.props;
    const type = summary.type;
    const index = summary.index;
    let indexChange = summary.indexChange;
    const indexSign = indexChange < 0 ? '▼' :
                        indexChange > 0 ? '▲' : '-';
    indexChange = Math.abs(indexChange);
    const indexChangePct = summary.indexChangePct;
    const rebalDate = summary.rebalDate;
    const indexDate = summary.indexDate;
    const initDate = summary.initDate;

    return (
      <StyledTable aria-label="target portfolio">
        <TableBody>
          <TableRow key="firstRow">
            {/* <StyledTableCell align="left">
              Target
            </StyledTableCell>
            <StyledTableCell align="left">
              {type}
            </StyledTableCell>
          </TableRow>
          <TableRow key="secondRow"> */}
            <StyledTableCell align="left">
              Index
            </StyledTableCell>
            <StyledTableCell align="right">
              {`${index} ${indexSign} ${indexChange} (${indexChangePct}%)`}
            </StyledTableCell>
          </TableRow>
          <TableRow key="secondRow">
            <StyledTableCell align="left">
              Rebalanced on
            </StyledTableCell>
            <StyledTableCell align="right">
              {rebalDate}
            </StyledTableCell>
          </TableRow>
          <TableRow key="thirdRow">
            <StyledTableCell align="left">
              Updated as of
            </StyledTableCell>
            <StyledTableCell align="right">
              {indexDate}
            </StyledTableCell>
          </TableRow>
          <TableRow key="fourthRow">
            <StyledTableCell align="left">
              Inception Date
            </StyledTableCell>
            <StyledTableCell align="right">
              {initDate}
            </StyledTableCell>
          </TableRow>
        </TableBody>
      </StyledTable>
    );
  }
}

export default Summary;
