import React from 'react';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import styled from 'styled-components';

import { withFormik, Form } from 'formik';
import * as Yup from 'yup';

import ActionFormButton from '../../../components/common/ActionFormButton';

import {
  renderSectionTitle,
  Section,
  Wrapper,
  Row,
} from '../../../components/common/FormUtils';

const Label = styled.p`
  margin-bottom: 4px;
  color: ${({ theme, error }) => error && theme.colors.danger}
`;

const StyledSelect = styled(Select)`
  min-width: 400px !important;
  color: ${({ theme, error }) => error && theme.colors.danger}
`;

const ItemLine = styled(Wrapper)`
  margin-bottom: 24px;
`;

const StyledRadioGroup = styled(RadioGroup)`
  margin-left: -15px;
`;

type Props = {
  onChageFormToEditMode: Function,
  handleSubmit: Function,
  onRemoveItem: Function,
  isSubmitting: boolean,
  item: Object,
  mode: string,
};

const RoleForm = (props: Props): Object => {
  const [userId, setUserId] = React.useState(props.item.USER_ID);
  const [mpCode, setMpCode] = React.useState(props.item.MP_CODE);
  const [authLevel, setAuthLevel] = React.useState(props.item.AUTH_LEVEL);

  const handleUserIdChange = event => {
    const { values } = props;

    const value = event.target.value;
    setUserId(value);
    values.username = value;
  }

  const handleMpCodeChange = event => {
    const { values } = props;

    const value = event.target.value;
    setMpCode(value);
    values.mpCode = value;
  }

  const handleAuthLevelChange = event => {
    const { values } = props;

    const value = event.target.value;
    setAuthLevel(value);
    values.authLevel = value;
  }

  const renderMenuItem = (value: String, text: String): Object => {
    return (
      <MenuItem value={value}>{text}</MenuItem>
    )
  }

  const renderUsernameSelect = (users: Array<Object>): Object => {
    return (
      <ItemLine>
        <Label>
          Username
        </Label>
        <StyledSelect name="username" value={userId} onChange={handleUserIdChange} displayEmpty>
          {
            users.map((item, idx) => renderMenuItem(item['USER_ID'], item['USER_ID']))
          }
        </StyledSelect>
      </ItemLine>
    );
  }

  const renderMpCodeSelect = (fundsInfo: Array<Object>): Object => {
    return (
      <ItemLine>
        <Label>
          Engine
        </Label>
        <StyledSelect name="mpCode" value={mpCode} onChange={handleMpCodeChange} displayEmpty>
          {
            fundsInfo.map((item, idx) => renderMenuItem(item['MP_CODE'], item['NAME']))
          }
        </StyledSelect>
      </ItemLine>
    );
  }

  const renderAuthLevelRadio = (): Object => {
    return (
      <ItemLine>
        <Label>
          Authentication Level
        </Label>
        <StyledRadioGroup aria-label="authLevel" value={authLevel} onChange={handleAuthLevelChange} row>
          <FormControlLabel
            value="0"
            control={<Radio color="primary" />}
            label="Portfolio List Visible"
            labelPlacement="bottom"
          />
          <FormControlLabel
            value="1"
            control={<Radio color="primary" />}
            label="Portfolio Details Visible"
            labelPlacement="bottom"
          />
          <FormControlLabel
            value="2"
            control={<Radio color="primary" />}
            label="Details Hidden"
            labelPlacement="bottom"
          />
        </StyledRadioGroup>
      </ItemLine>
    );
  }

  const renderRoleSection = (): Object => {
    const { users, fundsInfo } = props;

    return (
      <Section>
        {renderSectionTitle('Role Info')}
        {renderUsernameSelect(users)}
        {renderMpCodeSelect(fundsInfo)}
        {renderAuthLevelRadio()}
      </Section>
    );
  };

  const {
    onChageFormToEditMode,
    onRemoveItem,
    handleSubmit,
    isSubmitting,
    mode,
  } = props;

  return (
    <Wrapper>
      <Form>
        {renderRoleSection()}
        <Row>
          <ActionFormButton
            onChageFormToEditMode={onChageFormToEditMode}
            onRemoveItem={onRemoveItem}
            disabled={isSubmitting}
            onClick={() => {
              handleSubmit()
            }}
            entity="Role"
            mode={mode}
          />
        </Row>
      </Form>
    </Wrapper>
  );
}

const CustomForm = withFormik({
  mapPropsToValues: ({ item }) => ({
    username: item.USER_ID || '',
    mpCode: item.MP_CODE || '',
    authLevel: item.AUTH_LEVEL || '',
  }),

  validationSchema: ({ item, usernames, mode }) => Yup.lazy(() => Yup.object().shape({
    // name: Yup.string()
    //   .required('The Name is required.'),

    // username: Yup.string()
    //   // .test('username-repeated', 'This User has already been registered.', (value) => {
    //   //   const { username } = item;
    //   //   return handleRepeatedFormValues(usernames, username, value, mode);
    //   // })
    //   .required('The Username is requried.'),
  })),

  handleSubmit(values, { setSubmitting, props }) {
    const { onCreateItem, onEditItem, mode } = props;

    const properCallback = (mode === 'edit' ? onEditItem : onCreateItem);

    properCallback({
      USER_ID: values.username,
      MP_CODE: values.mpCode,
      AUTH_LEVEL: values.authLevel,
    });

    setSubmitting(false);
  },
})(RoleForm);

export default CustomForm;
