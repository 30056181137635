import { all, take, takeEvery, takeLatest } from 'redux-saga/effects';

import { Types as AuthTypes } from '../ducks/auth';
import { Types as LoadingTypes } from '../ducks/loading';
import { Types as UserTypes } from '../ducks/user';
import { Types as RoleTypes } from '../ducks/role';
import { Types as BannerTypes } from '../ducks/banner';
import { Types as ProductListTypes } from '../ducks/productList';
import { Types as ProductDetailTypes } from '../ducks/productDetail';


import {
  checkLoggedIn,
  getLoggedInUserName,
  authorize,
  deleteToken,
  logout,
} from './auth';

import {
  initializeInterceptor,
} from './loading';

import {
  createUser,
  getAllUsers,
  editUser,
  removeUser,
  editPassword,
} from './user';

import {
  createRole,
  getAllRoles,
  editRole,
  removeRole,
} from './role';

import {
  createBanner,
  getAllBanners,
  editBanner,
  removeBanner,
} from './banner';

import {
  getFundsInfo, 
  getAllFundsInfo, 
} from './productList';

import {
  getPortfolioReturn, 
  getAssetAllocationList,
  getTargetPortfolioList,
  getProductIndicatorList,
  getDataStatus, 
  getPortfolioSnapshot,
  getPortfolioScore,
} from './productDetail';

export function* rootSaga() {
  return yield all([
    takeLatest(LoadingTypes.LOADING_INITIALIZE, initializeInterceptor),
    takeLatest(AuthTypes.LOGIN_CHECK, checkLoggedIn),
    takeLatest(AuthTypes.GET_LOGIN_NAME, getLoggedInUserName),
    takeLatest(AuthTypes.LOGIN_REQUEST, authorize),
    takeLatest(AuthTypes.LOGOUT, logoutProcess),
    takeLatest(UserTypes.CREATE_REQUEST, createUser),
    takeLatest(UserTypes.GET_ALL_REQUEST, getAllUsers),
    takeLatest(UserTypes.EDIT_REQUEST, editUser),
    takeLatest(UserTypes.REMOVE_REQUEST, removeUser),
    takeLatest(UserTypes.EDIT_PASSWORD_REQUEST, editPassword),
    takeLatest(RoleTypes.CREATE_REQUEST, createRole),
    takeLatest(RoleTypes.GET_ALL_REQUEST, getAllRoles),
    takeLatest(RoleTypes.EDIT_REQUEST, editRole),
    takeLatest(RoleTypes.REMOVE_REQUEST, removeRole),
    takeLatest(BannerTypes.CREATE_REQUEST, createBanner),
    takeLatest(BannerTypes.GET_ALL_REQUEST, getAllBanners),
    takeLatest(BannerTypes.EDIT_REQUEST, editBanner),
    takeLatest(BannerTypes.REMOVE_REQUEST, removeBanner),
    takeEvery(ProductListTypes.GET_FUNDS_INFO_REQUEST, getFundsInfo),
    takeEvery(ProductListTypes.GET_ALL_FUNDS_INFO_REQUEST, getAllFundsInfo),
    takeEvery(ProductDetailTypes.GET_PORTFOLIO_RETURN_REQUEST, getPortfolioReturn),
    takeEvery(ProductDetailTypes.GET_ASSET_ALLOCATION_LIST_REQUEST, getAssetAllocationList),
    takeEvery(ProductDetailTypes.GET_TARGET_PORTFOLIO_LIST_REQUEST, getTargetPortfolioList),
    takeEvery(ProductDetailTypes.GET_PRODUCT_INDICATOR_LIST_REQUEST, getProductIndicatorList),
    takeEvery(ProductDetailTypes.GET_DATA_STATUS_REQUEST, getDataStatus),
    takeEvery(ProductDetailTypes.GET_PORTFOLIO_SNAPSHOT_REQUEST, getPortfolioSnapshot),
    takeEvery(ProductDetailTypes.GET_PORTFOLIO_SCORE_REQUEST, getPortfolioScore),
  ]);
}

function* logoutProcess() {
  yield deleteToken();
  yield logout();
}

export function* logActions() {
  while(true) {
    const action = yield take('*');
    console.log(action);
  }
}
