// import { put } from 'redux-saga/effects';

// import { Creators as LoadingCreators } from '../ducks/loading';

import axios from 'axios'

import store from '../../store'
import { isUserLoggedIn } from './auth'

export function initializeInterceptor() {
  // axios.interceptors.request.use(function* onFulfilled(config) {
  //   if (isUserLoggedIn()) {
  //     yield put(LoadingCreators.loading());
  //   }
  //   return config;
  // }, function* onRejected(error) {
  //   if (isUserLoggedIn()) {
  //     yield put(LoadingCreators.loadingFinished());
  //   }
  //   return Promise.reject(error);
  // })

  // axios.interceptors.response.use(function* onFulfilled(response) {
  //   if (isUserLoggedIn()) {
  //     yield put(LoadingCreators.loadingFinished());
  //   }
  //   return response;
  // }, function* onRejected(error) {
  //   if (isUserLoggedIn()) {
  //     yield put(LoadingCreators.loadingFinished());
  //   }
  //   return Promise.reject(error);
  // })

  //---------------------------------------------------------------------------------------------------------------- 
  // 20200714. 아래 에러 발생해서 Loading 진행하던 것 삭제 (위는 원래 주석이었음)
  // Material-UI: the Menu component doesn't accept a Fragment as a child. Consider providing an array instead.
  //----------------------------------------------------------------------------------------------------------------
  // axios.interceptors.request.use(function onFulfilled(config) {
  //   if (isUserLoggedIn()) {
  //     store.dispatch({type:'loading/LOADING'})
  //   }
  //   return config;
  // }, function onRejected(error) {
  //   if (isUserLoggedIn()) {
  //     store.dispatch({type:'loading/LOADING_FINISHED'})
  //   }
  //   return Promise.reject(error);
  // })

  // axios.interceptors.response.use(function onFulfilled(response) {
  //   if (isUserLoggedIn()) {
  //     store.dispatch({type:'loading/LOADING_FINISHED'})
  //   }
  //   return response;
  // }, function onRejected(error) {
  //   if (isUserLoggedIn()) {
  //     store.dispatch({type:'loading/LOADING_FINISHED'})
  //   }
  //   return Promise.reject(error);
  // })
}
