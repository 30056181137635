import { call, put } from 'redux-saga/effects';

import { Creators as ProductDetailCreators } from '../ducks/productDetail';

import {
  READ_PORTFOLIO_RETURN,
  READ_ASSET_ALLOCATION_LIST,
  READ_TARGET_PORTFOLIO_LIST,
  READ_PRODUCT_INDICATOR_LIST,
  READ_DATA_STATUS,
  READ_PORTFOLIO_SNAPSHOT,
  READ_PORTFOLIO_SCORE,
} from './event-handlers-types/productDetail';

import execRequest from './execRequest';
import { PRODUCTDETAIL } from './entitiesTypes';

const EVENT_TAGS = {
  GET_PORTFOLIO_RETURN: 'PRODUCTDETAIL_GET_PORTFOLIO_RETURN',
  GET_ASSET_ALLOCATION_LIST: 'PRODUCTDETAIL_GET_ASSET_ALLOCATION_LIST',
  GET_TARGET_PORTFOLIO_LIST: 'PRODUCTDETAIL_GET_TARGET_PORTFOLIO_LIST',
  GET_PRODUCT_INDICATOR_LIST: 'PRODUCTDETAIL_GET_PRODUCT_INDICATOR_LIST',
  GET_DATA_STATUS: 'PRODUCTDETAIL_GET_DATA_STATUS',
  GET_PORTFOLIO_SNAPSHOT: 'PRODUCTDETAIL_GET_PORTFOLIO_SNAPSHOT',
  GET_PORTFOLIO_SCORE: 'PRODUCTDETAIL_GET_PORTFOLIO_SCORE',
};

export function* getPortfolioReturn(args) {
  try {
    const result = yield call(execRequest, PRODUCTDETAIL, READ_PORTFOLIO_RETURN, EVENT_TAGS.GET_PORTFOLIO_RETURN, args);
    yield put(ProductDetailCreators.getPortfolioReturnSuccess(result));
  } catch (err) {
    yield put(ProductDetailCreators.getPortfolioReturnFailure(err));
  }
}

export function* getAssetAllocationList(args) {
  try {
    const result = yield call(execRequest, PRODUCTDETAIL, READ_ASSET_ALLOCATION_LIST, EVENT_TAGS.GET_ASSET_ALLOCATION_LIST, args);
    yield put(ProductDetailCreators.getAssetAllocationListSuccess(result));
  } catch (err) {
    yield put(ProductDetailCreators.getAssetAllocationListFailure(err));
  }
}

export function* getTargetPortfolioList(args) {
  try {
    const result = yield call(execRequest, PRODUCTDETAIL, READ_TARGET_PORTFOLIO_LIST, EVENT_TAGS.GET_TARGET_PORTFOLIO_LIST, args);
    yield put(ProductDetailCreators.getTargetPortfolioListSuccess(result));
  } catch (err) {
    yield put(ProductDetailCreators.getTargetPortfolioListFailure(err));
  }
}

export function* getProductIndicatorList(args) {
  try {
    const result = yield call(execRequest, PRODUCTDETAIL, READ_PRODUCT_INDICATOR_LIST, EVENT_TAGS.GET_PRODUCT_INDICATOR_LIST, args);
    yield put(ProductDetailCreators.getProductIndicatorListSuccess(result));
  } catch (err) {
    yield put(ProductDetailCreators.getProductIndicatorListFailure(err));
  }
}

export function* getDataStatus(args) {
  try {
    const result = yield call(execRequest, PRODUCTDETAIL, READ_DATA_STATUS, EVENT_TAGS.GET_DATA_STATUS, args);
    yield put(ProductDetailCreators.getDataStatusSuccess(result));
  } catch (err) {
    yield put(ProductDetailCreators.getDataStatusFailure(err));
  }
}

export function* getPortfolioSnapshot(args) {
  try {
    const result = yield call(execRequest, PRODUCTDETAIL, READ_PORTFOLIO_SNAPSHOT, EVENT_TAGS.GET_PORTFOLIO_SNAPSHOT, args);
    yield put(ProductDetailCreators.getPortfolioSnapshotSuccess(result));
  } catch (err) {
    yield put(ProductDetailCreators.getPortfolioSnapshotFailure(err));
  }
}

export function* getPortfolioScore(args) {
  try {
    const result = yield call(execRequest, PRODUCTDETAIL, READ_PORTFOLIO_SCORE, EVENT_TAGS.GET_PORTFOLIO_SCORE, args);
    yield put(ProductDetailCreators.getPortfolioScoreSuccess(result));
  } catch (err) {
    yield put(ProductDetailCreators.getPortfolioScoreFailure(err));
  }
}