import Immutable from 'seamless-immutable';

export const Types = {
  LOADING_INITIALIZE: 'loading/LOADING_INITIALIZE',
  LOADING: 'loading/LOADING',
  LOADING_FINISHED: 'loading/LOADING_FINISHED',
};

const INITIAL_STATE = Immutable({
  isLoading: false,
  refCount: 0,
});

export const Creators = {
  initializeLoading: () => ({
    type: Types.LOADING_INITIALIZE,
  }),

  loading: () => ({
    type: Types.LOADING,
  }),

  loadingFinished: () => ({
    type: Types.LOADING_FINISHED,
  }),
};

const loading = (state = INITIAL_STATE, { payload, type }) => {

  let newState = {...state};

  switch (type) {
    case Types.LOADING_INITIALIZE:
      newState = { ...INITIAL_STATE };
      return newState;

    case Types.LOADING:
      newState.refCount = newState.refCount + 1;
      newState.isLoading = true;
      return newState;

    case Types.LOADING_FINISHED:
      newState.refCount = newState.refCount - 1;
      if (newState.refCount > 0) {
        newState.isLoading = true;
      } else {
        newState.isLoading = false;
      }
      return newState;

    default:
      return state;
  }
};

export default loading;
