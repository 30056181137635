// @flow

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import styled from 'styled-components';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Creators as BannerCreators } from '../../../../store/ducks/banner';

import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";


const Container = styled.div`
  margin: 0 4px;
  background: #419be0;
  min-height: 95px !important;
`;

const SliderWrapper = styled.div`
`;

const StyledImg = styled.img`
  width: 1084px !important;
  height: 120px !important;
  margin: auto;
`;

type Props = {
  getAllBanners: Function,
}

class BannerCarousel extends Component<Props, State> {

  componentDidMount() {
    const { getAllBanners } = this.props;

    getAllBanners();
  }

  gotoLink = (link) => {
    // 현재 window
    // window.location = link;
    // 다른 탭
    const win = window.open(link, '_blank');
    win.focus();
  }

  renderSlider = (index: Number, fileName: String, link: String): Object => {

    const src = 'https://roboinvest-engine-webapp.s3.ap-northeast-2.amazonaws.com/banner/' + fileName;
    const alt = 'slider ' + index;

    return (
      <SliderWrapper onClick={() => this.gotoLink(link)}>
        <StyledImg src={src} alt={alt} />
      </SliderWrapper>
    )
  }

  render() {
    const { data } = this.props.banners;

    let settings = {
      showThumbs: false,
      autoPlay: true,
      interval: 8000,
      infiniteLoop: true,
      showStatus: false,
    }

    return (
      <Container>
        <Carousel {...settings}>
          {
            data.map((item, idx) => this.renderSlider(idx, item['FILE_NM'], item['LINK']))
          }
        </Carousel>
      </Container>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators(BannerCreators, dispatch);

const mapStateToProps = state => ({
  banners: state.banner,
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BannerCarousel));
