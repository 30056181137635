import { combineReducers } from 'redux';

import auth from './auth';
import loading from './loading';
import user from './user';
import role from './role';
import banner from './banner';
import productList from './productList';
import productDetail from './productDetail';

export default combineReducers({
  auth,
  loading,
  user,
  role,
  banner,
  productList,
  productDetail,
});
