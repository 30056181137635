// @flow

import React from 'react';

import styled from 'styled-components';
import { Hidden } from '@material-ui/core';

const TitleWrapper = styled.div`
  margin-bottom: 0px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LeftSideContainer = styled.div`
  display: flex;
  align-items: center;
`;

const RightSideContainer = styled.div`
  display: flex;
  align-items: center;
`;

type Props = {
  text: string,
  date: string,
};

const TitleWithDate = ({ text, date }: Props): Object => {
  const textText = `${text}`;
  const dateText = `${date}`;

  return (
    <TitleWrapper>
      <LeftSideContainer>
        <h3>
          {textText}
        </h3>
      </LeftSideContainer>
      <Hidden mdDown>
        <RightSideContainer>
          <h4>
            {dateText}
          </h4>
        </RightSideContainer>
      </Hidden>
    </TitleWrapper>
  );
};

export default TitleWithDate;
