// @flow

import React, { Component } from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import { Hidden, Dialog, DialogTitle, DialogContent, TextField, DialogActions } from '@material-ui/core';

import Grid from '@material-ui/core/Grid';

import styled from 'styled-components';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Creators as ProductDetailCreators } from '../../../../store/ducks/productDetail';

import moment from 'moment';
import { parse as parseJson } from 'json2csv';
import FileSaver from 'file-saver';

import TitleWithDate from './components/TitleWithDate';
import AssetAllocationChart from './components/AssetAllocationChart';

const Container = styled.div`
  margin: 0 4px;
  min-height: 345px;
`;

const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 16px;
`;

const LineWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LeftSideContainer = styled.div`
  margin-bottom: 24px;
  align-items: center;
  background-color: #27293d !important;
`;

const RightSideContainer = styled.div`
  padding-bottom: 15px;
  align-items: center;
  background-color: #27293d !important;
  @media ( max-width: 415px ) {
    margin-bottom: 0;
  }
`;

const StyledHeaderCell = styled(TableCell)`
  padding: 10px 24px 10px 16px !important;
  background-color: #A6A6A6 !important;
`;
const StyledTableCell = styled(TableCell)`
  padding: 10px 24px 10px 16px !important;
  background-color: #27293d !important;
  font-size: 0.7rem !important;
`;

const ChartWrapper = styled.div`
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const ButtonDiv = styled.div`
  float: right;
  margin-bottom: 10px !important;
`;

const ButtonWrapper = styled(Button)`
  margin-left: 10px !important;
`;

const StyledTextField = styled(TextField)`
  input {
    color: white !important;
  }
`;

type Props = {
  getPortfolioSnapshot: Function,
  data: Array<Object>,
  mpCode: String,
  authLevel: String,
}

type State = {
  open: Boolean,
};

class TargetPortfolio extends Component<Props, State> {

  state = {
    open: false,
  };

  constructor(...args) {
    super(...args);

    this.savedPort = [];
    this.savedAsset = [];
    
    this.condensed = [];
    this.keys = new Set([]);
    this.types = {};
    this.names = {};
    this.prevPortfolio = null;
    this.lastPortfolio = null;
    this.rebalanceMomentDate = null;

    const today = moment();
	  this.endDate = today.format("YYYY-MM-DD");
	  this.startDate = today.add(-2, 'M').format("YYYY-MM-DD");
  }
  
  shouldComponentUpdate(nextProps, nextStats) {
    const vitalPropsChange = 
      nextProps.data != null &&
      typeof nextProps.data !== 'undefined' &&
      nextProps.data.length !== 0;
    const vitalStateChange = 
      nextStats.open != null &&
      typeof nextStats.open !== 'undefined';
  
    return vitalPropsChange || vitalStateChange;
  }  

  componentDidMount() {
    this.getPortfolioList(this.endDate);
    this.getAssetAllocation(this.startDate, this.endDate);
  }
  
  getPortfolioList = (endDt) => {
    const { getPortfolioSnapshot, mpCode } = this.props;

    const params = {
      mp_code: mpCode,
      datestr: endDt,
    }

    getPortfolioSnapshot(params);
  }

  getAssetAllocation(startDate, endDate) {
    const { getAssetAllocationList, mpCode } = this.props;

    const params = {
      key: 'pie',
      mp_code: mpCode,
      start_dt: startDate,
      end_dt: endDate,
    };

    getAssetAllocationList(params);
  }

  getLastPortDt = (data) => {
    let lastPortDt = null;

    for (const [key, value] of Object.entries(data)) {
      if ((lastPortDt == null || lastPortDt < key) && key != "CHANGE" && key != "CATEGORY" && key != "NAME") {
        lastPortDt = key;
      }
    }

    return lastPortDt;
  }

  initialize = (data) => {
    const lastPortDt = this.getLastPortDt(data);

    const targetPortfolio = [];
    for (const [key, value] of Object.entries(data[lastPortDt])) {
      targetPortfolio.push({
        "STK_CD": key,
        "WEIGHT": value,
        "CHANGE": data["CHANGE"][key],
        "CATEGORY": data["CATEGORY"][key],
        "NAME": data["NAME"][key],
      });
    }
    targetPortfolio.sort((a, b) => (b.WEIGHT - a.WEIGHT));
    this.lastPortfolio = targetPortfolio;
  };

  getPrecision = (precision) => {
    const condensed = [];
    Array.from(this.lastPortfolio).forEach((item) => {
      condensed.push(
        this.createData(
          item.STK_CD, 
          item.CATEGORY, 
          item.NAME, 
          (item.WEIGHT * 100).toFixed(precision),
          (item.CHANGE * 100).toFixed(precision)
        )
      );
    });
    condensed.sort((a, b) => (b.weights - a.weights));

    return condensed;
  };

  createData = (ticker, assetClass, name, weights, changes) => {
    return {
      ticker, assetClass, name, weights, changes,
    };
  };

  handleClickOpen = () => {
    this.setState({open: true});
  }

  handleClose = () => {
    this.setState({open: false});
  }

  handleCloseGetData = () => {
    this.getPortfolioList(this.endDate);
    this.getAssetAllocation(this.startDate, this.endDate);
    this.setState({open: false});
  }

  handleTextFieldChange = (key, e) => {
    // this[key] = e.target.value;
    this.endDate = e.target.value;
    this.startDate = moment(this.endDate).subtract(2, 'month').format("YYYY-MM-DD");
  }

  handleCsvDownload = (key, e) => {
    const clonedData = JSON.parse(JSON.stringify( this.forCsv ));

    const csv = parseJson(clonedData.sort(
      (a, b) => (a.ticker.localeCompare(b.ticker))
    ));

    const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8' });
    FileSaver.saveAs(csvData, `target_portfolio(${this.getFormattedRebalanceDate('YYYY-MM-DD')}).csv`);
  }

  getFormattedRebalanceDate = (format) => {
    if (this.rebalanceMomentDate == null) {
      return '';
    } else {
      return this.rebalanceMomentDate.format(format);
    }
  }

  renderTitleWithDate = (): Object => {
    const { data } = this.props;

    if (typeof data !== 'undefined' && 
      data != null) {
      this.rebalanceMomentDate = moment(this.getLastPortDt(data));
    }

    const title = 'Target Portfolio';

    return (
      <TitleWithDate
        text={title}
        date={`(rebalanced on ${this.getFormattedRebalanceDate('MMM. Do, YYYY')})`}
      />
    );
  };

  prepareData = () => {
    const { data } = this.props;

    /******************************************************************
     * 서버에서 내려온 데이터를 화면에 맞게 바꾼다.
     ******************************************************************/
    // const portfolio = [
    //   this.createData('SHY', 'Equity', 8.5, 0.1),
    //   this.createData('TLT', 'Fixed Income', 5.0, -0.15),
    //   this.createData('BOTZ', 'Equity', 5.0, 0.02),
    //   this.createData('QQQ', 'Equity', 4.5, 0.03),
    //   this.createData('IMW', 'Equity', 4.35, 0.01),
    // ];

    if (data != null && typeof data !== 'undefined') {
      this.keys.clear();
      this.types = {};
      this.prevPortfolio = null;
      this.lastPortfolio = null;
  
      this.initialize(data);
      this.condensed = this.getPrecision(2);
      this.forCsv = this.getPrecision(3);
    }
  };

  renderTable = (authenticated, portfolio): Object => {
    if (!authenticated) {
      return this.renderBasicTable(portfolio);
    } else {
      return this.renderDetailTable(portfolio);
    }
  };

  renderBasicTable = (portfolio): Object => {

    return (
      <div>
        <Table aria-label="target portfolio">
          <TableHead>
            <TableRow>
              <StyledHeaderCell align="left">
                Ticker
              </StyledHeaderCell>
              <Hidden mdDown>
                <StyledHeaderCell align="left">
                  Name
                </StyledHeaderCell>
              </Hidden>
              <StyledHeaderCell align="left">
                Class
              </StyledHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              portfolio.map((row) => (
                <TableRow key={row.ticker}>
                  <StyledTableCell component="th" scope="row">
                    {row.ticker}
                  </StyledTableCell>
                  <Hidden mdDown>
                    <StyledTableCell>
                      {row.name}
                    </StyledTableCell>
                  </Hidden>
                  <StyledTableCell>
                    {row.assetClass}
                  </StyledTableCell>
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
      </div>
    );
  };

  renderDetailTable = (portfolio): Object => {

    return (
      <div>
        <Table aria-label="target portfolio">
          <TableHead>
            <TableRow>
              <StyledHeaderCell align="left">
                Ticker
              </StyledHeaderCell>
              <Hidden mdDown>
                <StyledHeaderCell align="left">
                  Asset Class
                </StyledHeaderCell>
              </Hidden>
              <StyledHeaderCell align="right">
                Weights(%)
              </StyledHeaderCell>
              <StyledHeaderCell align="right">
                Changes(%)
              </StyledHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              portfolio.map((row) => (
                <TableRow key={row.ticker}>
                  <StyledTableCell component="th" scope="row">
                    {row.ticker}
                  </StyledTableCell>
                  <Hidden mdDown>
                    <StyledTableCell>
                      {row.assetClass}
                    </StyledTableCell>
                  </Hidden>
                  <StyledTableCell align="right">
                    {row.weights}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row.changes}
                  </StyledTableCell>
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
      </div>
    );
  };

  renderButtons = (authenticated): Object => {

    if (authenticated) {
      return (
        <ButtonDiv>
          <ButtonWrapper variant="contained" size="small" color="primary" onClick={this.handleClickOpen}>
            Portfolio Date
          </ButtonWrapper>
          {this.renderDatesDialog()}
          <ButtonWrapper variant="contained" size="small" color="primary" onClick={this.handleCsvDownload}>
            CSV Download
          </ButtonWrapper>
        </ButtonDiv>
      );
    } else {
      return (
        <div>
          <br />
          <br />
        </div>
      )
    }
  };

  renderDatesDialog = () => {
    const { open } = this.state;

    return (
      <Dialog 
        open={open} 
        onClose={this.handleClose} 
        aria-labelledby="from-dialog-title"
        PaperProps={{
          style: {
            backgroundColor: '#27293d',
          }
        }}>
        <DialogTitle id="form-dialog-title">Portfolio Date</DialogTitle>
        <DialogContent>
          <StyledTextField
            id="endDate"
            label="To"
            type="date"
            defaultValue={this.endDate}
            onChange={(e) => this.handleTextFieldChange('endDate', e)}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={this.handleCloseGetData} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    )
  };

  renderChart = (asset): Object => {

    return (
      <ChartWrapper>
        <AssetAllocationChart
          data={asset}
        />
      </ChartWrapper>
    );
  };

  renderLine = (authenticated): Object => {
    const { list } = this.props;
    const asset = typeof list !== 'undefined' && list.length !== 0 ? list[list.length - 1] : null;

    this.prepareData();
    const port = this.condensed.slice(0, 5);

    if (typeof port !== 'undefined' &&
      port !== null &&
      port.length !== 0 &&
      (
        this.savedPort.length === 0 || this.savedPort !== port
      )) {
      this.savedPort = port;
    }

    if (typeof asset !== 'undefined' &&
      asset !== null &&
      (
        this.savedAsset.length === 0 || this.savedAsset !== asset
      )) {
      this.savedAsset = asset;
    }

    return (
      <LineWrapper>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <LeftSideContainer>
              {this.renderTable(authenticated, this.savedPort)}
            </LeftSideContainer>
          </Grid>
          <Grid item xs={12} sm={6}>
            <RightSideContainer>
              {this.renderChart(this.savedAsset)}
            </RightSideContainer>
          </Grid>
        </Grid>
      </LineWrapper>
    );
  };
  
  render() {
    const { authLevel, fundsInfo, mpCode } = this.props;
    let authenticated = authLevel === "1";
    if (fundsInfo) {
      const dataStatus = fundsInfo.filter(item => item.MP_CODE == mpCode)[0];
      this.rebalanceMomentDate = moment(dataStatus.REBAL_DT);
    }


    return (
      <Container>
        <HeaderWrapper>
          {this.renderTitleWithDate()}
        </HeaderWrapper>
        {this.renderLine(authenticated)}
        <Hidden mdDown>
          {this.renderButtons(authenticated)}
        </Hidden>
      </Container>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators(ProductDetailCreators, dispatch);

const mapStateToProps = state => ({
  fundsInfo: state.productList.fundsInfo,
  data: state.productDetail.portfolioSnapshot,
  list: state.productDetail.assetAllocationList,
  auth: state.auth,
});

export default connect(mapStateToProps, mapDispatchToProps)(TargetPortfolio);
