// @flow

import React, { Component, Fragment } from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Creators as UserCreators } from '../../store/ducks/user';
import { Creators as AuthCreators } from '../../store/ducks/auth';

import ChangePassword from './components/ChangePassword';
import Snackbar from '../common/Snackbar';


type Props = {
  editPassword: Function,
  users: Array<Object>,
};

type State = {
  isPasswordDialogOpen: boolean,
  isSnackbarOpen: boolean,
  snackbarMessage: string,
  snackbarError: string,
}

class Passwords extends Component<Props, State> {
  state = {
    isPasswordDialogOpen: true,
    isSnackbarOpen: false,
    snackbarMessage: '',
    snackbarError: '',
  };

  componentWillReceiveProps(nextProps) {
    const { message, error } = nextProps.users;

    if (message || error) {
      this.setState({
        isSnackbarOpen: true,
      });
    }
  }

  onTogglePassowordDialog = (): void => {
    const { isPasswordDialogOpen } = this.state;

    this.setState({
      isPasswordDialogOpen: !isPasswordDialogOpen,
    });
  };

  onChangePassword = (newPassword: string): void => {
    const { editPassword } = this.props;
    const { user } = this.props.auth;

    this.setState({
      isPasswordDialogOpen: false,
    }, () => editPassword({
        user_id: user.username,
        password: newPassword
    }));
  };

  renderSnackbar = (stock: Object): Object => {
    const { isSnackbarOpen } = this.state;
    const { message, error } = stock;

    return (
      <Snackbar
        onCloseSnackbar={() => this.setState({ isSnackbarOpen: false })}
        isOpen={isSnackbarOpen}
        message={message}
        error={error}
      />
    );
  };

  render() {
    const { isPasswordDialogOpen } = this.state;
    const { users } = this.props;
    
    return (
      <Fragment>
        <ChangePassword
            onChangePassword={this.onChangePassword}
            onClose={this.onTogglePassowordDialog}
            isOpen={isPasswordDialogOpen}
          />
        {this.renderSnackbar(users)}
      </Fragment>
    );
  }
}
const Creators = Object.assign({}, UserCreators, AuthCreators);

const mapDispatchToProps = dispatch => bindActionCreators(Creators, dispatch);

const mapStateToProps = state => ({
  users: state.user,
  auth: state.auth,
});

export default connect(mapStateToProps, mapDispatchToProps)(Passwords);
