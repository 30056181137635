import { FILTER_TYPES } from '../../../utils/filter';

const filterConfig = [{
  placeholder: 'Enter the Username of the User you are looking for',
  type: FILTER_TYPES.TEXT,
  filterTitle: 'Username',
  dataField: 'USER_ID',
},{
  placeholder: 'Enter the Engine Key of the Role you are looking for',
  type: FILTER_TYPES.TEXT,
  filterTitle: 'Engine Key',
  dataField: 'MP_CODE',
}];

export default filterConfig;
