// @flow

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Toolbar from '@material-ui/core/Toolbar';

import styled from 'styled-components';

import UserInfo from './components/UserInfo';
import Logo from '../../../../images/logo.png';


const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const IconWrapper = styled.div`
  max-width: 100%;
  cursor: pointer !important;
`;

const LogoIcon = styled.img.attrs({
  src: Logo,
  alt: 'Logo',
})`
  margin-top: 5px;
  width: 150px;
  height: 100%;
`;

const LeftSideContainer = styled.div`
  display: flex;
  align-items: center;
`;

const RightSideContainer = styled.div`
  display: flex;
  align-items: center;
`;

type Props = {
  history: Object,
};

class HeaderBar extends Component<Props, State> {

  handleLogoClick = () => {
    const { history } = this.props;
    history.push('/product-list');
  };

  render() {
    return (
      <Toolbar>
        <Container>
          <LeftSideContainer>
            <IconWrapper onClick={this.handleLogoClick}>
              <LogoIcon />
            </IconWrapper>
          </LeftSideContainer>
          <RightSideContainer>
            <UserInfo />
          </RightSideContainer>
        </Container>
      </Toolbar>
    );
  }
}

export default withRouter(HeaderBar);
