import Immutable from 'seamless-immutable';

export const Types = {
  CREATE_REQUEST: 'role/CREATE_REQUEST',
  CREATE_SUCCESS: 'role/CREATE_SUCCESS',
  CREATE_FAILURE: 'role/CREATE_FAILURE',

  GET_ALL_REQUEST: 'role/GET_ALL_REQUEST',
  GET_ALL_SUCCESS: 'role/GET_ALL_SUCCESS',
  GET_ALL_FAILURE: 'role/GET_ALL_FAILURE',

  EDIT_REQUEST: 'role/EDIT_REQUEST',
  EDIT_REQUEST_SUCCESS: 'role/EDIT_REQUEST_SUCCESS',
  EDIT_REQUEST_FAILURE: 'role/EDIT_REQUEST_FAILURE',

  REMOVE_REQUEST: 'role/REMOVE_REQUEST',
  REMOVE_REQUEST_SUCCESS: 'role/REMOVE_REQUEST_SUCCESS',
  REMOVE_REQUEST_FAILURE: 'role/REMOVE_REQUEST_FAILURE',
};

const INITIAL_STATE = Immutable({
  message: null,
  error: null,
  data: [],
});

export const Creators = {
  createRole: args => ({
    type: Types.CREATE_REQUEST,
    args,
  }),

  createRoleSuccess: role => ({
    type: Types.CREATE_SUCCESS,
    payload: { message: 'Role Created Successfully', role },
  }),

  createRoleFailure: () => ({
    type: Types.CREATE_FAILURE,
    payload: { error: 'There was a problem when trying to Create Role' },
  }),

  getAllRoles: () => ({
    type: Types.GET_ALL_REQUEST,
  }),

  getAllRolesSuccess: roles => ({
    type: Types.GET_ALL_SUCCESS,
    payload: { roles },
  }),

  getAllRolesFailure: () => ({
    type: Types.GET_ALL_FAILURE,
    payload: { error: 'There was a problem when trying to get Roles from Database' },
  }),

  editRole: role => ({
    type: Types.EDIT_REQUEST,
    payload: { role },
  }),

  editRoleSuccess: roleEdited => ({
    type: Types.EDIT_REQUEST_SUCCESS,
    payload: { message: 'Role Edited Successfully', roleEdited },
  }),

  editRoleFailure: () => ({
    type: Types.EDIT_REQUEST_FAILURE,
    payload: { error: 'There was a problem when trying to Edit Role' },
  }),

  removeRole: id => ({
    type: Types.REMOVE_REQUEST,
    payload: { id },
  }),

  removeRoleSuccess: id => ({
    type: Types.REMOVE_REQUEST_SUCCESS,
    payload: { message: 'Role Removed Successfully', id },
  }),

  removeRoleFailure: () => ({
    type: Types.REMOVE_REQUEST_FAILURE,
    payload: { error: 'There was a problem when trying to Remove Role' },
  }),
};

const role = (state = INITIAL_STATE, { payload, type }) => {
  switch (type) {
    case Types.CREATE_REQUEST:
      return {
        ...state,
        message: null,
        error: null,
      };

    case Types.CREATE_SUCCESS:
      return {
        ...state,
        message: payload.message,
        data: [payload.role, ...state.data],
      };

    case Types.CREATE_FAILURE:
      return {
        ...state,
        error: payload.error,
      };

    case Types.GET_ALL_REQUEST:
      return {
        ...state,
        message: null,
        error: null,
      };

    case Types.GET_ALL_SUCCESS:
      return {
        data: [...payload.roles.data],
        error: null,
      };

    case Types.GET_ALL_FAILURE:
      return {
        ...state,
        error: payload.error,
      };

    case Types.EDIT_REQUEST:
      return {
        ...state,
        message: null,
        error: null,
      };

    case Types.EDIT_REQUEST_SUCCESS:
      return {
        ...state,
        message: payload.message,
        data: state.data.map(item => (item.USER_ID === payload.roleEdited.USER_ID && 
          item.MP_CODE === payload.roleEdited.MP_CODE ? payload.roleEdited : item)),
      };

    case Types.EDIT_REQUEST_FAILURE:
      return {
        ...state,
        error: payload.error,
      };

    case Types.REMOVE_REQUEST:
      return {
        ...state,
        message: null,
        error: null,
      };

    case Types.REMOVE_REQUEST_SUCCESS:
      return {
        ...state,
        message: payload.message,
        data: state.data.filter(item => item.id !== payload.id),
      };

    case Types.REMOVE_REQUEST_FAILURE:
      return {
        ...state,
        error: payload.error,
      };
  
    default:
      return state;
  }
};

export default role;
