/* eslint-disable react/no-string-refs */
// @flow

import React, { Component } from 'react';

import styled from 'styled-components';

import { connect } from 'react-redux';

import LineChart from "../../../../../components/chart/LineChart";


const Wrapper = styled.div`
  margin-bottom: 0px;
`;

type Props = {
  data: Array<Object>,
}

type State = {
  chartWidth: Number,
};

class PortfolioReturnChart extends Component<Props, State> {

  constructor(...args) {
    super(...args);
    this.childKey = 0;
    this.state = {chartWidth: 1000};
  }

  componentDidMount() {
    this.updateChartWidth();    
    window.addEventListener('resize', this.updateChartWidth);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateChartWidth);
  }

  componentDidUpdate(prevProps, prevState) {

    const { data } = this.props;

    /******************************************************************
     * 서버에서 내려온 STK_DT, VALUE의 이름을 바꾼다.
     ******************************************************************/
    if (data != null && typeof data !== 'undefined' && data.length !== 0) {
      const converted = [];

      let init_pr = 1
      let init_tr = 1

      if (data.length > 0) {
        init_pr = data[0].PR_INDEX
        init_tr = data[0].TR_INDEX
      }

      data.forEach((item, index) => {
        converted.push(this.createData(item.DT, (item.PR_INDEX / init_pr - 1) * 100, (item.TR_INDEX / init_tr - 1) * 100));
      });
  
      this.drawChart(converted);
    }
  }

  updateChartWidth = () => {

    const windowWidth = window.innerWidth;
    let preferredWidth = 1000;
    if (windowWidth < 1000) {
      preferredWidth = windowWidth - 100;
    }

    this.setState( {
      chartWidth: preferredWidth
    })
  }

  createData = (date, val, total) => {
    return {
      date: new Date(date), "Price Return": val, "Total Return": total,
    };
  };

  drawChart = (data) => {
    this.chart = LineChart()
      .addSerie(data, {x: 'date', y: 'Total Return'}, {})
      .addSerie(data, {x: 'date', y: 'Price Return'}, {dashed: 'dashed'})
      .width(this.state.chartWidth)
      .height(200)
      .margin.top(10)
      .margin.right(10)
      .margin.bottom(40)
      .margin.left(45)
      .legend(true)
      .yAxisLabel(true)
    ;

    this.chart(this.refs.chartdivPortfolioReturn);
  }

  render() {
    // Props 갱신 될 때 마다 새로 그리기 위해서 key 속성 추가
    this.childKey++;
    return (
      <Wrapper key={this.childKey}>
        <div ref="chartdivPortfolioReturn" />
      </Wrapper>
    );
  }
}

export default connect()(PortfolioReturnChart);
