// @flow

import React, { Component } from 'react';

import styled from 'styled-components';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Creators as ProductDetailCreators } from '../../../../store/ducks/productDetail';

import moment from 'moment'

import { Grid } from '@material-ui/core';
import { Typography } from '@material-ui/core';

import Title from './components/Title';
import Summary from './components/Summary';
import SummaryChart from './components/SummaryChart';


const Container = styled.div`
  margin: 0 4px;
  min-height: 115px !important;
`;

const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 16px;
`;

const NotReadyWrapper = styled.div`
  min-height: 50px;
`;

type Props = {
  getPortfolioReturn: Function,
  getProductIndicatorList: Function,
  mpCode: String,
  engineData: Object,
  rtnData: Array<Object>,
  indData: Array<Object>,
  args: Object,
  forwardToDetail: Function,
}

class ProductSummary extends Component<Props, State> {

  componentDidMount() {
    this.getData();
  }
  
  shouldComponentUpdate(nextProps, nextStats) {
    const vitalPropsChange = 
      nextProps.args != null &&
      typeof nextProps.args !== 'undefined' &&
      nextProps.args.mp_code != null &&
      typeof nextProps.args.mp_code !== 'undefined' &&
      ((nextProps.rtnData != null && typeof nextProps.rtnData !== 'undefined') || (nextProps.indData != null && typeof nextProps.indData !== 'undefined')) &&
      nextProps.args.mp_code === this.props.mpCode;
    
    return vitalPropsChange;
  }  

  getData= () => {
    const { getPortfolioReturn, getProductIndicatorList, mpCode } = this.props;

    const today = moment();
	  let endDate = today.format("YYYY-MM-DD");
	  // let startDate = today.add(-30, 'Y').format("YYYY-MM-DD");
	  let startDate = '2005-01-04';

    const params = {
      mp_code: mpCode,
      start_dt: startDate,
      end_dt: endDate,
    }

    getPortfolioReturn(params);
    getProductIndicatorList(params);
  }

  renderTitle = (): Object => {
    const { engineData } = this.props;

    let mpCode = engineData['MP_CODE'];
    if (mpCode) {
      mpCode = mpCode.toUpperCase();
    }
    
    const fundName = engineData['NAME'];
    const title = mpCode + ", " + fundName;

    return (
      <Title>
        {title}
      </Title>
    );
  };

  getSummary = (data) => {

    let index = '-';
    let indexChange = '-';
    let indexChangePct = '-';

    if (typeof data !== 'undefined' && data.length > 2) {
      index = data[data.length - 1].TR_INDEX.toFixed(2);

      indexChange = data[data.length - 1].TR_INDEX - data[data.length - 2].TR_INDEX;
      indexChange = indexChange.toFixed(2);

      indexChangePct = indexChange / index * 100;
      indexChangePct = indexChangePct.toFixed(2);
    }

    return {
      index,
      indexChange,
      indexChangePct,
    };
  };

  getReturnByTerm = (data, term) => {
    if (typeof data === 'undefined') {
      return '-';
    }

    const selected = data[term];
    // return selected["Total Return"] !== 'NaN'
    //   ? `${selected["Total Return"].toFixed(2)}%` 
    //   : '-';
    if (selected["Total Log Return"] !== 'NaN') {
      const totRet = selected["Total Log Return"] * 100;
      return `${totRet.toFixed(2)}%`
    } else {
      return '-';
    }
  };

  renderSummary = (): Object => {
    const { indData, rtnData, engineData } = this.props;
    const { index, indexChange, indexChangePct } = this.getSummary(rtnData);

    const summary = {}
    summary.type = engineData['TYPE_NM'];
    summary.index = index;
    summary.indexChange = indexChange;
    summary.indexChangePct = indexChangePct;
    summary.oneMonth = this.getReturnByTerm(indData, '1M');
    summary.threeMonth = this.getReturnByTerm(indData, '3M');
    summary.sixMonth = this.getReturnByTerm(indData, '6M');
    summary.oneYear = this.getReturnByTerm(indData, '1Y');
    summary.threeYear = this.getReturnByTerm(indData, '3Y');
    summary.fiveYear = this.getReturnByTerm(indData, '5Y');
    summary.ytd = this.getReturnByTerm(indData, 'YTD');
    summary.itd = this.getReturnByTerm(indData, 'ITD');
    summary.rebalDate = engineData['REBAL_DT'];
    summary.indexDate = engineData['INDEX_DT'];
    summary.initDate = engineData['INIT_DT'];
  
    return (
      <Summary
        summary={summary}
      />
    );
  };

  renderChart = (): Object => {
    const { rtnData, mpCode } = this.props;

    return (
      <SummaryChart
        mpCode={mpCode}
        data={rtnData}
      />
    );
  };

  render() {
    const { rtnData, indData, forwardToDetail, mpCode, engineData } = this.props;
    if ((typeof rtnData !== 'undefined' && rtnData.length === 0) ||
        (typeof indData !== 'undefined' && indData.length === 0)) {
      return (
        // 데이터 없는 Summary에서는 Detail 들어가지 않음
        <Container>
          <Grid container spacing={3}>
            <Grid item md={12} sm={12}>
              <HeaderWrapper>
                {this.renderTitle()}
              </HeaderWrapper>
              <NotReadyWrapper>
                <center>
                  <Typography variant="h7">
                    This product is not ready yet.
                  </Typography>
                </center>
              </NotReadyWrapper>
            </Grid>
          </Grid>
        </Container>
      );
    } else {
      return (
        // 데이터 있을 경우에만 Detail 들어감
        <Container
          onClick={(e) => forwardToDetail(mpCode, engineData, e)}
        >
          <Grid container spacing={3}>
            <Grid item md={7} sm={12}>
              <HeaderWrapper>
                {this.renderTitle()}
              </HeaderWrapper>
              {this.renderSummary({})}
            </Grid>
            <Grid item md={0.5} sm={0}/>
            <Grid item md={4} sm={12}>
            {this.renderChart()}
            </Grid>
          </Grid>
        </Container>
      );
      }
  }
}

const mapDispatchToProps = dispatch => bindActionCreators(ProductDetailCreators, dispatch);

const mapStateToProps = state => ({
  rtnData: state.productDetail.portfolioReturn,
  indData: state.productDetail.productIndicatorList,
  args: state.productDetail.args,
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductSummary);
