// @flow

import React, { Component } from 'react';

import FormLabel from '@material-ui/core/FormLabel';

import styled from 'styled-components';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Creators as ProductListCreators } from '../../../../store/ducks/productList';
import { getLoggedInUserName } from '../../../../store/sagas/auth';

import Title from './components/Title';


const Container = styled.div`
  margin: 0 4px;
  min-height: 120px !important;
`;

const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 16px;
`;

const FormLabelWrapper = styled(({ ...props }) => (
  <FormLabel
    {...props}
  />
))`
  width: 100%;
  line-height: 1.35em !important;
`;

type Props = {
  getFundsInfo: Function,
  getLoggedInUserName: Function,
  mpCode: String,
  fundsInfo: Object,
}

class StrategyDescription extends Component<Props, State> {

  componentDidMount() {
    
    const { fundsInfo, getFundsInfo } = this.props;

    if (typeof fundsInfo == 'undefined' || fundsInfo == null) {
      const { user } = this.props.auth;

      let userName = 'anonymous';
      if (typeof user !== 'undefined' && user !== null) {
        userName = user.username;
      } else if (!user) {
        userName = getLoggedInUserName();
      }
  
      if (!userName) {
        userName = 'anonymous';
      }
  
      getFundsInfo({"user_id": userName});
    }
  }

  getFund = () => {
    const { fundsInfo, mpCode } = this.props;
    if (typeof fundsInfo === 'undefined') {
      return {};
    }

    const targetFund = fundsInfo.filter((item) => item['MP_CODE'] === mpCode);
    return targetFund[0];
  };

  renderTitle = (): Object => {
    const fundInfo = this.getFund();
    let mpCode = fundInfo['MP_CODE'];
    if (mpCode) {
      mpCode = mpCode.toUpperCase();
    }
    
    const fundName = fundInfo['NAME'];
    const title = mpCode + ", " + fundName;

    return (
      <Title>
        {title}
      </Title>
    );
  };

  renderDescription = (): Object => {

    const fundInfo = this.getFund();
    const desc = fundInfo['FUND_DESC'];

    return (
      <FormLabelWrapper component="legend">
        {desc}
      </FormLabelWrapper>
    );
  };

  render() {
    return (
      <Container>
        <HeaderWrapper>
          {this.renderTitle()}
        </HeaderWrapper>
        {this.renderDescription()}
        <br />
      </Container>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators(ProductListCreators, dispatch);

const mapStateToProps = state => ({
  fundsInfo: state.productList.fundsInfo,
  auth: state.auth,
});

export default connect(mapStateToProps, mapDispatchToProps)(StrategyDescription);