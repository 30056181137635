import * as d3 from 'd3';
import moment from 'moment';

// From below URL
// https://github.com/mcaule/d3-timeseries/blob/master/src/d3_timeseries.js

const defaultColors = ["#a6cee3", "#ff7f00", "#b2df8a", "#1f78b4", "#fdbf6f", "#33a02c", "#cab2d6", "#6a3d9a", "#fb9a99", "#e31a1c", "#ffff99", "#b15928"];

// chart utility functions
const functorkey = (v) => {
  return typeof v === "function" ? v : function (d) {
    return d[v];
  };
}

const functorkeyscale = (v, scale) => {
  var f = typeof v === "function" ? v : function (d) {
    return d[v];
  };
  return (d) => {
    return scale(f(d));
  };
}

const keyNotNull = (k) => {
  return function (d)  {
    return d.hasOwnProperty(k) && d[k] !== null && !isNaN(d[k]);
  };
}

const fk = (v) => {
  return function (d) {
    return d[v];
  };
}

export default function LineChart() {
  // set the dimensions and margins of the graph
  let width = 1000;
  let height = 200;

  let margin = {
    top: 10, right: 10, bottom: 40, left: 50,
  };

  let series = [];

  const yscale = d3.scaleLinear();
  const xscale = d3.scaleTime();

  yscale.label = "";
  xscale.label = "";

  const brush = d3.brushX();

  let svg, container, serieContainer, annotationsContainer, mousevline;
  let fullxscale, tooltipDiv;

  let hasLegend = false;
  let hasYAxisLabel = false;

  yscale.setformat = (n) => {
    return n.toLocaleString();
  };
  // xscale.setformat = xscale.tickFormat();
  // xscale.setformat = d3.timeYears();

  // default tool tip function
  const _tipFunction = (date, series) => {
    const spans = '<table style="border:none">' + 
        series.filter((d) => d.item !== undefined && d.item !== null)
              .map((d) => '<tr><td style="color:' + d.options.color + '">' + d.options.label + ' </td>' +
                          '<td style="color:#333333;text-align:right">' + yscale.setformat(d.item[d.aes.y]) + '</td></tr>')
              .join('') + 
        '<table>';

    return '<h4 style="color:#000000;">' + moment(date).format("YYYY-MM-DD") + '</h4>' + spans;
  };

  const createLines = (serie) => {
    const aes = serie.aes;
    if (! serie.options.interpolate) {
      serie.options.interpolate = "linear";
    } else {
      // translate curvenames
      serie.options.interpolate = (
        serie.options.interpolate === 'monotone' ? 'monotoneX'
          : serie.options.interpolate === 'step-after' ? 'stepAfter'
            : serie.options.interpolate === 'step-before' ? 'stepBefore'
              : serie.options.interpolate
      );
    }

    // to uppercase for d3 curve name
    const curveName = 'curve' + serie.options.interpolate[0].toUpperCase() + serie.options.interpolate.slice(1);
    serie.interpolationFunction = d3[curveName] || d3.curveLinear;

    const line = d3.line()
      .x(functorkeyscale(aes.x, xscale))
      .y(functorkeyscale(aes.y, yscale))
      .curve(serie.interpolationFunction)
      .defined(keyNotNull(aes.y));

    serie.line = line;

    serie.options.label = serie.options.label ||
                            serie.options.name ||
                            serie.aes.label ||
                            serie.aes.y;
    
    if (aes.ci_up && aes.ci_down) {
      const ciArea = d3.area()
        .x(functorkeyscale(aes.x, xscale))
        .y0(functorkeyscale(aes.ci_down, yscale))
        .y1(functorkeyscale(aes.ci_up, yscale))
        .curve(serie.interpolationFunction);
      serie.ciArea = ciArea;
    }

    if (aes.diff) {
      serie.diffAreas = [
        d3.area()
          .x(functorkeyscale(aes.x, xscale))
          .y0(functorkeyscale(aes.y, yscale))
          .y1((d) => {
            if (d[aes.y] > d[aes.diff])
              return yscale(d[aes.diff]);
            return yscale(d[aes.y]);
          })
          .curve(serie.interpolationFunction)
        ,
        d3.area()
          .x(functorkeyscale(aes.x, xscale))
          .y1(functorkeyscale(aes.y, yscale))
          .y0((d) => {
            if (d[aes.y] < d[aes.diff])
              return yscale(d[aes.diff]);
            return yscale(d[aes.y]);
          })
          .curve(serie.interpolationFunction)
      ];
    }

    serie.find = (date) => {
      const bisect = d3.bisector(fk(aes.x)).left;
      const i = bisect(serie.data, date) - 1;
      if (i === -1) {
        return null;
      }
      
      // look to far after serie is defined
      if (i === serie.data.length - 1 && serie.data.length > 1 &&
        Number(date) - Number(serie.data[i][aes.x]) > Number(serie.data[i][aes.x]) - Number(serie.data[i - 1][aes.x])) {
        return null;
      }
      return serie.data[i];
    };
  }

  const drawSerie = (serie) => {
    if (! serie.linepath) {
      const linepath = serieContainer.append("path")
        .datum(serie.data)
        .attr('class', 'd3_timeseries line')
        .attr('d', serie.line)
        .attr('stroke', serie.options.color)
        .attr('stroke-linecap', 'round')
        .attr('stroke-width', serie.options.width || 1.5)
        .attr('fill', 'none');
      
      if (serie.options.dashed) {
        if (serie.options.dashed === true || serie.options.dashed === 'dashed') {
          serie['stroke-dasharray'] = '5,5';
        } else if (serie.options.dashed === 'long') {
          serie['stroke-dasharray'] = '10,10';
        } else if (serie.options.dashed === 'dot') {
          serie['stroke-dasharray'] = '2,4';
        } else {
          serie['stroke-dasharray'] = serie.options.dashed;
        }
        linepath.attr('stroke-dasharray', serie['stroke-dasharray']);
      }
      serie.linepath = linepath;

      if (serie.ciArea) {
        serie.cipath = serieContainer.insert("path", ":first-child")
          .datum(serie.data)
          .attr('class', 'd3_timeseries ci-area')
          .attr('d', serie.ciArea)
          .attr('stroke', 'none')
          .attr('fill', serie.options.color)
          .attr('opacity', serie.options.ci_opacity || 0.3);
      }
      if (serie.diffAreas) {
        serie.diffpaths = serie.diffAreas.map(
          (area, i) => {
            const c = (serie.options.diff_colors ? serie.options.diff_colors : ['green', 'red'])[i];
            return serieContainer.insert("path",
              () => linepath.node()
            )
            .datum(serie.data)
            .attr('class', 'd3_timeseries diff-area')
            .attr('d', area)
            .attr('stroke', 'none')
            .attr('fill', c)
            .attr('opacity', serie.options.diff_opacity || 0.5);
          }
        );
      }
    } else {
      serie.linepath.attr('d', serie.line);
      if (serie.ciArea) {
        serie.cipath.attr('d', serie.ciArea);
      }
      if (serie.diffAreas) {
        serie.diffpaths[0].attr('d', serie.diffAreas[0]);
        serie.diffpaths[1].attr('d', serie.diffAreas[1]);
      }
    }

    if (hasLegend) {
      drawLegend();
    }
  }

  const updatefocusRing = (xdate) => {
    let s = annotationsContainer.selectAll("circle.d3_timeseries.focusring");

    if (xdate == null) {
      s = s.data([]);
    } else {
      s = s.data(series.map(
        (s) => ({ 
            x: xdate, item: s.find(xdate),
            aes: s.aes,
            color: s.options.color
          })
        ).filter(
          (d) => (d.item !== undefined && d.item !== null &&
              d.item[d.aes.y] !== null && !isNaN(d.item[d.aes.y]))
        )
      )
    }

    s.transition().duration(50)
      .attr('cx', (d) => (xscale(d.item[d.aes.x])))
      .attr('cy', (d) => (yscale(d.item[d.aes.y])))
    ;

    s.enter().append("circle")
      .attr('class', 'd3_timeseries focusring')
      .attr('fill', 'none')
      .attr('stroke-width', 2)
      .attr('r', 5)
      .attr('stroke', fk('color'));
    
    s.exit().remove();
  }

  const updateTip = (xdate) => {
    if (xdate == null) {
      tooltipDiv.style('opacity', 0);
    } else {
      const s = series.map((s) => (
        {item: s.find(xdate), aes: s.aes, options: s.options}
      ))

      tooltipDiv.style('opacity', 0.9)
        .style('left', `${margin.left + 5 + xscale(xdate)}px`)
        .style('top', "0px")
        .html(_tipFunction(xdate, s));
    }
  }

  const mouseMove = () => {
    let x = d3.mouse(container.node())[0];
    x = xscale.invert(x);
    mousevline.datum({x: x, visible: true});
    mousevline.update();
    updatefocusRing(x)
    updateTip(x);
  }

  const mouseOut = () => {
    mousevline.datum({x: null, visible: false});
    mousevline.update();
    updatefocusRing(null);
    updateTip(null);
  }

  const drawLegend = () => {
    //++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    // LEGEND
    //++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    const size = 15;
    const legendCnt = series.length;
    const itemWidth = 120;
    const yLocation = 200;

    svg.attr('height', height + 30);

    svg.append('g')
      .selectAll('rect')
      .data(series)
      .join('rect')
      .attr('x', (d, i) => {
        const level = i - legendCnt / 2;
        let xVal = (width / 2) + (level * itemWidth)
        return xVal;
      })
      .attr('y', yLocation)
      .attr('width', size)
      .attr('height', size)
      .style('fill', (serie) => serie.options.color)
    ;

    svg.append('g')
      .selectAll('text')
      .data(series)
      .join('text')
      .attr('x', (d, i) => {
        const level = i - legendCnt / 2;
        let xVal = (width / 2) + (level * itemWidth)
        return xVal + 20;
      })
      .attr('y', yLocation + 8)
      .style('fill', 'White')
      .text((d) => d.aes.y)
      .attr('text-anchor', 'left')
      .style('alignment-baseline', 'middle')
      .attr('font-size', 10)
      .attr('font-weight', 'bold')
    ;
  }

  const chart = (elem) => {
    // compute mins max of all series
    series = series.map((s) => {
      let extent = d3.extent(s.data.map(functorkey(s.aes.y)));
      s.min = extent[0];
      s.max = extent[1];
      extent = d3.extent(s.data.map(functorkey(s.aes.x)));
      s.dateMin = extent[0];
      s.dateMax = extent[1];
      return s;
    });

    // set scales
    yscale.range([height - margin.top - margin.bottom, 0])
      .domain([d3.min(series.map(fk('min'))),
               d3.max(series.map(fk('max')))])
      .nice();

    xscale.range([0, width - margin.left - margin.right])
      .domain([d3.min(series.map(fk('dateMin'))),
                d3.max(series.map(fk('dateMax')))])
      .nice();

    // if user specify domain
    if (yscale.fixedomain) {
      // for showing 0 :
      // chart.adSeries(...)
      //    .yscale.domain([0])
      if (yscale.fixedomain.length === 1) {
        yscale.fixedomain.push(yscale.domain()[1]);
      }
      yscale.domain(yscale.fixedomain);
    }

    if (xscale.fixedomain) {
      xscale.domain(yscale.fixedomain);
    }

    fullxscale = xscale.copy();

    // create svg
    svg = d3.select(elem).append('svg')
      .attr('width', width)
      .attr('height', height);

    // clipping for scrolling in focus area
    svg.append('defs').append('clipPath')
      .attr('id', 'clip')
      .append('rect')
      .attr('width', width - margin.left - margin.right)
      .attr('height', height - margin.bottom)
      .attr('y', - margin.top)
    
    // container for fucus area
    container = svg.insert('g', "rect.mouse-catch")
      .attr('transform', `translate(${margin.left}, ${margin.top})`)
      .attr('clip-path', 'url(#clip)');
    
    serieContainer = container.append('g');
    annotationsContainer = container.append('g');

    // vertical line moving with mouse tip
    mousevline = svg.append('g')
      .datum({
        x: new Date(),
        visible: false
      })
    mousevline.append('line')
      .attr('x1', 0)
      .attr('x2', 0)
      .attr('y1', yscale.range()[0])
      .attr('y2', yscale.range()[1])
      .attr('class', 'd3_timeseries mousevline');
    // update mouse vline
    mousevline.update = () => {
      // this.attr('transform', (d) => (`translate(${margin.left + xscale(d.x)}, ${margin.top})`))
      mousevline.attr('transform', (d) => (`translate(${margin.left + xscale(d.x)}, ${margin.top})`))
        .style('opacity', (d) => (d.visible ? 1 : 0))
    }
    mousevline.update();

    // const xAxis = d3.axisBottom().scale(xscale).tickFormat(xscale.setformat);
    const xAxis = d3.axisBottom().scale(xscale).ticks(width / 80).tickSizeOuter(0);
    const yAxis = d3.axisLeft().scale(yscale).tickFormat(yscale.setformat);

    if (hasYAxisLabel) {
      svg.append("text")
        .attr("class", "y label")
        .attr("y", 12)
        .attr("x",0 - (height / 2) + 15)
        .attr("dy", ".01em")
        .attr("transform", 'rotate(-90)')
        .attr("text-anchor", "middle")
        .style('fill', 'white')
        .text("Percent");
    }

    brush.extent([[fullxscale.range()[0], 0], [fullxscale.range()[1], 0]])
      .on('brush', () => {
        let selection = d3.event.selection;

        xscale.domain(selection.map(fullxscale.invert, fullxscale));

        series.forEach(drawSerie);
        svg.select(".focus.x.axis").call(xAxis);
        mousevline.update();
        updatefocusRing();
      })
      .on('end', () => {
        let selection = d3.event.selection;
        if (selection === null) {
          xscale.domain(fullxscale.domain());

          series.forEach(drawSerie);
          svg.select(".focus.x.axis").call(xAxis);
          mousevline.update();
          updatefocusRing();
        }
      });
    
    svg.append('g')
      .attr('class', 'd3_timeseries focus x axis')
      .attr("transform", `translate(${margin.left},${height - margin.bottom})`)
      .call(xAxis);
    
    svg.append('g')
      .attr('class', 'd3_timeseries y axis')
      .attr("transform", `translate(${margin.left},${margin.top})`)
      .call(yAxis)
      .append("text")
      .attr("transform", "rotate(-90)")
      .attr("x", -margin.top - d3.mean(yscale.range()))
      .attr("dy", ".71em")
      .attr('y', -margin.left + 5)
      .style("text-anchor", "middle")
      .text(yscale.label);
    
    // catch event for mouse tip
    svg.append('rect')
      .attr('width', width)
      .attr('class', 'd3_timeseries mouse-catch')
      .attr('height', height)
      .style('opacity', 0)
      .on('mousemove', mouseMove)
      .on('mouseout', mouseOut);
    
    tooltipDiv = d3.select(elem)
      .style('position', 'relative')
      .append('div')
      .attr('class', 'd3_timeseries tooltip')
      .style('opacity', 0);
    
    series.forEach(createLines);
    series.forEach(drawSerie);
  }

  chart.width = function (_) {
    if (!arguments.length) return width;
    width = _;
    return chart;
  };

  chart.height = function (_) {
    if (!arguments.length) return height;
    height = _;
    return chart;
  };

  chart.margin = function (_) {
    if (!arguments.length) return margin;
    margin = _;
    return chart;
  };
  // accessors for margin.left(), margin.right(), margin.top(), margin.bottom()
  d3.keys(margin).forEach(function (k) {
    chart.margin[k] = function (_) {
      if (!arguments.length) return margin[k];
      margin[k] = _;
      return chart;
    };
  });

  chart.addSerie = function (data, aes, options) {
    if (!data && series.length > 0)
      data = series[0].data;
    if (!options.color)
      options.color = defaultColors[series.length % defaultColors.length];
    series.push({data: data, aes: aes, options: options});
    return chart;
  };

  chart.legend = function (_) {
    if (!arguments.length) return hasLegend;
    hasLegend = _;
    return chart;
  };

  chart.yAxisLabel = function (_) {
    if (!arguments.length) return hasYAxisLabel;
    hasYAxisLabel = _;
    return chart;
  };

  return chart;  
}