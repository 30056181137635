// @flow

import React, { Component } from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import styled from 'styled-components';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Creators as ProductDetailCreators } from '../../../../store/ducks/productDetail';

import Title from './components/Title';
import { Hidden } from '@material-ui/core';

import moment from 'moment';


const Container = styled.div`
  margin: 0 4px;
`;

const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 16px;
`;

const StyledHeaderCell = styled(TableCell)`
  padding: 10px 24px 10px 16px !important;
  background-color: #A6A6A6 !important;
`;

const StyledBoldHeaderCell = styled(StyledHeaderCell)`
  font-weight: bold !important;
`;

const StyledTableCell = styled(TableCell)`
  padding: 10px 24px 10px 16px !important;
  background-color: #27293d !important;
`;

const StyledBoldTableCell = styled(StyledTableCell)`
  font-weight: bold !important;
`;

const StyledMdHeaderCell = styled(StyledHeaderCell)`
  padding: 5px 5px 5px 5px !important;
  font-size: 0.675rem !important;
`;

const StyledMdBoldHeaderCell = styled(StyledMdHeaderCell)`
  font-weight: bold !important;
`;

const StyledMdTableCell = styled(StyledTableCell)`
  padding: 5px 5px 5px 5px !important;
  font-size: 0.675rem !important;
`;

const StyledMdBoldTableCell = styled(StyledMdTableCell)`
  font-weight: bold !important;
`;

type Props = {
  getProductIndicatorList: Function,
  data: Array<Object>,
  mpCode: String,
}

class ProductIndicator extends Component<Props, State> {
  constructor(...args) {
    super(...args);
    this.selectedData = [];
    this.targetKeys = ['1M', '3M', '6M', '1Y', '3Y', '5Y', '10Y', 'YTD', 'ITD'];
  }

  componentDidMount() {
    this.getList();
  }

  getList = (startDt, endDt) => {
    const { getProductIndicatorList, mpCode } = this.props;

    const params = {
      mp_code: mpCode,
    }

    getProductIndicatorList(params);
  }

  componentDidUpdate(prevProps, prevState) {
    // const { data } = this.props;

    // /******************************************************************
    //  * 서버에서 내려온 데이터를 화면에 맞게 바꾼다.
    //  ******************************************************************/
    // // const portfolio = [
    // //   this.createData('Return', 0.23, 1.51, -2.34, -0.57, 6.15, 10.01, 21.04, 4.51, 31.00),
    // //   this.createData('Volatility', 5.00, 6.00, 7.50, 13.10, 8.74, 12.15, 17.10, 9.09, 11.05),
    // //   this.createData('Sharpe Ratio', 3.00, 3.00, 3.50, 3.50, 3.50, 3.50, 3.50, 3.00, 2.75),
    // //   this.createData('MDD', 1.20, 2.00, 2.00, 2.00, 2.70, 2.70, 2.70, 2.00, 2.70),
    // // ];

    // if (data != null && typeof data !== 'undefined') {
    //   this.selectedData = this.getSelected(data);
    // }
  }

  getSelected = (data) => {
    console.log(data);
    const selected = [];
    const dateFromArray = [];
    const dateToArray = [];
    const totalRtnArray = [];
    const priceRtnArray = [];
    const dividendRtnArray = [];
    const totalAnnRtnArray = [];
    const priceAnnRtnArray = [];
    const dividendAnnRtnArray = [];
    const totalVolArray = [];
    const priceVolArray = [];
    const totalSharpArray = [];
    const priceSharpArray = [];
    const totalMddArray = [];
    const priceMddArray = [];

    Array.from(this.targetKeys).forEach((key) => {
      const item = data[key]
      
      const dateFrom = this.validateDateArrayAndGetByKey(item, 'Date From');
      const dateTo = this.validateDateArrayAndGetByKey(item, 'Date To');
      const totalRtn = this.validateNumericArrayAndGetByKey(item, 'Total Log Return');
      const priceRtn = this.validateNumericArrayAndGetByKey(item, 'Price Log Return');
      const dividendRtn = this.validateNumericArrayAndGetByKey(item, 'Dividend Log Return');
      const annTotalRtn = this.validateNumericArrayAndGetByKey(item, 'Annualized Total Log Return');
      const annPriceRtn = this.validateNumericArrayAndGetByKey(item, 'Annualized Price Log Return');
      const annDividendRtn = this.validateNumericArrayAndGetByKey(item, 'Annualized Dividend Log Return');
      const totalVol = this.validateNumericArrayAndGetByKey(item, 'Annualized Total Volatility');
      const priceVol = this.validateNumericArrayAndGetByKey(item, 'Annualized Price Volatility');
      const totalSharp = this.validateNumericArrayAndGetByKey(item, 'Annualized Total Sharpe Ratio');
      const priceSharp = this.validateNumericArrayAndGetByKey(item, 'Annualized Price Sharpe Ratio');
      const totalMdd = this.validateNumericArrayAndGetByKey(item, 'Total Maximum Drawdown');
      const priceMdd = this.validateNumericArrayAndGetByKey(item, 'Price Maximum Drawdown');

      dateFromArray.push(dateFrom);
      dateToArray.push(dateTo);
      totalRtnArray.push(totalRtn);
      priceRtnArray.push(priceRtn);
      dividendRtnArray.push(dividendRtn);
      totalAnnRtnArray.push(annTotalRtn);
      priceAnnRtnArray.push(annPriceRtn);
      dividendAnnRtnArray.push(annDividendRtn);
      totalVolArray.push(totalVol);
      priceVolArray.push(priceVol);
      totalSharpArray.push(totalSharp);
      priceSharpArray.push(priceSharp);
      totalMddArray.push(totalMdd);
      priceMddArray.push(priceMdd);
    });

    // selected.push(this.createData('Date From', ...dateFromArray));
    // selected.push(this.createData('Date To', ...dateToArray));
    selected.push(this.createData('Total Log Return', ...totalRtnArray));
    selected.push(this.createData('Price Log Return', ...priceRtnArray));
    selected.push(this.createData('Divend Log Return', ...dividendRtnArray));
    selected.push(this.createData('Annualized Total Log Return', ...totalAnnRtnArray));
    selected.push(this.createData('Annualized Price Log Return', ...priceAnnRtnArray));
    selected.push(this.createData('Annualized Divend Log Return', ...dividendAnnRtnArray));
    selected.push(this.createData('Annualized Total Volatility', ...totalVolArray));
    selected.push(this.createData('Annualized Price Volatility', ...priceVolArray));
    selected.push(this.createData('Annualized Total Sharpe Ratio', ...totalSharpArray));
    selected.push(this.createData('Annualized Price Sharpe Ratio', ...priceSharpArray));
    selected.push(this.createData('Total Maximum Drawdown', ...totalMddArray));
    selected.push(this.createData('Price Maximum Drawdown', ...priceMddArray));

    return selected;
  };

  validateDateArrayAndGetByKey = (array, key) => {
    return (typeof array === 'undefined' || 
      array == null || 
      String(array[key]).toUpperCase() === 'NAN'
      ? '-' : moment(array[key]).format("MM-DD"));
  }

  validateNumericArrayAndGetByKey = (array, key) => {
    let factor = 100;
    if (key.indexOf('Sharpe') >= 0) {
      factor = 1;
    }

    return (typeof array === 'undefined' || 
      array == null || 
      String(array[key]).toUpperCase() === 'NAN' ||
      String(array[key]).indexOf('Infinity') >= 0
      ? '-' : (array[key] * factor).toFixed(2));
  }

  createData = (items, onem, threem, sixm, oney, threey, fivey, teny, ytd, itd) => {
    return {
      items, onem, threem, sixm, oney, threey, fivey, teny, ytd, itd,
    };
  };

  renderTitle = (): Object => {
    const title = 'Performance (단위: %)';

    return (
      <Title>
        {title}
      </Title>
    );
  };

  renderLgTable = (): Object => {
    const { data } = this.props;

    if (data != null && typeof data !== 'undefined') {
      this.selectedData = this.getSelected(data);
    }

    const portfolio = this.selectedData;

    return (
      <div>
        <Table aria-label="target portfolio">
          <TableHead>
            <TableRow>
              <StyledHeaderCell align="left">
                Items
              </StyledHeaderCell>
              <StyledHeaderCell align="right">
                1M
              </StyledHeaderCell>
              <StyledHeaderCell align="right">
                3M
              </StyledHeaderCell>
              <StyledHeaderCell align="right">
                6M
              </StyledHeaderCell>
              <StyledBoldHeaderCell align="right">
                1Y
              </StyledBoldHeaderCell>
              <StyledHeaderCell align="right">
                3Y
              </StyledHeaderCell>
              <StyledHeaderCell align="right">
                5Y
              </StyledHeaderCell>
              <StyledHeaderCell align="right">
                10Y
              </StyledHeaderCell>
              <StyledHeaderCell align="right">
                YTD
              </StyledHeaderCell>
              <StyledHeaderCell align="right">
                ITD
              </StyledHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              portfolio.map((row) => (
                <TableRow key={row.items}>
                  <StyledTableCell component="th" scope="row" align="left">
                    {row.items}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row.onem}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row.threem}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row.sixm}
                  </StyledTableCell>
                  <StyledBoldTableCell align="right">
                    {row.oney}
                  </StyledBoldTableCell>
                  <StyledTableCell align="right">
                    {row.threey}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row.fivey}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row.teny}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row.ytd}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row.itd}
                  </StyledTableCell>
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
      </div>
    );
  };

  renderMdTable = (): Object => {
    const { data } = this.props;

    if (data != null && typeof data !== 'undefined') {
      this.selectedData = this.getSelected(data);
    }

    const portfolio = this.selectedData;

    return (
      <div>
        <Table aria-label="target portfolio">
          <TableHead>
            <TableRow>
              <StyledMdHeaderCell align="left">
                Items
              </StyledMdHeaderCell>
              <StyledMdHeaderCell align="right">
                6M
              </StyledMdHeaderCell>
              <StyledMdBoldHeaderCell align="right">
                1Y
              </StyledMdBoldHeaderCell>
              <StyledMdHeaderCell align="right">
                YTD
              </StyledMdHeaderCell>
              <StyledMdHeaderCell align="right">
                ITD
              </StyledMdHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              portfolio.map((row) => (
                <TableRow key={row.items}>
                  <StyledMdTableCell component="th" scope="row" align="left">
                    {row.items}
                  </StyledMdTableCell>
                  <StyledMdTableCell align="right">
                    {row.sixm}
                  </StyledMdTableCell>
                  <StyledMdBoldTableCell align="right">
                    {row.oney}
                  </StyledMdBoldTableCell>
                  <StyledMdTableCell align="right">
                    {row.ytd}
                  </StyledMdTableCell>
                  <StyledMdTableCell align="right">
                    {row.itd}
                  </StyledMdTableCell>
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
      </div>
    );
  };

  render() {
    return (
      <Container>
        <HeaderWrapper>
          {this.renderTitle()}
        </HeaderWrapper>
        <Hidden mdDown>
          {this.renderLgTable()}
        </Hidden>
        <Hidden lgUp>
          {this.renderMdTable()}
        </Hidden>
        <br />
      </Container>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators(ProductDetailCreators, dispatch);

const mapStateToProps = state => ({
  data: state.productDetail.productIndicatorList,
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductIndicator);
