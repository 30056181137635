// @flow

import React, { Component } from 'react';

import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Creators as ProductDetailCreators } from '../../../../store/ducks/productDetail';

import moment from 'moment'
import { parse as parseJson } from 'json2csv';
import FileSaver from 'file-saver';

import Title from './components/Title';
import PortfolioReturnChart from './components/PortfolioReturnChart';

const Container = styled.div`
  margin: 0 4px;
`;

const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 16px;
`;

const ButtonWrapper = styled(Button)`
  margin-left: 10px !important;
`;

const ChartWrapper = styled.div`
  max-width: 100%;
  min-height: 230px;
`;

const notSelectedStyle = {
};

const selectedStyle = {
  backgroundColor: '#1e1e2e',
};

const DownloadIcon = styled(CloudDownloadIcon)`
  color: ${({ theme }) => theme.colors.white};
`;

type Props = {
  getPortfolioReturn: Function,
  data: Array<Object>,
  mpCode: String,
  fundsInfo: Object,
}

class PortfolioReturn extends Component<Props, State> {

  constructor(...args) {
    super(...args);
    this.selectedLabel = null;
    this.savedList = null;
    this.startDate = null;
    this.endDate = null;
  }

  shouldComponentUpdate(nextProps, nextStats) {
    const { mpCode } = this.props;

    const vitalPropsChange = 
      nextProps.data != null &&
      typeof nextProps.data !== 'undefined' &&
      mpCode == nextProps.args.mp_code;

    if (vitalPropsChange) {
      this.savedList = JSON.parse(JSON.stringify( nextProps.data ));
    }
      
    return vitalPropsChange;
  }  

  componentDidMount() {
    this.getPeriod('1Y');
  }

  getPeriod= (label) => {
    const { getPortfolioReturn, mpCode } = this.props;
    this.selectedLabel = label;

    const today = moment();
	  this.startDate = "";
	  this.endDate = today.format("YYYY-MM-DD");

    switch(label) {
      case "1M":
        this.startDate = today.add(-1, 'M').format("YYYY-MM-DD");
        break;
    
      case "3M":
        this.startDate = today.add(-3, 'M').format("YYYY-MM-DD");
        break;
  
      case "6M":
        this.startDate = today.add(-6, 'M').format("YYYY-MM-DD");
        break;
  
      case "1Y":
        this.startDate = today.add(-1, 'y').format("YYYY-MM-DD");
        break;
  
      case "3Y":
        this.startDate = today.add(-3, 'y').format("YYYY-MM-DD");
        break;
  
      case "5Y":
        this.startDate = today.add(-5, 'y').format("YYYY-MM-DD");
        break;
  
      case "10Y":
        this.startDate = today.add(-10, 'y').format("YYYY-MM-DD");
        break;
                          
      case "YTD":
        this.startDate = today.startOf('year').format("YYYY-MM-DD");
        break;
  
      case "ITD":
        this.startDate = "ITD";
        break;

      default:
        this.startDate = '2019-01-01';
    }

    const params = {
      mp_code: mpCode,
      start_dt: this.startDate,
      end_dt: this.endDate,
    }

    getPortfolioReturn(params);
  };

  handleCsvDownload = (key, e) => {
    
    const cloned = JSON.parse(JSON.stringify( this.savedList ));

    const csv = parseJson(cloned);
    const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8' });
    FileSaver.saveAs(csvData, `portfolio_cumulative_return.csv`);
  };

  renderTitle = (): Object => {
    const title = 'Portfolio Cumulative Return';

    return (
      <Title>
        {title}
      </Title>
    );
  };

  renderCsvDownloadButton = (): Object => {
    return (
      <Hidden mdDown key='csvDownload'>
        <ButtonWrapper
          key='csvDownload'
          variant="contained"
          size="small"
          color="primary"
          onClick={this.handleCsvDownload}
        >
          <DownloadIcon />
        </ButtonWrapper>
      </Hidden>
    );
  };

  renderButtons = (): Object => {
    const categories = [
      { label: '1M' },
      { label: '3M' },
      { label: '6M' },
      { label: '1Y' },
      { label: '3Y' },
      { label: '5Y' },
      { label: '10Y' },
      { label: 'YTD' },
      { label: 'ITD' },
    ];
    const buttonList = categories.map((category) => {
      let style = notSelectedStyle;
      if (category.label === this.selectedLabel) {
        style = selectedStyle;
      }

      return (
        <Hidden mdDown key={category.label}>
          <ButtonWrapper
            key={category.label}
            variant="contained"
            size="small"
            color="primary"
            term={category.label}
            onClick={() => this.getPeriod(category.label)}
            style={style}
          >
            {category.label}
          </ButtonWrapper>
        </Hidden>
      );
    });

    return (
      <div>
        {buttonList}
        {this.renderCsvDownloadButton()}
      </div>
    );
  };

  renderChart = (data: Array<Object>): Object => {
    return (
      <ChartWrapper>
        <PortfolioReturnChart 
          data={data}
        />
      </ChartWrapper>
    );
  };

  render() {
    const { data } = this.props;

    let selected = data;
    if (data) {
      if (this.startDate == 'ITD') {
        this.startDate = '1990-01-01';
      }
      selected = data.filter(item => {
        return item.DT >= this.startDate && item.DT <= this.endDate;
      });
    }

    return (
      <Container>
        <HeaderWrapper>
          {this.renderTitle()}
          {this.renderButtons()}
        </HeaderWrapper>
        {this.renderChart(selected)}
      </Container>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators(ProductDetailCreators, dispatch);

const mapStateToProps = state => ({
  data: state.productDetail.portfolioReturn,
  args: state.productDetail.args,
  fundsInfo: state.productList.fundsInfo,
});

export default connect(mapStateToProps, mapDispatchToProps)(PortfolioReturn);
