const tabConfig = [{
  columnTitle: 'Name',
  dataField: 'NAME',
}, {
  columnTitle: 'Username',
  dataField: 'USER_ID',
}, {
  columnTitle: 'Belongs to',
  dataField: 'BELONG',
}];

export default tabConfig;
