const entities = {
  OPERATION_REQUEST: 'OPERATION_REQUEST',
  USER: 'USER',
  ROLE: 'ROLE',
  BANNER: 'BANNER',
  PRODUCTLIST: 'PRODUCTLIST',
  PRODUCTDETAIL: 'PRODUCTDETAIL',
};

module.exports = entities;
