/* eslint-disable react/no-string-refs */
// @flow

import React, { Component } from 'react';

import styled from 'styled-components';

import { connect } from 'react-redux';

import * as d3 from 'd3';
import moment from 'moment';


const Wrapper = styled.div`
`;

type Props = {
  data: Array<Object>,
  hasViewAuth: Boolean,
}

type State = {
  chartWidth: Number,
};

const fk = (v) => {
  return function (d) {
    return d[v];
  };
}

class AssetAllocationTrendChart extends Component<Props, State> {

  constructor(...args) {
    super(...args);
    this.childKey = 0;
    this.state = {chartWidth: 1100};
  }

  componentDidMount() {
    this.updateChartWidth();    
    window.addEventListener('resize', this.updateChartWidth);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateChartWidth);
  }

  shouldComponentUpdate(nextProps, nextStats) {
    const vitalPropsChange = 
      nextProps.data != null &&
      typeof nextProps.data !== 'undefined';
    
    return vitalPropsChange;
  }  

  componentDidUpdate(prevProps, prevState) {
    const { data } = this.props;
    const keyset = new Set();

    if (data != null && typeof data !== 'undefined' && data.length > 0) {
      /******************************************************************
       * 서버에서 내려온 TYPE, RATIO의 이름을 바꾼다.
       ******************************************************************/
      for (const [key, value] of Object.entries(data[0])) {
        if (key != 'date') {
          keyset.add(key);
        }
      }
      // keys를 set에서 array로 변환
      let keys = Array.from(keyset);
      this.drawChart(data, keys);
    }
  }

  updateChartWidth = () => {

    const windowWidth = window.innerWidth;
    let preferredWidth = 1010;
    if (windowWidth < 1010) {
      preferredWidth = windowWidth - 100;
    }

    this.setState( {
      chartWidth: preferredWidth
    })
  }

  drawChart = (data, keys) => {
    // set the dimensions and margins of the graph
    const margin = ({
      top: 0, right: 0, bottom: 20, left: 40,
    });
    const width = this.state.chartWidth;
    const height = 200;

    let svg, container, tooltipDiv;
    const elem = this.refs.chartDivAllocationTrend;

    const series = d3.stack()
      .keys(keys)
      .offset(d3.stackOffsetExpand)(data)
      ;

    const xscale = d3.scaleTime()
      .domain(d3.extent(data, (d) => d.date))
      .range([margin.left, width - margin.right]);

    const yscale = d3.scaleLinear()
      .range([height - margin.bottom, margin.top]);

    const area = d3.area()
      .x((d) => xscale(d.data.date))
      .y0((d) => yscale(d[0]))
      .y1((d) => yscale(d[1]));

    const xAxis = (g) => g.attr('transform', `translate(0,${height - margin.bottom})`)
      .call(d3.axisBottom(xscale).ticks(width / 80).tickSizeOuter(0));

    const yAxis = (g) => g.attr('transform', `translate(${margin.left},0)`)
      .call(d3.axisLeft(yscale).ticks(10, '%'))
      .call((ga) => ga.select('.domain').remove());

    let colorRange = d3.schemeBlues[keys.length];
    if (keys.length <= 2) {
      colorRange = d3.schemePaired.slice(0, keys.length);
    }
        
    const color = d3.scaleOrdinal()
      .domain(keys)
      .range(colorRange);

    svg = d3.select(elem)
      .append('svg')
      // .style('width', '100%')
      // .style('height', '100%')
      .style('width', width)
      // .style('height', height + 50)
      .style('height', height + 50)
      .style('display', 'block')
      .style('margin', 'auto')
      .attr('viewBox', [10,  20, width, height])
    ;

    // container for fucus area
    container = svg.insert('g', "rect.mouse-catch")
      // .attr('transform', `translate(${margin.left}, ${margin.top})`)
      .attr('transform', `translate(0, ${margin.top})`)
      .attr('clip-path', 'url(#clip)');

    const pathG = svg.append('g')
      .selectAll('path')
      .data(series)
      .join('path')
      .attr('fill', (key) => color(key))
      .attr('d', area)
    ;

    //++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    // LEGEND
    //++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    const size = 9;
    const legendCnt = keys.length;
    const itemWidth = 80;
    svg.append('g')
      .selectAll('rect')
      .data(keys)
      .join('rect')
      .attr('x', (d, i) => {
        const level = i - legendCnt / 2;
        let xVal = (width / 2) + (level * itemWidth) + 20
        return xVal;
      })
      .attr('y', 210)
      .attr('width', size)
      .attr('height', size)
      .style('fill', (d) => color(d))
    ;

    svg.append('g')
      .selectAll('text')
      .data(keys)
      .join('text')
      .attr('x', (d, i) => {
        const level = i - legendCnt / 2;
        let xVal = (width / 2) + (level * itemWidth) + 20
        return xVal + 15;
      })
      .attr('y', 217)
      .style('fill', 'White')
      .text((d) => {
        let text = d;
        if (text.length > 13) {
          text = text.substring(0, 9) + " ...";
        }
        return text
      })
      .attr('text-anchor', 'left')
      .style('alignment-baseline', 'middle')
      .attr('font-size', 9)
      .attr('font-weight', 'bold')
    ;

    svg.append('g')
      .call(xAxis);

    svg.append('g')
      .call(yAxis);

    // vertical line moving with mouse tip
    const mousevline = svg.append('g')
      .datum({
        x: new Date(),
        visible: false
      });

    mousevline.append('line')
      .attr('x1', 0)
      .attr('x2', 0)
      .attr('y1', yscale.range()[0])
      .attr('y2', yscale.range()[1])
      .attr('class', 'd3_timeseries mousevline');
    // update mouse vline
    mousevline.update = () => {
      // this.attr('transform', (d) => (`translate(${margin.left + xscale(d.x)}, ${margin.top})`))
      // mousevline.attr('transform', (d) => (`translate(${margin.left + xscale(d.x)}, 0)`))
      mousevline.attr('transform', (d) => (`translate(${xscale(d.x)}, 0)`))
        .style('opacity', (d) => (d.visible ? 1 : 0))
    }
    mousevline.update();

    // xdate와 series의 날짜에 시간차가 있어 추가
    const getDateString = (date) => {
      return moment(date).format("YYYY-MM-DD");
    }

    const findByDate = (serie, xdate) => {
      return serie.map((item) => (item.data))
        .find((d) => (getDateString(d.date) === getDateString(xdate)))
    }

    // default tool tip function
    const _tipFunction = (date, series) => {
      const spans = '<table style="border:none">' + 
          series.filter((d) => d.item !== undefined && d.item !== null)
                .reverse()
                .map((d) => '<tr><td style="color:' + color(d.key) + '">' + d.key + ' </td>' +
                            '<td style="color:#dddddd;text-align:right">' + d.item[d.key].toFixed(3) + '</td></tr>')
                .join('') + 
          '<table>';

      return `<h4 style="color:#ffffff">${moment(date).format("YYYY-MM-DD")}</h4>${spans}`;
    };

    const updateTip = (xdate) => {
      if (xdate == null) {
        tooltipDiv.style('opacity', 0);
      } else {
        const s = series.map((s) => ({
          x: xdate, 
          item: findByDate(s, xdate),
          key: s.key
        }))
  
        tooltipDiv.style('opacity', 0.9)
          .style('left', `${xscale(xdate) * 0.9 + 5}px`)
          .style('top', "10px")
          .style('background', '#000000')
          .html(_tipFunction(xdate, s));
      }
    }
  
    const mouseMove = () => {
      let x = d3.mouse(container.node())[0];
      x = xscale.invert(x);
      mousevline.datum({x: x, visible: true});
      mousevline.update();
      updateTip(x);
    }
  
    const mouseOut = () => {
      mousevline.datum({x: null, visible: false});
      mousevline.update();
      updateTip(null);
    }
  
    // catch event for mouse tip
    svg.append('rect')
      .attr('width', width)
      .attr('class', 'd3_timeseries mouse-catch')
      .attr('height', height)
      .style("cursor", "pointer")
      .style('opacity', 0)
      .on('mousemove', mouseMove)
      .on('mouseout', mouseOut);
    
    tooltipDiv = d3.select(elem)
      .style('position', 'relative')
      .append('div')
      .attr('class', 'd3_timeseries tooltip')
      .style("cursor", "pointer")
      .style('opacity', 0)
      .on('mousemove', mouseMove)
      .on('mouseout', mouseOut);
      ;

  }

  render() {
    // Props 갱신 될 때 마다 새로 그리기 위해서 key 속성 추가
    this.childKey++;
    return (
      <Wrapper key={this.childKey}>
        <div ref="chartDivAllocationTrend" />
      </Wrapper>
    );
  }
}

export default connect()(AssetAllocationTrendChart);
