// @flow

import React, { Component } from 'react';

import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { withRouter, Switch, Route } from 'react-router-dom';
import styled from 'styled-components';
import './styles/fade.css';

import Header from './components/header';
import Footer from './components/footer';
import Login from './components/login';
import Passwords from './components/passwords';

import ProductList from './screens/product-list';
import ProductDetail from './screens/product-detail';
import User from './screens/user';
import Role from './screens/role';
import Banner from './screens/banner';

import NotFound from './components/error/NotFound';

const Container = styled.div`
  position: relative;
`;

const Wrapper = styled.div`
  width: 100%;
  padding: 14px 14px 14px 14px;
`;

type Props = {
  history: Object,
};

class ApplicationRouter extends Component<Props, {}> {
  render() {
    return (
      <Container>
        <Route
          render={({ location }) => (
            <TransitionGroup>
              <CSSTransition
                key={location.key}
                classNames="fade"
                timeout={250}
              >
                <Wrapper>
                  <Header />
                  <Switch
                    location={location}
                  >
                    <Route exact
                      component={ProductList}
                      path="(/|/product-list)"
                    />
                    <Route
                      component={Login}
                      path="/login"
                    />
                    <Route
                      component={ProductDetail}
                      path="/product-detail/:mpCode?"
                    />
                    <Route
                      component={User}
                      path="/user"
                    />
                    <Route
                      component={Role}
                      path="/role"
                    />
                    <Route
                      component={Banner}
                      path="/banner"
                    />
                    <Route
                      component={Passwords}
                      path="/passwords"
                    />
                    <Route
                      component={NotFound}
                      path="/error"
                    />
                    <Route
                      component={NotFound}
                    />
                  </Switch>
                  <Footer />
                </Wrapper>
              </CSSTransition>
            </TransitionGroup>
          )}
        />
      </Container>
    );
  }
}

export default withRouter(ApplicationRouter);
