// @flow

import React, { Component } from 'react';

import Paper from '@material-ui/core/Paper';

import styled from 'styled-components';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Creators as ProductListCreators } from '../../store/ducks/productList';
import { Creators as AuthCreators } from '../../store/ducks/auth';

import BannerCarousel from './components/banner-carousel';
import ProductSummary from './components/product-summary';
import NoProductFound from './components/no-product-found';
import DataStatus from './components/data-status';
import { getLoggedInUserName } from '../../store/sagas/auth';


const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const CarouselWrapper = styled(Paper)`
  margin-bottom: 12px;
  padding: 16px 0px 0 0px !important;
  background-color: #27293d !important;
`;

const CategoryWrapper = styled(Paper)`
  margin-bottom: 24px;
  padding: 16px 8px 0 8px !important;
  background-color: #1b2b4b !important;
`;

const DataStatusWrapper = styled(Paper)`
  margin-bottom: 12px;
  max-height: 50px;
  padding: 8px 16px 0 0 !important;
  background-color: #1b2b4b !important;
  text-align: right;
`;

const Wrapper = styled(Paper)`
  margin-bottom: 24px;
  padding: 16px 32px 0 32px !important;
  background-color: #27293d !important;
  cursor: pointer !important;
`;

const PublicHeaderWrapper = styled(Paper)`
  margin-bottom: 12px;
  padding: 8px 32px 12px 32px !important;
  background-color: #27293d !important;
`;

type Props = {
  getFundsInfo: Function,
  getLoggedInUserName: Function,
  history: Object,
  fundsInfo: Array<Object>,
  auth: Object,
}

class ProductList extends Component<Props, State> {

  componentDidMount() {
    window.scrollTo(0, 0)
    
    const { getFundsInfo } = this.props;
    const { user } = this.props.auth;

    let userName = 'anonymous';
    if (typeof user !== 'undefined' && user !== null) {
      userName = user.username;
    } else if (!user) {
      userName = getLoggedInUserName();
    }

    if (!userName) {
      userName = 'anonymous';
    }

    getFundsInfo({"user_id": userName});
  }

  forwardToDetail = (mpCode, engineData, e) => {
    const { history } = this.props;

    e.preventDefault();

    history.push({
      pathname: `/product-detail`,
      state: { engineData, mpCode },
    });
  };

  isUserLoggedIn = () => {
    if (typeof this.props.auth.user !== 'undefined' && this.props.auth.user != null) {
      return true;
    } else {
      return false;
    }
  }

  renderSlider = () => {
    return (
      <CarouselWrapper>
        <BannerCarousel />
      </CarouselWrapper>
    );
  };

  renderDataStatus = () => {
    return (
      <DataStatusWrapper>
        <DataStatus />
      </DataStatusWrapper>
    );
  };

  renderProduct = (mpCode, engineData): Object => {
    return (
      <Wrapper 
        key={mpCode}
      >
        <ProductSummary
          mpCode={mpCode}
          engineData={engineData}
          forwardToDetail={this.forwardToDetail}
        />
      </Wrapper>
    );
  };

  renderNoProduct = () => {
    return (
      <Wrapper>
        <NoProductFound />
      </Wrapper>
    );
  };

  render() {
    const { fundsInfo } = this.props;

    return (
      <Container>
        {this.renderSlider()}
        {/* {this.renderCategory()} */}
        {/* {this.renderDataStatus()} */}
        {typeof fundsInfo !== 'undefined' && fundsInfo.length > 0 &&
          fundsInfo.map((item) => this.renderProduct(item['MP_CODE'], item))
        }
        {(typeof fundsInfo === 'undefined' || fundsInfo.length === 0) &&
          this.renderNoProduct()
        }
      </Container>
    );
  }
}

const Creators = Object.assign({}, ProductListCreators, AuthCreators);

const mapDispatchToProps = dispatch => bindActionCreators(Creators, dispatch);

const mapStateToProps = state => ({
  fundsInfo: state.productList.fundsInfo,
  auth: state.auth,
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductList);
