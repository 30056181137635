// @flow

import React, { Component } from 'react';

import styled from 'styled-components';

import { Grid } from '@material-ui/core';
import { Hidden } from '@material-ui/core';

import SummaryIndex from './SummaryIndex';
import SummaryProfit from './SummaryProfit';


const Wrapper = styled.div`
  margin-bottom: 0px;
`;

type Props = {
  summary: Object,
};

class Summary extends Component<Props, State> {

  shouldComponentUpdate(nextProps, nextStats) {
    const vitalPropsChange = 
      nextProps.summary !== this.props.summary &&
      nextProps.summary != null &&
      typeof nextProps.summary !== 'undefined';
    
    return vitalPropsChange;
  }  

  render() {
    const { summary } = this.props;

    return (
      <div>
        <Wrapper>
          <Grid container spacing={5}>
            <Grid item sm={7}>
              <SummaryIndex
                summary={summary}
              />
            </Grid>
            <Hidden mdDown>
              <Grid item sm={5}>
                <SummaryProfit
                  summary={summary}
                />
              </Grid>
            </Hidden>
          </Grid>
        </Wrapper>
      </div>
    );
  }
}

export default Summary;
