import Immutable from 'seamless-immutable';

export const Types = {
  LOGIN_CHECK: 'auth/LOGIN_CHECK',
  GET_LOGIN_NAME: 'auth/GET_LOGIN_NAME',
  LOGIN_REQUEST: 'auth/LOGIN_REQUEST',
  LOGIN_SUCCESS: 'auth/LOGIN_SUCCESS',
  LOGIN_ERROR: 'auth/LOGIN_ERROR',
  LOGIN_CANCELLED: 'auth/LOGIN_CANCELLED',
  LOGOUT: 'auth/LOGOUT',
  SAVE_TOKEN: 'auth/SAVE_TOKEN',
  DELETE_TOKEN: 'auth/DELETE_TOKEN',
  PROCESSING: 'auth/PROCESSING',
};

export const Status = {
  PROCESSING: 'Processing',
  LOGGED_IN: 'Logged in',
  LOGGED_OUT: 'Logged out',
  LOGIN_ERROR: 'Login error',
  LOGIN_CANCELLED: 'Login cancelled',
}

const INITIAL_STATE = Immutable({
  isAuthenticated: false,
  user: null,
  token: null,
  status: Status.LOGGED_OUT,
});

export const Creators = {
  checkLoggedIn: () => ({
    type: Types.LOGIN_CHECK,
  }),

  getLoggedInUserName: () => ({
    type: Types.GET_LOGIN_NAME,
  }),

  login: (user) => ({
    type: Types.LOGIN_REQUEST,
    payload: { user },
  }),

  loginSuccess: (user) => ({
    type: Types.LOGIN_SUCCESS,
    payload: { user },
  }),

  loginError: () => ({
    type: Types.LOGIN_ERROR,
  }),

  loginCancelled: () => ({
    type: Types.LOGIN_CANCELLED,
  }),

  logout: () => ({
    type: Types.LOGOUT,
  }),

  saveToken: (token) => ({
    type: Types.SAVE_TOKEN,
    payload: { token },
  }),

  deleteToken: () => ({
    type: Types.DELETE_TOKEN,
  }),

  processing: () => ({
    type: Types.PROCESSING,
  }),
};

const auth = (state = INITIAL_STATE, { payload, type }) => {
  let newState;
  switch (type) {
    case Types.LOGIN_SUCCESS:
      newState = {
        isAuthenticated: true,
        user: payload.user,
        status: Status.LOGGED_IN,
      };
      return newState;

    case Types.SAVE_TOKEN:
      newState = {...state, token: payload.token};
      // newState.token = payload.token;
      return newState;

    case Types.DELETE_TOKEN:
      newState = {...state, token: null};
      // newState.token = null;
      return newState;
    
    case Types.LOGOUT:
      newState = {
        ...INITIAL_STATE,
      };
      return newState;
    
    case Types.LOGIN_ERROR:
      newState = {
        ...INITIAL_STATE,
        status: Status.LOGIN_ERROR,
      };
      return newState;
    
    case Types.LOGIN_CANCELLED:
      newState = {
        ...INITIAL_STATE,
        status: Status.LOGIN_CANCELLED,
      };
      return newState;
    
    case Types.PROCESSING:
      newState = {
        ...INITIAL_STATE,
        status: Status.PROCESSING,
      };
      return newState;
      
    default:
      return state;
  }
};

export default auth;
