import React, { Component, Fragment } from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Creators as RoleCreators } from '../../store/ducks/role';
import { Creators as ProductListCreators } from '../../store/ducks/productList';
import { Creators as UserCreators } from '../../store/ducks/user';

import EntityComponent from '../../components/common/entity-component';
import Snackbar from '../../components/common/Snackbar';

import config from './config';
import RoleForm from './form';

type Props = {
  getAllRoles: Function,
  getAllFundsInfo: Function,
  getAllUsers: Function,
  createRole: Function,
  roles: Array<Object>,
  allFundsInfo: Array<Object>,
  users: Array<Object>,
  removeRole: Function,
  editRole: Function,
};

type State = {
  isSnackbarOpen: boolean,
};

class Role extends Component<Props, State> {
  state = {
    isSnackbarOpen: false,
  };

  componentDidMount() {
    const { getAllRoles, getAllFundsInfo, getAllUsers } = this.props;

    getAllRoles();
    getAllFundsInfo();
    getAllUsers();
  }

  componentWillReceiveProps(nextProps) {
    const { message, error } = nextProps.roles;

    if (message || error) {
      this.setState({
        isSnackbarOpen: true,
      });
    }
  }

  onCreateRole = async (role: Object): void => {
    const { createRole } = this.props;

    createRole(role);
  };

  onEditRole = async (roleEdited: Object): void => {
    const { editRole } = this.props;

    editRole(roleEdited);
  };

  onRemoveRole = async (id): void => {
    const { removeRole } = this.props;
    const tokens = id.split('|');
    const USER_ID = tokens[0];
    const MP_CODE = tokens[1];
    removeRole({USER_ID, MP_CODE});
  };

  renderSnackbar = (stock: Object): Object => {
    const { isSnackbarOpen } = this.state;
    const { message, error } = stock;

    return (
      <Snackbar
        onCloseSnackbar={() => this.setState({ isSnackbarOpen: false })}
        isOpen={isSnackbarOpen}
        message={message}
        error={error}
      />
    );
  };

  render() {
    const { roles, allFundsInfo, users } = this.props;
    const usernames = roles.data.map(role => role.USER_ID).filter((value, index, self) => self.indexOf(value) === index);
    const data = roles.data.map((item) => ({id: item.USER_ID + "|" + item.MP_CODE, ...item}))

    return (
      <Fragment>
        <EntityComponent
          onCreateItem={this.onCreateRole}
          onEditItem={this.onEditRole}
          onRemoveItem={this.onRemoveRole}
          singularEntityName="Role"
          pluralEntityName="Roles"
          filterConfig={config.filterConfig}
          tabConfig={config.tabConfig}
          withOwnRemoveAction={this.onClickRemoveTableIcon}
          dataset={data}
          canBeCreated
          canBeEdited
          canBeRemoved
          Form={props => (
            <RoleForm
              usernames={usernames}
              fundsInfo={allFundsInfo}
              users={users.data}
              {...props}
            />
          )}
        />
        {this.renderSnackbar(roles)}
      </Fragment>
    );
  }
}

const Creators = Object.assign({}, RoleCreators, ProductListCreators, UserCreators);

const mapDispatchToProps = dispatch => bindActionCreators(Creators, dispatch);

const mapStateToProps = state => ({
  roles: state.role,
  allFundsInfo: state.productList.allFundsInfo,
  users: state.user,
});

export default connect(mapStateToProps, mapDispatchToProps)(Role);
