// @flow

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import styled from 'styled-components';

import NotFoundImg from '../../images/404.png';

const Container = styled.div`
  background-color: white !important;
  min-height: 500px !important;
`;

const IconWrapper = styled.div`
  max-width: 100%;
  filter: blur(1px);
  -webkit-filter: blur(1px);
`;

const NotFoundIcon = styled.img.attrs({
  src: NotFoundImg,
  alt: 'Not Found',
})`
  padding-top: 30px !important;
  padding-bottom: 50px !important;
  width: 300px;
  height: auto;
  display: block;
  margin: auto;
  position: relative;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const TypographyTopWrapper = styled(({ ...props }) => (
  <Typography
    {...props}
  />
))`
  font-weight: bold !important;
  color: #1b2b4b !important;
`;
  
const TypographyBottomWrapper = styled(({ ...props }) => (
  <Typography
    {...props}
  />
))`
  color: #1b2b4b !important;
  padding-bottom: 10px !important;
`;
  
const ButtonWrapper = styled(({ ...props }) => (
  <Button
    {...props}
  />
))`
  color: #1b2b4b !important;
  margin-bottom: 70px !important;
`;

type Props = {
  history: Object,
};

class NotFound extends Component<Props, State> {

  handleGoBackClick = () => {
    const { history } = this.props;
    history.push('/');
  };

  renderNotFoundMessage = () => {
    return (
      <Wrapper>
        <TypographyTopWrapper variant="h4" gutterBottom>
          Oops! Something went wrong
        </TypographyTopWrapper>
        <TypographyBottomWrapper variant="body2" gutterBottom>
          Click below to go to the main page.
        </TypographyBottomWrapper>
        <ButtonWrapper variant="contained" color="secondary" onClick={this.handleGoBackClick}>
          Go to Main Page
        </ButtonWrapper>
      </Wrapper>
    );
  };

  render() {
    return (
      <Container>
        <IconWrapper onClick={this.handleLogoClick}>
          <NotFoundIcon />
        </IconWrapper>
        {this.renderNotFoundMessage()}
      </Container>
    );
  }
}

export default withRouter(NotFound);
