// @flow

import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';

import AssignmentInd from '@material-ui/icons/AssignmentInd';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';

import styled from 'styled-components';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Creators as AuthCreators } from '../../../../../store/ducks/auth';

import { Hidden } from '@material-ui/core';


const UsernameText = styled.p`
  color: ${({ theme }) => theme.colors.white};
`;

const UserIcon = styled(AssignmentInd)`
  margin-right: 8px;
  color: ${({ theme }) => theme.colors.white};
`;

type Props = {
  history: Object,
  logout: Function,
  checkLoggedIn: Function,
  auth: Object,
};

type State = {
  anchorElement: Object,
};

class UserInfo extends Component<Props, State> {
  state = {
    anchorElement: null,
    isPasswordDialogOpen: false,
  };
  
  componentDidMount() {
    const { auth, checkLoggedIn } = this.props;
    if (auth.status === 'Logged out') {
      checkLoggedIn();
    }
  }

  onClickButton = (event) => {
    const { history } = this.props;

    if (typeof this.props.auth.user === 'undefined' || this.props.auth.user === null) {
      history.push('/login');
    } else {
      this.setState({ anchorElement: event.currentTarget });
    }
  };

  onClickLogout = () => {
    const { logout, history } = this.props;
    this.setState({
      anchorElement: null,
    }, () => {
      logout();
      history.push('/product-list');
    });
  }

  onClickPassword = () => {
    const { history } = this.props;
    this.setState({
      anchorElement: null,
    });

    history.push('/passwords');
  }

  onClickUsers = () => {
    const { history } = this.props;
    this.setState({
      anchorElement: null,
    });

    history.push('/user');
  }

  onClickRoles = () => {
    const { history } = this.props;
    this.setState({
      anchorElement: null,
    });

    history.push('/role');
  }

  onClickBanners = () => {
    const { history } = this.props;
    this.setState({
      anchorElement: null,
    });

    history.push('/banner');
  }

  handleCloseMenu = () => {
    this.setState({ anchorElement: null });
  };

  renderAdminContent = () => {
    return (
      <Fragment>
        <MenuItem
          onClick={this.onClickUsers}
        >
          Users
        </MenuItem>
        <MenuItem
          onClick={this.onClickRoles}
        >
          Roles
        </MenuItem>
        <MenuItem
          onClick={this.onClickBanners}
        >
          Banners
        </MenuItem>
      </Fragment>
    );
  };

  render() {
    const { anchorElement } = this.state;
    const { user } = this.props.auth;

    let userNameText = 'Login';
    let isAdmin = false;
    if (typeof user !== 'undefined' &&
      user !== null) {
      userNameText = user.username;
      isAdmin = user.isAdmin === 'Y';
    }

    return (
      <Fragment>
        <Button
          aria-owns={anchorElement ? 'user-menu' : undefined}
          onClick={this.onClickButton}
          aria-haspopup="true"
        >
          <UserIcon />
          <Hidden mdDown>
            <UsernameText>
              {userNameText}
            </UsernameText>
          </Hidden>
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={anchorElement}
          open={Boolean(anchorElement)}
          onClose={this.handleCloseMenu}
        >
          <MenuItem
            onClick={this.onClickLogout}
          >
            Logout
          </MenuItem>
          <MenuItem
            onClick={this.onClickPassword}
          >
            Password
          </MenuItem>
          {isAdmin && this.renderAdminContent()}
        </Menu>
      </Fragment>
    );
  }
}

const Creators = Object.assign({}, AuthCreators);

const mapDispatchToProps = dispatch => bindActionCreators(Creators, dispatch);

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UserInfo));
