/* eslint-disable react/no-string-refs */
// @flow

import React, { Component } from 'react';

import styled from 'styled-components';

import { connect } from 'react-redux';

import * as d3 from 'd3';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

type Props = {
  data: Array<Object>,
}

class AssetAllocationChart extends Component<Props, State> {

  shouldComponentUpdate(nextProps, nextStats) {
    const vitalPropsChange = 
      nextProps.data != null &&
      typeof nextProps.data !== 'undefined';
    
    return vitalPropsChange;
  }  

  componentDidUpdate(prevProps, prevState) {
    
    const { data } = this.props;

    if (data != null && typeof data != 'undefined') {
      /******************************************************************
       * 서버에서 내려온 TYPE, RATIO의 이름을 바꾼다.
       ******************************************************************/
      const converted = [];
      data.forEach((item, index) => {
        converted.push(this.createData(item.TYPE, item.RATIO * 100));
      });
  
      this.drawChart(converted);
    }
  }

  createData = (name, value) => {
    return {
      name, value: value.toFixed(2),
    };
  };

  drawChart = (data) => {
    // 100:0 일 경우 레이블 겹쳐서 변경
    const midAngle = (d) => {
      let angle = d.startAngle + (d.endAngle - d.startAngle) / 2;
      if (d.startAngle === 0) {
        angle = 0;
      }
      return angle;
    }

    // set the dimensions and margins of the graph
    const width = 450;
    const height = 300;
    const radius = Math.min(width, height) / 2;

    let colorRange = d3.schemeBlues[data.length];
    if (data.length <= 2) {
      colorRange = d3.schemePaired.slice(0, data.length);
    }

    const color = d3.scaleOrdinal()
      .domain(data.map((d) => d.name))
      .range(colorRange);

    const arc = d3.arc()
      .outerRadius(radius * 0.8)
      .innerRadius(radius * 0.4)
    ;

    const outerArc = d3.arc()
      .outerRadius(radius * 0.9)
      .innerRadius(radius * 0.9)
    ;

    const pie = d3.pie()
      .sort(null)
      .value((d) => d.value);

    const arcs = pie(data);

    const getLabelPosition = (d) => {
      const pos = outerArc.centroid(d);
      const x = pos[0];
      const y = pos[1];
      let sign =  midAngle(d) < Math.PI ? 1 : -1;
      const y1 = Math.pow(x + Math.sqrt(x*x + y*y) * sign * -1, 4) / Math.pow(135, 4) * 135 * -1;
      const newPos = [radius * sign, y1];
      return newPos;
    }

    const svg = d3.select(this.refs.chartDiv)
      .append('svg')
      .attr('width', '100%')
      .attr('height', '100%')
      .attr('viewBox', [-70,  0, width + 110, height + 100])
      .append('g')
      .attr('transform', `translate(${width / 2}, ${height / 2})`)
      ;

    /* ------- PIE SLICES -------*/
    svg.append('g')
      .attr('stroke', 'white')
      .selectAll('path')
      .data(arcs)
      .join('path')
      .attr('fill', (d) => color(d.data.name))
      .attr('d', arc);

    /* ------- TEXT LABELS -------*/
    svg.append('g')
      .attr('font-family', 'sans-serif')
      .attr('font-size', 15)
      .attr('font-weight', 'bold')
      .attr('fill', 'White')
      // .attr('text-anchor', 'middle')
      .selectAll('text')
      .data(arcs)
      .join('text')
      .attr('dy', '.35em')
      .attr('transform', (d) => `translate(${getLabelPosition(d)})`)
      .attr('text-anchor', (d) => midAngle(d) < Math.PI ? 'start' : 'end')
      .call((text) => {
        text.append('tspan')
          .text((d) => `${d.data.name} ${d.data.value}%`)
      })
      ;
    
    /* ------- SLICE TO TEXT PATH -------*/
    svg.append('g')
      .selectAll('path')
      .data(arcs.map((d) => [arc.centroid(d), outerArc.centroid(d), getLabelPosition(d)]))
      .join('path')
      .attr('stroke', '#2196F3')
      .attr('fill', 'none')
      .call((path) => {
        path.attr('d', d3.line()
          .curve(d3.curveBasis))
      })
      ;
  }

  render() {
    return (
      <Wrapper ref="chartDiv" />
    );
  }
}

export default connect(null, null)(AssetAllocationChart);
