// @flow

import React from 'react';

import styled from 'styled-components';

const TitleWrapper = styled.div`
  margin-bottom: 0px;
`;

type Props = {
  children: string,
};

const Title = ({ children }: Props): Object => {
  const textWithDateText = `${children}`;

  return (
    <TitleWrapper>
      <h3>
        {textWithDateText}
      </h3>
    </TitleWrapper>
  );
};

export default Title;
