// @flow

import React, { Component, Fragment } from 'react';

import { BrowserRouter as ApplicationRouter } from 'react-router-dom';

import styled from 'styled-components';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Creators as LoadingCreators } from '../store/ducks/loading';

import Router from '../Router';
import Loading from './common/Loading';
import ErrorBoundary from './error/ErrorBoundary';

type Props = {
  auth: Object,
  isLoading: Object,
  initializeLoading: Function,
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  height: 100% !important;
`;

const Wrapper = styled.div`
  background-color: #1e1e2e !important;
  max-width: 1120px !important;
  @media ( max-width: 1120px ) { width: 100% !important; }
`;

class Root extends Component<Props, State> {
    
  componentDidMount() {
    const { initializeLoading } = this.props;
    initializeLoading();
  }

  renderDashboard = () => {
    // const { isLoading } = this.props;

    return (
      <ApplicationRouter>
        <Fragment>
          <Container>
            <Wrapper>
              <ErrorBoundary>
                {/* {isLoading ? <Loading /> : <Router /> } */}
                <Router />
              </ErrorBoundary>
            </Wrapper>
          </Container>
        </Fragment>
      </ApplicationRouter>
    );
  };

  render = () => {

    return (
      <Fragment>
        {this.renderDashboard()}
      </Fragment>
    );
  }
};

const mapDispatchToProps = dispatch => bindActionCreators(LoadingCreators, dispatch);

const mapStateToProps = (state) => ({
  auth: state.auth,
  isLoading: state.loading.isLoading,
});

export default connect(mapStateToProps, mapDispatchToProps)(Root);
